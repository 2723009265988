import React, { useState, useMemo } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import classnames from "classnames";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { Icon, Row, Col, Button, PreviewCard, ReactDataTable, UserAvatar } from "../../../components/Component";
import { S3_BUCKET_URL } from "../../../constants";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import { useParams } from "react-router-dom";
import { findUpper, capital } from "../../../utils/Utils";
import bnb from "../../../images/coins/bnb.svg";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt, Star } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalFooter, ModalHeader, ModalBody, CardTitle } from "reactstrap";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";

function ViewCustomerDetails() {
  // let tabno=sessionStorage.getItem("tabcust");
  let history = useHistory();
  let star = [0.5, 1.5, 2.5, 3.5, 4.5];
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };

  const params = useParams();
  let customerId = params.id;
  const [userData, setUserData] = useState([]);
  const [getchildinfo, setGetchildInfo] = useState([]);
  const [getStudentFilterData, setGetStudentFilterData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [getcustomerResourceInfo, setGetCustomerResourceInfo] = useState([]);
  const [purchaseResourceData, setPurchaseResourceData] = useState([]);
  const [savedResourceList, setSavedResourceList] = useState([]);
  const [childPermissionShare, setChildPermissionShare] = useState([]);
  const [sharedResourceList, setSharedResourceList] = useState([]);
  const [review, setReview] = useState([]);
  const [customerComment, setCustomerComment] = useState([]);
  const [customerLike, setCustomerLike] = useState([]);
  const [subscriptionPurchase, setSubscriptionPurchase] = useState([]);
  const [friendList, setFriendList] = useState([]);
  const [friendListNonMatched, setFriendListNonMatched] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [mentoringSessionData, setMentoringSessionData] = useState([]);
  const [childId, setChildId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();
  const [resouceMode, setResouceMode] = useState("save");
  const [selectedfromdate, setSelectedfromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [selectedfromdateChildPermission, setSelectedfromDateChildPermission] = useState(null);
  const [selectedtodateChildPermission, setSelectedToDateChildPermission] = useState(null);
  const [formDateErrorChildPermission, setformDateErrorChildPermission] = useState("");
  const [toDateErrorChildPermission, settoDateErrorChildPermission] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [selectedfromdatementor, setSelectedfromDatementor] = useState(null);
  const [selectedtodatementor, setSelectedToDatementor] = useState(null);
  const [formDateErrormentor, setformDateErrormentor] = useState("");
  const [toDateErrormentor, settoDateErrormentor] = useState("");
  const [isSharedOpen, setSharedOpen] = useState(false);
  const [grouplistshare, setGroupListShare] = useState([]);
  const [friendlistshare, setFriendListShare] = useState([]);
  const [selectedfromdateSharedResource, setSelectedfromDateSharedResource] = useState(null);
  const [selectedtodateSharedResource, setSelectedToDateSharedResource] = useState(null);
  const [formDateErrorSharedResource, setformDateErrorSharedResource] = useState("");
  const [toDateErrorSharedResource, settoDateErrorSharedResource] = useState("");
  const [selectShareWith, setSelectShareWith] = useState("");
  const [selectedfromdateSaveResource, setSelectedfromDateSaveResource] = useState(null);
  const [selectedtodateSaveResource, setSelectedToDateSaveResource] = useState(null);
  const [formDateErrorSaveResource, setformDateErrorSaveResource] = useState("");
  const [toDateErrorSaveResource, settoDateErrorSaveResource] = useState("");
  const [selectedSaveFor, setSelectedSaveFor] = useState("");
  const [manageGrade, setManageGrade] = useState([]);
  const [studentId, setStudentId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const [childData, setChildData] = useState([]);
  const [child, setChild] = useState("");
  const [selectPaymentType, setSelectPaymentType] = useState("");
  const [selectFriendMemberType, setSelectFriendMemberType] = useState("");
  const [joinedAsType, setJoinedAsType] = useState("");
  const [selectedfromdateComment, setSelectedfromDateComment] = useState(null);
  const [selectedtodateComment, setSelectedToDateComment] = useState(null);
  const [formDateErrorComment, setformDateErrorComment] = useState("");
  const [toDateErrorComment, settoDateErrorComment] = useState("");
  const [selectedfromdateReview, setSelectedfromDateReview] = useState(null);
  const [selectedtodateReview, setSelectedToDateReview] = useState(null);
  const [formDateErrorReview, setformDateErrorReview] = useState("");
  const [toDateErrorReview, settoDateErrorReview] = useState("");
  const [selectedfromdateLike, setSelectedfromDateLike] = useState(null);
  const [selectedtodateLike, setSelectedToDateLike] = useState(null);
  const [formDateErrorLike, setformDateErrorLike] = useState("");
  const [toDateErrorLike, settoDateErrorLike] = useState("");
  const [selectedfromdateGroup, setSelectedfromDateGroup] = useState(null);
  const [selectedtodateGroup, setSelectedToDateGroup] = useState(null);
  const [formDateErrorGroup, setformDateErrorGroup] = useState("");
  const [toDateErrorGroup, settoDateErrorGroup] = useState("");
  const [selectedfromdateSubscription, setSelectedfromDateSubscription] = useState(null);
  const [selectedtodateSubscription, setSelectedToDateSubscription] = useState(null);
  const [formDateErrorSubscription, setformDateErrorSubscription] = useState("");
  const [toDateErrorSubscription, settoDateErrorSubscription] = useState("");
  const [isMentorOpen, setMentorOpen] = useState(false);
  const [mentorDetails, setMentorDetails] = useState("");
  const [userMentorId, setUserMentorId] = useState("");
  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const openModal1 = () => {
    setIsOpen1(!isOpen1);
  };
  const mentorModal = () => {
    setMentorOpen(!isMentorOpen);
  };
  const [ratingId, setRatingId] = useState([]);
  const HandleViewRating = (row) => {
    setRatingId(row);
    setIsOpen(true);
  };
  const [comment, setComment] = useState([]);
  const HandleViewcomment = (row) => {
    setComment(row);
    setIsOpen1(true);
  };
  const showmentordetails = (row) => {
    setMentorDetails(row.tbl_payment_history);
    setMentorOpen(true);
  };
  const shareModal = () => {
    setSharedOpen(!isSharedOpen);
  };
  //   const setTabno = () => {
  // 	setLoader(true);
  // 	  if(tabno==""){
  // 		setVerticalIconTab("1")
  // 	  }
  // 		else
  // 		{
  // 		  setVerticalIconTab(tabno)
  // 	}
  // 	setLoader(false);
  //   }

  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"];
  const columns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.name,
        grow: 1,
        minWidth: "50px",
        style: { minWidth: "50px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPageResource == 1 ? index + 1 : (currentPageResource - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Creation Date",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>
          </div>
        ),
      },
      {
        name: "Resource Title",
        selector: (row) => row.resource_title,
        grow: 3,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <span className="sub-text">{row.resource_title}</span>
          </div>
        ),
      },

      {
        name: "Status",
        selector: (row) => row.status,
        cell: (row, index) => (
          <>
            {row.status == "publish" && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={row.id}
                  onClick={() => handleswitch(row.id, "unpublish", index)}
                  checked
                />
                <label className="custom-control-label" htmlFor={row.id}></label>
              </div>
            )}
            {row.status == "unpublish" && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={row.id}
                  onClick={() => handleswitch(row.id, "publish", index)}
                />
                <label className="custom-control-label" htmlFor={row.id}></label>
              </div>
            )}
            {row.status === "under_review" && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={row.id}
                  onClick={() => handleswitch(row.id, "publish", index)}
                />
                <label className="custom-control-label" htmlFor={row.id}></label>
              </div>
            )}
          </>
        ),
      },
      {
        name: "View",
        selector: (row) => row.id,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <Link to={`/view-resource/${row.id}`}>
              {" "}
              <button className="dropdown-item btn btn-md">
                <em className="icon ni ni-eye"></em>
              </button>
            </Link>
          </div>
        ),
      },
    ];
  }, [getcustomerResourceInfo]);

  const showshareddetails = (sid, uid, id) => {
    setLoader(true);
    let data = {
      resource_id: sid,
      user_id: uid,
      id: id,
    };
    makePostRequest(URLS.GET_SHARE_LIST, null, data, null).then((response) => {
      if (response.code == 200) {
        setFriendListShare(response.data.data.username);
        setGroupListShare(response.data.data.groupname);
        setLoader(false);
        setSharedOpen(true);
      } else setLoader(false);
    });
  };

  const columns1 = [
    {
      name: "#",
      selector: (row) => row,
      grow: 0,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1 ? index + 1 : (currentPage - 1) * 10 + (index + 1)}
          </div>
        </>
      ),
    },
    {
      name: "Purchase Date",
      selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>
        </div>
      ),
    },
    {
      name: "Resource Title",
      selector: (row) => row.tbl_user_resource.resource_title,
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.tbl_user_resource.resource_title}</span>

            <div className="user-card mt-0 mb-0">
              <Link to={`/view-resource/${row?.tbl_user_resource?.id}`}>
                <Icon name="eye"></Icon>
              </Link>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Resource Type",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.resource_type_status ? row.resource_type_status : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Total Amount",
      selector: (row) => row.total_amount,
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.total_amount
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(row.total_amount)
                : "$0.00"}
            </span>
            <span className="sub-text">
              {" "}
              <a href={S3_BUCKET_URL + row?.tbl_payment_history?.invoice_pdf_link} target="_blank">
                <span className="sub-text" style={{ color: "#2566e8" }}>
                  {" "}
                  view Invoice{" "}
                </span>
              </a>
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "View Detail",
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to="/purchase-list">
            <Icon name="eye"></Icon>
          </Link>
        </div>
      ),
    },
  ];

  const columns2 = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPageSave == 1 ? index + 1 : (currentPageSave - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Saved Date",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Saved Resource",
        selector: (row) => row.tbl_user_resource.resource_title,
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row?.tbl_user_resource.resource_title}</span>
              <div className="user-card mt-0 mb-0">
                <Link to={`/view-resource/ ${row?.tbl_user_resource?.id}`}>
                  <Icon name="eye"></Icon>
                </Link>
              </div>
            </div>
          </div>
        ),
      },

      {
        name: "For (Student)",
        selector: (row) => row.tbl_user_resource.resource_title,
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 1,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">
                {row?.tbl_child?.firstName} {row?.tbl_child?.lastName}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Status",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row?.resource_for}</span>
            </div>
          </div>
        ),
      },
    ];
  }, [savedResourceList]);

  const columnsAdminUser = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {index + 1} </div>
        </>
      ),
    },

    {
      name: "Name",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row && row.tbl_user.firstName ? row.tbl_user.firstName : "--"}{" "}
              {row && row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.status}</span>
          </div>
        </div>
      ),
    },
  ];

  const columns3 = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage2 == 1 ? index + 1 : (currentPage2 - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Shared with",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      //   grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row && row.Share_with_user.firstName ? row.Share_with_user.firstName : ""}{" "}
              {row && row.Share_with_user.lastName ? row.Share_with_user.lastName : ""}{" "}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Permission Date",
      selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{moment(row.created_at).format("MM-DD-YYYY")}</span>
          </div>
        </div>
      ),
    },

    {
      name: "Student",
      selector: (row) => "",
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.tbl_child.firstName} {row.tbl_child.lastName}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Type",
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.isAdmin == 0 ? "General" : "Admin"}</span>
          </div>
        </div>
      ),
    },
  ];

  const columns4 = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage4 == 1 ? index + 1 : (currentPage4 - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },

      {
        name: "Shared Resource",
        selector: (row) => "",
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">{row?.tbl_user_resource?.resource_title}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Shared Date",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Shared With",
        selector: (row) => "",
        sortable: true,
        minWidth: "150px",
        style: { minWidth: "150px" },
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">
                {row.shared_with.indexOf("1,2,3") >= 0 ||
                row.shared_with.indexOf("1,3,2") >= 0 ||
                row.shared_with.indexOf("3,2,1") >= 0 ||
                row.shared_with.indexOf("3,1,2") >= 0 ||
                row.shared_with.indexOf("2,1,3") >= 0 ||
                row.shared_with.indexOf("2,3,1") >= 0
                  ? "Matched Community,Friends,Groups"
                  : row.shared_with.indexOf("1,2") >= 0 || row.shared_with.indexOf("2,1") >= 0
                  ? "Matched Community,Friends"
                  : row.shared_with.indexOf("1,3") >= 0 || row.shared_with.indexOf("3,1") >= 0
                  ? "Matched Community,Groups"
                  : row.shared_with.indexOf("2,3") >= 0 || row.shared_with.indexOf("3,2") >= 0
                  ? "Friends,Groups"
                  : row.shared_with.indexOf("1") >= 0
                  ? "Matched Community"
                  : row.shared_with.indexOf("2") >= 0
                  ? "Friends"
                  : row.shared_with.indexOf("3") >= 0
                  ? "groups"
                  : ""}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "View Detail",
        selector: (row) => "",
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <button
              className="dropdown-item"
              onClick={() => {
                showshareddetails(row.resource_id, row.user_id, row.id);
              }}
            >
              <Icon name="eye"></Icon>
            </button>
          </div>
        ),
      },
    ];
  }, [sharedResourceList]);

  const columns5 = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage3 == 1 ? index + 1 : (currentPage3 - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Review Date",
        selector: (row) => "",
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },

      {
        name: "Resource Title",
        selector: (row) => "",
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row?.tbl_user_resource?.resource_title}</span>
              <div className="user-card mt-0 mb-0">
                <Link to={`/view-resource/${row?.tbl_user_resource?.id}`}>
                  <Icon name="eye"></Icon>
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: "Rating",
        selector: (row) => "",
        sortable: true,
        grow: 2,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <Button
                color="primary"
                onClick={() => {
                  HandleViewRating(row);
                }}
              >
                View Rating
              </Button>
            </div>
          </div>
        ),
      },
    ];
  }, [review]);

  const columns6 = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPageComment == 1 ? index + 1 : (currentPageComment - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },

      {
        name: "Resource Title",
        selector: (row) => row.tbl_user_resource.resource_title,
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">{row?.tbl_user_resource.resource_title}</span>
              <div className="user-card mt-0 mb-0">
                <Link to={`/view-resource/ ${row?.tbl_user_resource?.id}`}>
                  <Icon name="eye"></Icon>
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: "Comment Date",
        selector: (row) => "",
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },
      {
        name: "View Detail",
        selector: (row) => "",
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <Button
                color="primary"
                onClick={() => {
                  HandleViewcomment(row);
                }}
              >
                View Comment
              </Button>
            </div>
          </div>
        ),
      },
    ];
  }, [customerComment]);

  const columns7 = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageSubscription == 1 ? index + 1 : (currentPageSubscription - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },

    {
      name: "Subscription Date",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 0,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{moment(row.subscription_date).format("MM-DD-YYYY")}</span>
          </div>
        </div>
      ),
    },
    {
      name: <div dangerouslySetInnerHTML={{ __html: "Subscription</br>Number" }} />,
      selector: (row) => "",
      minWidth: "120px",
      style: { minWidth: "120px" },
      grow: 0,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row.tbl_subscription_plan.title}</span>
            <span className="sub-text">{row.subscription_no}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Valid Until",
      selector: (row) => "",
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.tbl_subscription_plan.payment_frequency_type != "free"
                ? moment(row.valid_upto).format("MM-DD-YYYY")
                : "Life Time"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => "",
      grow: 0,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.status === "active"
                ? "active"
                : row.status === "pending"
                ? "upcoming"
                : row.status === "deactive"
                ? "deactive"
                : "--"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "View Detail",
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-subscriptionplan/${row?.id}`}>
            <Icon name="eye"></Icon>
          </Link>
        </div>
      ),
    },
  ];

  const columns8 = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {index + 1} </div>
        </>
      ),
    },
    {
      name: "Friend",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row.user.firstName} {row.user.lastName}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => "",
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.status}</span>
          </div>
        </div>
      ),
    },
    {
      name: "View Detail",
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/customer-list/ ${row.id}`}>
            <Icon name="eye"></Icon>
          </Link>
        </div>
      ),
    },
  ];

  const columnsLike = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPageLike == 1 ? index + 1 : (currentPageLike - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Resource Title",
        selector: (row) => "",
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">{row.tbl_user_resource.resource_title}</span>
              <div className="user-card mt-0 mb-0">
                <Link to={`/view-resource/${row?.tbl_user_resource?.id}`}>
                  <Icon name="eye"></Icon>
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: "Like Date",
        selector: (row) => "",
        // grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{moment(row?.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Likes",
        // grow: 2,
        selector: (row) => "",
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">
                {" "}
                <Icon name="heart"></Icon>
                {row.tbl_user_resource.total_likes}
              </span>
            </div>
          </div>
        ),
      },
    ];
  }, [customerLike]);

  const columnsmentoring = [
    {
      name: "#",
      selector: (row) => "1",
      grow: 0,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageMentoring == 1 ? index + 1 : (currentPageMentoring - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: <div dangerouslySetInnerHTML={{ __html: "Booking Date/</br>Session Type" }} />,
      selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
      grow: 1,
      minWidth: "140px",
      style: { minWidth: "150px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead"> {moment(row.created_at).format("MM-DD-YYYY")}</span>
              <br />
              <span>{row.schedule_type == "1" ? "Group Session" : "Individual Session"}</span>
            </div>
          </div>
        </>
      ),
    },

    {
      name: <div dangerouslySetInnerHTML={{ __html: "Mentor Name/</br>Resource Title" }} />,
      selector: (row) => "",
      minWidth: "120px",
      style: { minWidth: "120px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row.sold_by_user.firstName} {row.sold_by_user.lastName}
            </span>
            <span className="sub-text">{row.tbl_user_resource.resource_title}</span>
          </div>
        </div>
      ),
    },
    {
      name: <div dangerouslySetInnerHTML={{ __html: "Student Name" }} />,
      selector: (row) => "",
      minWidth: "120px",
      style: { minWidth: "120px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row.tbl_child.firstName} {row.tbl_child.lastName}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: "Amount",
      minWidth: "90px",
      style: { minWidth: "90px" },
      grow: 2,
      selector: (row) => row.total_amount,
      grow: 2,
      sortable: true,
      cell: (row) => (
        <span className="sub-text">
          {row?.total_amount
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(row?.total_amount)
            : "$0.00"}
        </span>
      ),
    },
    {
      name: "View Detail",
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <button
            className="dropdown-item"
            onClick={() => {
              showmentordetails(row);
            }}
          >
            <Icon name="eye"></Icon>
          </button>
        </div>
      ),
    },
  ];

  const columnsmentoringMentor = [
    {
      name: "#",
      selector: (row) => "1",
      grow: 0,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageMentoring == 1 ? index + 1 : (currentPageMentoring - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: <div dangerouslySetInnerHTML={{ __html: "Booking Date/</br>Session Type" }} />,
      selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
      grow: 1,
      minWidth: "140px",
      style: { minWidth: "150px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead"> {moment(row.created_at).format("MM-DD-YYYY")}</span>
              <br />
              <span>{row.schedule_type == "1" ? "Group Session" : "Individual Session"}</span>
            </div>
          </div>
        </>
      ),
    },

    {
      name: <div dangerouslySetInnerHTML={{ __html: "Customer Name/</br>Resource Title" }} />,
      selector: (row) => "",
      minWidth: "120px",
      style: { minWidth: "120px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row.purchased_user.firstName} {row.purchased_user.lastName}
            </span>
            {/* <span className="tb-lead">{row.sold_by_user.firstName}{" "}{row.sold_by_user.lastName}</span> */}
            <span className="sub-text">{row.tbl_user_resource.resource_title}</span>
          </div>
        </div>
      ),
    },
    {
      name: <div dangerouslySetInnerHTML={{ __html: "Student Name" }} />,
      selector: (row) => "",
      minWidth: "120px",
      style: { minWidth: "120px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            {/* <span className="tb-lead">{row.purchased_user.firstName} {" "} {row.purchased_user.lastName}</span> */}
            <span className="tb-lead">
              {row.tbl_child.firstName} {row.tbl_child.lastName}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: "Amount",
      minWidth: "90px",
      style: { minWidth: "90px" },
      grow: 2,
      selector: (row) => row.total_amount,
      grow: 2,
      sortable: true,
      cell: (row) => (
        <span className="sub-text">
          {row?.total_amount
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(row?.total_amount)
            : "$0.00"}
        </span>
      ),
    },
    {
      name: "View Detail",
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <button
            className="dropdown-item"
            onClick={() => {
              showmentordetails(row);
            }}
          >
            <Icon name="eye"></Icon>
          </button>
        </div>
      ),
    },
  ];

  const columnsGroup = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageGroup == 1 ? index + 1 : (currentPageGroup - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },

    {
      name: "Joined Date",
      selector: (row) => "",

      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Group Name",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.tbl_group.group_name ? row?.tbl_group.group_name : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Joined As",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row?.isAdmin === 1 ? "Admin" : "Member"}</span>
          </div>
        </div>
      ),
    },

    {
      name: "View Detail",
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-groupdetail/${row.group_id}`}>
            <Icon name="eye"></Icon>
          </Link>
        </div>
      ),
    },
  ];

  const columnsStripe = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageStripe == 1 ? index + 1 : (currentPageStripe - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },

    {
      name: "Email",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row?.email ? row?.email : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Stripe Account ID",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.stripe_account_id ? row?.stripe_account_id : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Payout",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.payouts_enabled == false ? "Disable" : "Enable"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Account Status",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.charges_enabled == false ? "Disable" : "Enable"}</span>
          </div>
        </div>
      ),
    },
  ];

  const handleswitch = (id, isSelected, index) => {
    let data = {
      id: id,
      status: isSelected,
    };
    const newArray = Array.from(getcustomerResourceInfo);
    newArray[index].status = isSelected === "publish" ? "unpublish" : "publish";
    setGetCustomerResourceInfo(newArray);

    makePostRequest(URLS.UPDATE_ADMIN_RESOURCE, null, data, null).then((res) => {
      if (res.code === 200) {
        toast.success(res.message);
        handleResource();
      } else if (res.code === 400) {
        toast.success(res.message);
      }
    });
  };

  /*==================================   Student filter api calling for student permission share======================*/

  const handleStudentFilterData = () => {
    let data = {
      user_id: customerId,
    };
    console.log(data, "data");
    makePostRequest(URLS.STUDENT_FILTER_PERMISSIONSHARE, null, data, null)
      .then((res) => {
        console.log("student", res);
        if (res.code == 200) {
          setGetStudentFilterData(res.data.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleStudentFilterData();
  }, []);

  const handleCustomerViewDetails = () => {
    setLoader(true);
    let data = {
      id: customerId,
    };
    makePostRequest(URLS.CUSTOMER_GET_ONE, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setUserData(res.data.uesrData);
          setGetchildInfo(res.data.childList);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setUserData(error);
      });
  };

  /*==============================Resource Api Calling Here======================*/

  const [currentPageResource, setCurrentPageResource] = useState(1);
  const [countResource, setCountResource] = useState();
  const handleResource = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      resource_mode: resouceMode,
      page: currentPageResource,
      limit: 10,
    };

    makePostRequest(URLS.GET_RESOURCE_INFORMATION, null, data, null)
      .then((res) => {
        setLoader(false);
        if (res.code == 200) {
          setGetCustomerResourceInfo(res.data.data);
          setCountResource(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setGetCustomerResourceInfo(error);
      });
  };

  const handlePageChangeResource = (selectedPage) => {
    setCurrentPageResource(selectedPage.selected + 1);
  };
  const fetchDataResource = () => {
    let data = {
      user_id: customerId,
      resource_mode: resouceMode,
      page: currentPageResource,
      limit: 10,
    };
    makePostRequest(URLS.GET_RESOURCE_INFORMATION, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setGetCustomerResourceInfo(res.data.data);
          setCountResource(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataResource();
  }, [currentPageResource]);

  useEffect(() => {
    handleResource();
  }, [resouceMode]);

  /*===============Purchase Resource Api Calling Here=============*/

  const handlePurchaseResource = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      child_id: childId,
      page: currentPage,
      resource_type_status: resourceTypeStatus,
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      resource_id: "",
      limit: 10,
    };

    makePostRequest(URLS.GET_RESOURCE_PURCHASE, null, data, null)
      .then((res) => {
        setLoader(false);
        if (res.code == 200) {
          setPurchaseResourceData(res.data.data);
          setCount(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setPurchaseResourceData(error);
      });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  const fetchData = () => {
    let data = {
      user_id: customerId,
      child_id: childId,
      page: currentPage,
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      resource_id: "",
      limit: 10,
    };
    makePostRequest(URLS.GET_RESOURCE_PURCHASE, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setPurchaseResourceData(res.data.data);
          setCount(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  /*=======================Save Resource Api Calling Here=========================*/

  const [currentPageSave, setCurrentPageSave] = useState(1);
  const [countSave, setCountSave] = useState();

  const GetSaveResourceList = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      page: currentPageSave,
      child_id: child,
      grade_id: gradeId,
      start_date: selectedfromdateSaveResource ? moment(selectedfromdateSaveResource).format("YYYY-MM-DD") : "",
      end_date: selectedtodateSaveResource ? moment(selectedtodateSaveResource).format("YYYY-MM-DD") : "",
      resource_for: selectedSaveFor,
      limit: 10,
    };
    makePostRequest(URLS.GET_SAVED_RESOURCELIST, null, data, null)
      .then((res) => {
        setLoader(false);
        if (res.code == 200) {
          setSavedResourceList(res.data.data);
          setCountSave(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeSave = (selectedPage) => {
    setCurrentPageSave(selectedPage.selected + 1);
  };
  const fetchDataSave = () => {
    let data = {
      user_id: customerId,
      page: currentPageSave,
      child_id: child,
      grade_id: gradeId,
      start_date: selectedfromdateSaveResource ? moment(selectedfromdateSaveResource).format("YYYY-MM-DD") : "",
      end_date: selectedtodateSaveResource ? moment(selectedtodateSaveResource).format("YYYY-MM-DD") : "",
      resource_for: selectedSaveFor,
      limit: 10,
    };
    makePostRequest(URLS.GET_SAVED_RESOURCELIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setSavedResourceList(res.data.data);
          setCountSave(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataSave();
  }, [currentPageSave]);

  /*=================================Shared Resource Api Calling Here=====================*/

  const [currentPage4, setCurrentPage4] = useState(1);
  const [count4, setCount4] = useState();
  const [sharewith, setSharewith] = useState([]);

  const GetSharedResourceList = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      page: currentPage4,
      start_date: selectedfromdateSharedResource ? moment(selectedfromdateSharedResource).format("YYYY-MM-DD") : "",
      end_date: selectedtodateSharedResource ? moment(selectedtodateSharedResource).format("YYYY-MM-DD") : "",
      shared_by: selectShareWith,
      limit: 10,
    };
    makePostRequest(URLS.GET_SHARED_RESOURCELIST, null, data, null)
      .then((res) => {
        setLoader(false);
        if (res.code == 200) {
          setSharedResourceList(res.data.data);
          setSharewith(res.data.data);
          setCount4(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  const handlePageChange4 = (selectedPage) => {
    setCurrentPage4(selectedPage.selected + 1);
  };
  const fetchData4 = () => {
    let data = {
      user_id: customerId,
      page: currentPage4,
      start_date: selectedfromdateSharedResource ? moment(selectedfromdateSharedResource).format("YYYY-MM-DD") : "",
      end_date: selectedtodateSharedResource ? moment(selectedtodateSharedResource).format("YYYY-MM-DD") : "",
      shared_by: selectShareWith,
      limit: 10,
    };
    makePostRequest(URLS.GET_SHARED_RESOURCELIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setSharedResourceList(res.data.data);
          setCount4(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData4();
  }, [currentPage4]);

  /*=====================Child permission Api Calling Here======================*/
  const [currentPage2, setCurrentPage2] = useState(1);
  const [count2, setCount2] = useState();

  const GetChildPermissionShare = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      page: currentPage2,
      start_date: selectedfromdateChildPermission ? moment(selectedfromdateChildPermission).format("YYYY-MM-DD") : "",
      end_date: selectedtodateChildPermission ? moment(selectedtodateChildPermission).format("YYYY-MM-DD") : "",
      child_id: studentId,
      limit: 10,
    };
    console.log(data, "childdata");

    makePostRequest(URLS.GET_CHILD_PERMISSION_SHARE, null, data, null)
      .then((res) => {
        setLoader(false);
        // console.log(res,"child permission")
        if (res.code == 200) {
          setChildPermissionShare(res.data.data);
          setCount2(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange2 = (selectedPage) => {
    setCurrentPage2(selectedPage.selected + 1);
  };
  //   const fetchData2 = () => {
  // 	let data = {

  // 		user_id:customerId,
  // 		// child_parent_id:"",
  // 	   page:currentPage2,
  // 	   limit: 10,
  //   }
  // 	makePostRequest(URLS.GET_CHILD_PERMISSION_SHARE, null, data, null)
  // 	  .then((res) => {
  // 		if (res.code == 200) {
  // 			setChildPermissionShare(res.data.data)
  // 		setCount2(Math.ceil(res.data.count/10))
  // 		}
  // 	  })
  // 	  .catch((error) => {
  // 		console.log(error);
  // 	  });
  //    };

  useEffect(() => {
    GetChildPermissionShare();
  }, [currentPage2]);

  /*==========================Review Api Calling Here==================*/

  const [currentPage3, setCurrentPage3] = useState(1);
  const [count3, setCount3] = useState();

  const GetReview = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      // start_date:selectedfromdateReview,
      // end_date:selectedtodateReview,
      start_date: selectedfromdateReview ? moment(selectedfromdateReview).format("YYYY-MM-DD") : "",
      end_date: selectedtodateReview ? moment(selectedtodateReview).format("YYYY-MM-DD") : "",
      page: currentPage3,
      limit: 10,
    };

    makePostRequest(URLS.GET_REVIEW, null, data, null)
      .then((res) => {
        setLoader(false);
        // console.log(res,"review")
        if (res.code == 200) {
          setReview(res.data.data);
          setCount3(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange3 = (selectedPage) => {
    setCurrentPage3(selectedPage.selected + 1);
  };
  const fetchData3 = () => {
    let data = {
      user_id: customerId,
      page: currentPage3,
      //    start_date:selectedfromdateReview,
      // 	end_date:selectedtodateReview,
      start_date: selectedfromdateReview ? moment(selectedfromdateReview).format("YYYY-MM-DD") : "",
      end_date: selectedtodateReview ? moment(selectedtodateReview).format("YYYY-MM-DD") : "",
      limit: 10,
    };
    makePostRequest(URLS.GET_REVIEW, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setReview(res.data.data);
          setCount3(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData3();
  }, [currentPage3]);

  /*==============================Comment Api Calling Here======================*/
  const [currentPageComment, setCurrentPageComment] = useState(1);
  const [countComment, setCountComment] = useState();

  const GetCustomerComment = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      page: currentPageComment,
      // start_date:selectedfromdateComment,
      // end_date:selectedtodateComment,
      start_date: selectedfromdateComment ? moment(selectedfromdateComment).format("YYYY-MM-DD") : "",
      end_date: selectedtodateComment ? moment(selectedtodateComment).format("YYYY-MM-DD") : "",
    };
    // console.log(data,"commentpay")
    makePostRequest(URLS.GET_CUSTOMER_COMMENT, null, data, null)
      .then((res) => {
        setLoader(false);
        // console.log(res,"comment")
        if (res.code == 200) {
          setCustomerComment(res.data.data);
          setCountComment(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeComment = (selectedPage) => {
    setCurrentPageComment(selectedPage.selected + 1);
  };
  const fetchDataComment = () => {
    let data = {
      user_id: customerId,
      page: currentPageComment,
      // start_date:selectedfromdateComment,
      // end_date:selectedtodateComment,
      start_date: selectedfromdateComment ? moment(selectedfromdateComment).format("YYYY-MM-DD") : "",
      end_date: selectedtodateComment ? moment(selectedtodateComment).format("YYYY-MM-DD") : "",
    };

    makePostRequest(URLS.GET_CUSTOMER_COMMENT, null, data, null)
      .then((res) => {
        // console.log(res,"comment")
        if (res.code == 200) {
          setCustomerComment(res.data.data);
          setCountComment(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataComment();
  }, [currentPageComment]);

  /*========================Like Api Calling Here============================*/
  const [currentPageLike, setCurrentPageLike] = useState(1);
  const [countLike, setCountLike] = useState();

  const GetCustomerLike = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      // start_date:selectedfromdateLike,
      // end_date:selectedtodateLike,
      start_date: selectedfromdateLike ? moment(selectedfromdateLike).format("YYYY-MM-DD") : "",
      end_date: selectedtodateLike ? moment(selectedtodateLike).format("YYYY-MM-DD") : "",
      page: currentPageLike,
    };
    // console.log(data,"likepay")
    makePostRequest(URLS.GET_CUSTOMER_LIKE, null, data, null)
      .then((res) => {
        setLoader(false);
        // console.log(res,"like")
        if (res.code == 200) {
          setCustomerLike(res.data.data);
          setCountLike(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeLike = (selectedPage) => {
    setCurrentPageLike(selectedPage.selected + 1);
  };
  const fetchDataLike = () => {
    let data = {
      user_id: customerId,
      // start_date:selectedfromdateLike,
      // end_date:selectedtodateLike,
      start_date: selectedfromdateLike ? moment(selectedfromdateLike).format("YYYY-MM-DD") : "",
      end_date: selectedtodateLike ? moment(selectedtodateLike).format("YYYY-MM-DD") : "",
      page: currentPageLike,
    };

    makePostRequest(URLS.GET_CUSTOMER_LIKE, null, data, null)
      .then((res) => {
        // console.log(res,"like")
        if (res.code == 200) {
          setCustomerLike(res.data.data);
          setCountLike(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataLike();
  }, [currentPageLike]);

  /*==============================Subscription Purchase Api Calling Here========================*/

  const [currentPageSubscription, setCurrentPageSubscription] = useState(1);
  const [countSubscription, setCountSubscription] = useState();

  const GetSubscriptionPurchase = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      page: currentPageSubscription,
      subscription_plan: selectPaymentType ? selectPaymentType : "",
      start_date: selectedfromdateSubscription ? moment(selectedfromdateSubscription).format("YYYY-MM-DD") : "",
      end_date: selectedtodateSubscription ? moment(selectedtodateSubscription).format("YYYY-MM-DD") : "",
      // limit:10,
    };
    // console.log(data,"datasubscription purchase")
    makePostRequest(URLS.GET_SUBSCRIPTION_PURCHASE, null, data, null)
      .then((res) => {
        setLoader(false);
        // console.log(res,"subscription purchase")
        if (res.code == 200) {
          setSubscriptionPurchase(res.data.data);
          setCountSubscription(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeSubscription = (selectedPage) => {
    setCurrentPageSubscription(selectedPage.selected + 1);
  };
  const fetchDataSubscription = () => {
    let data = {
      user_id: customerId,
      page: currentPageSubscription,
      subscription_plan: selectPaymentType ? selectPaymentType : "",
      start_date: selectedfromdateSubscription ? moment(selectedfromdateSubscription).format("YYYY-MM-DD") : "",
      end_date: selectedtodateSubscription ? moment(selectedtodateSubscription).format("YYYY-MM-DD") : "",

      // limit:10,
    };

    makePostRequest(URLS.GET_SUBSCRIPTION_PURCHASE, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setSubscriptionPurchase(res.data.data);
          setCountSubscription(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataSubscription();
  }, [currentPageSubscription]);

  /*========================Friend List Api Calling  Matched Member Here====================*/

  const [currentPage1, setCurrentPage1] = useState(1);
  const [count1, setCount1] = useState();

  const GetFriendList = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      page: currentPage1,
      limit: 10,
    };

    makePostRequest(URLS.GET_FIENDLIST, null, data, null)
      .then((res) => {
        setLoader(false);
        console.log(res, "friend list");
        if (res.code == 200) {
          setFriendList(res.data.FriendData);
          setCount1(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange1 = (selectedPage) => {
    setCurrentPage1(selectedPage.selected + 1);
  };

  //   const fetchData1 = () => {
  // 	let data = {

  // 		user_id:customerId,
  // 	   page:currentPage1,
  // 	   limit: 10,
  //   }
  // 	makePostRequest(URLS.GET_FIENDLIST, null, data, null)
  // 	  .then((res) => {
  // 		if (res.code == 200) {
  // 			setFriendList(res.data.FriendData);
  // 		setCount1(Math.ceil(res.data.count/10))
  // 		}
  // 	  })
  // 	  .catch((error) => {
  // 		console.log(error);
  // 	  });
  //    };

  useEffect(() => {
    GetFriendList();
  }, [currentPage1]);

  /*========================Friend List Api Calling Non Matched Member Here====================*/

  const [currentPageNonMatched, setCurrentPageNonMatched] = useState(1);
  const [countNonMatched, setCountNonMatched] = useState();

  const GetFriendListNonMatched = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      page: currentPageNonMatched,
      limit: 10,
    };

    makePostRequest(URLS.GET_NON_MATCHED_FRIEND, null, data, null)
      .then((res) => {
        setLoader(false);
        console.log(res, "friend list NonMatched");
        if (res.code == 200) {
          setFriendListNonMatched(res.data.FriendData);
          setCountNonMatched(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeNonMatched = (selectedPage) => {
    setCurrentPageNonMatched(selectedPage.selected + 1);
  };

  useEffect(() => {
    GetFriendListNonMatched();
  }, [currentPageNonMatched]);

  useEffect(() => {
    GetFriendListNonMatched();
  }, [selectFriendMemberType]);

  /*=============================GRoup Api calling here===========================*/

  const [currentPageGroup, setCurrentPageGroup] = useState(1);
  const [countGroup, setCountGroup] = useState();

  const GetGroupList = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      joined_as: joinedAsType,
      start_date: selectedfromdateGroup ? moment(selectedfromdateGroup).format("YYYY-MM-DD") : "",
      end_date: selectedtodateGroup ? moment(selectedtodateGroup).format("YYYY-MM-DD") : "",
      page: currentPageGroup,
      limit: 10,
    };
    console.log(data, "datattttt");
    makePostRequest(URLS.GETGROUPLIST_CUSTOMER, null, data, null)
      .then((res) => {
        setLoader(false);
        console.log(res, "Group listtttt");
        if (res.code == 200) {
          setGroupList(res.data.groupMemberList);
          setCountGroup(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeGroup = (selectedPage) => {
    setCurrentPageGroup(selectedPage.selected + 1);
  };

  // const fetchDataGroup= () => {
  //   let data = {

  // 	user_id:customerId,
  // 	page:currentPageGroup,
  // 	limit:10,
  // }
  //   makePostRequest(URLS.GETGROUPLIST_CUSTOMER, null, data, null)
  // 	.then((res) => {
  // 	  if (res.code == 200) {
  // 		setGroupList(res.data.groupMemberList);
  // 		  setCountGroup(Math.ceil(res.data.count/10))
  // 	  }
  // 	})
  // 	.catch((error) => {
  // 	  console.log(error);
  // 	});
  //  };

  useEffect(() => {
    //fetchDataGroup()
    GetGroupList();
  }, [currentPageGroup]);

  /*=============================Stripe Api calling here===========================*/

  const [stripeList, setStripeList] = useState([]);
  const [currentPageStripe, setCurrentPageStripe] = useState(1);
  const [countStripe, setCountStripe] = useState();

  const GetStripeList = () => {
    setLoader(true);
    let data = {
      id: customerId,
      page_number: currentPageStripe,
      limit: 10,
    };
    // console.log(data,"datastrip" );

    makePostRequest(URLS.GETALLSTRIPE, null, data, null)
      .then((res) => {
        setLoader(false);
        // console.log(res,"Stripe list")
        if (res.code == 200) {
          setStripeList(res.data.data);
          setCountStripe(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeStripe = (selectedPage) => {
    setCurrentPageStripe(selectedPage.selected + 1);
  };

  const fetchDataStripe = () => {
    let data = {
      id: customerId,
      page_number: currentPageStripe,
      limit: 10,
    };
    makePostRequest(URLS.GETALLSTRIPE, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setStripeList(res.data.data);
          setCountStripe(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataStripe();
  }, [currentPageStripe]);

  useEffect(() => {
    GetStripeList();
  }, [customerId]);

  /*========================Mentoring Session Api Calling Here====================*/
  const [currentPageMentoring, setCurrentPageMentoring] = useState(1);
  const [countMentoring, setCountMentoring] = useState();

  const GetMentoringSession = () => {
    setLoader(true);
    let data = {
      user_id: customerId,
      page: currentPageMentoring,
      limit: 10,
      start_date: selectedfromdatementor ? moment(selectedfromdatementor).format("YYYY-MM-DD") : "",
      end_date: selectedtodatementor ? moment(selectedtodatementor).format("YYYY-MM-DD") : "",
      type: userMentorId ? userMentorId : "user",
    };
    console.log(data, "mentoring session data");
    makePostRequest(URLS.GET_MENTORING_SESSION, null, data, null)
      .then((res) => {
        setLoader(false);
        console.log(res, "mentoring session123");
        if (res.code == 200) {
          setMentoringSessionData(res.data.data);
          setCountMentoring(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePageChangeMentoring = (selectedPage) => {
    setCurrentPageMentoring(selectedPage.selected + 1);
  };

  useEffect(() => {
    GetMentoringSession();
  }, [currentPageMentoring]);

  useEffect(() => {
    GetMentoringSession();
  }, [userMentorId]);

  /*====================Grade level Api calling Here===============================*/

  const getGradeLevel = () => {
    makeGetRequest(URLS.GET_ALL_GRADE_LEVEL, {}, null)
      .then((res) => {
        if (res.code == 200) {
          setManageGrade(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*========================Child  Api Calling Here====================*/

  const GetChildData = () => {
    let data = {
      user_id: customerId,
      page_number: 1,
    };
    makePostRequest(URLS.GET_CHILD_DATA, null, data, null)
      .then((res) => {
        // console.log(res,"childres")
        if (res.code == 200) {
          setChildData(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [resourceTypeStatus, setResourceTypeStatus] = useState("");

  const HandleSlelectChild = (e) => {
    setChildId(e.target.value);
  };

  const HandleResourceTypeStatus = (e) => {
    setResourceTypeStatus(e.target.value);
  };

  const HandleSlelectresourceType = (e) => {
    setResouceMode(e.target.value);
    setCurrentPageResource(1);
  };

  const HandleFromDate = (e) => {
    setSelectedfromDate(e);
    settoDateError("");
  };
  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setformDateError("");
  };

  const HandleFromDateChildPermission = (e) => {
    setSelectedfromDateChildPermission(e);
    setformDateErrorChildPermission("");
  };
  const HandleToDateChildPermission = (e) => {
    setSelectedToDateChildPermission(e);
    settoDateErrorChildPermission("");
  };
  const HandleFromDatementor = (e) => {
    setSelectedfromDatementor(e);
    //settoDateErrormentor("")
    setformDateErrormentor("");
  };
  const HandleToDatementor = (e) => {
    setSelectedToDatementor(e);
    //setformDateErrormentor("")
    settoDateErrormentor("");
  };

  const HandleFromDateSubscription = (e) => {
    setSelectedfromDateSubscription(e);
    settoDateErrorSubscription("");
    setformDateErrorSubscription("");
  };
  const HandleToDateSubscription = (e) => {
    setSelectedToDateSubscription(e);
    //setformDateErrorSubscription("")
    settoDateErrorSubscription("");
  };

  const HandleFromDateSharedResource = (e) => {
    setSelectedfromDateSharedResource(e);
    setformDateErrorSharedResource("");
  };
  const HandleToDateSharedResource = (e) => {
    setSelectedToDateSharedResource(e);
    settoDateErrorSharedResource("");
  };
  const HandleSlelectShareWith = (e) => {
    setSelectShareWith(e.target.value);
  };

  const HandleFromDateSaveResource = (e) => {
    setSelectedfromDateSaveResource(e);
    settoDateErrorSaveResource("");
  };
  const HandleToDateSaveResource = (e) => {
    setSelectedToDateSaveResource(e);
    setformDateErrorSaveResource("");
  };

  const HandleSlelectSaveFor = (e) => {
    setSelectedSaveFor(e.target.value);
  };

  const HandleSlelectGrade = (e) => {
    setGradeId(e.target.value);
  };

  const HandleSlelectChildId = (e) => {
    setChild(e.target.value);
  };

  const HandleSlelectStudent = (e) => {
    setStudentId(e.target.value);
  };

  const HandleFromDateComment = (e) => {
    setSelectedfromDateComment(e);
    setformDateErrorComment("");
  };
  const HandleToDateComment = (e) => {
    setSelectedToDateComment(e);
    settoDateErrorComment("");
  };

  const HandleFromDateReview = (e) => {
    setSelectedfromDateReview(e);
    settoDateErrorReview("");
  };
  const HandleToDateReview = (e) => {
    setSelectedToDateReview(e);
    setformDateErrorReview("");
  };

  const HandleFromDateLike = (e) => {
    setSelectedfromDateLike(e);
    setformDateErrorLike("");
  };
  const HandleToDateLike = (e) => {
    setSelectedToDateLike(e);
    settoDateErrorLike("");
  };

  const HandleFromDateGroup = (e) => {
    setSelectedfromDateGroup(e);
    //settoDateErrorGroup("")
    setformDateErrorGroup("");
  };
  const HandleToDateGroup = (e) => {
    setSelectedToDateGroup(e);
    settoDateErrorGroup("");
  };

  const handleSearchby = (e) => {
    setUserMentorId(e.target.value);
  };

  const HandleSlelectPaymenttype = (e) => {
    setSelectPaymentType(e.target.value);
  };

  const HandleFriendMembertype = (e) => {
    setSelectFriendMemberType(e.target.value);
  };

  const HandleJoinedAs = (e) => {
    setJoinedAsType(e.target.value);
  };

  const handleValidation = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select start date");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be grater than start date");
    } else {
      handlePurchaseResource();
    }
  };

  const handleValidationChildPermission = () => {
    if (selectedfromdateChildPermission != null && selectedtodateChildPermission == null) {
      settoDateErrorChildPermission("please select end date");
    } else if (selectedtodateChildPermission != null && selectedfromdateChildPermission == null) {
      setformDateErrorChildPermission("please select start date");
    } else if (selectedfromdateChildPermission > selectedtodateChildPermission) {
      toast.error("end date should be grater than start date");
    } else {
      GetChildPermissionShare();
    }
  };

  const handleValidationmentor = () => {
    if (selectedfromdatementor != null && selectedtodatementor == null) {
      settoDateErrormentor("please select end date");
    } else if (selectedtodatementor != null && selectedfromdatementor == null) {
      setformDateErrormentor("please select start date");
    } else if (selectedfromdatementor > selectedtodatementor) {
      toast.error("end date should be grater than start date");
    } else {
      GetMentoringSession();
      setCurrentPageMentoring(1);
    }
  };

  const handleValidationSharedResource = () => {
    if (selectedfromdateSharedResource != null && selectedtodateSharedResource == null) {
      settoDateErrorSharedResource("please select end date");
    } else if (selectedtodateSharedResource != null && selectedfromdateSharedResource == null) {
      setformDateErrorSharedResource("please select start date");
    } else if (selectedfromdateSharedResource > selectedtodateSharedResource) {
      toast.error("end date should be grater than start date");
    } else {
      GetSharedResourceList();
    }
  };

  const handleValidationSaveResource = () => {
    if (selectedfromdateSaveResource != null && selectedtodateSaveResource == null) {
      settoDateErrorSaveResource("please select end date");
    } else if (selectedtodateSaveResource != null && selectedfromdateSaveResource == null) {
      setformDateErrorSaveResource("please select start date");
    } else if (selectedfromdateSaveResource > selectedtodateSaveResource) {
      toast.error("end date should be grater than start date");
    } else {
      GetSaveResourceList();
    }
  };

  const handleValidationComment = () => {
    if (selectedfromdateComment != null && selectedtodateComment == null) {
      settoDateErrorComment("please select end date");
    } else if (selectedtodateComment != null && selectedfromdateComment == null) {
      setformDateErrorComment("please select start date");
    } else if (selectedfromdateComment > selectedtodateComment) {
      toast.error("end date should be grater than start date");
    } else {
      GetCustomerComment();
    }
  };

  const handleValidationReview = () => {
    if (selectedfromdateReview != null && selectedtodateReview == null) {
      settoDateErrorReview("please select end date");
    } else if (selectedtodateReview != null && selectedfromdateReview == null) {
      setformDateErrorReview("please select start date");
    } else if (selectedfromdateReview > selectedtodateReview) {
      toast.error("end date should be grater than start date");
    } else {
      GetReview();
    }
  };

  const handleValidationLike = () => {
    if (selectedfromdateLike != null && selectedtodateLike == null) {
      settoDateErrorLike("please select end date");
    } else if (selectedtodateLike != null && selectedfromdateLike == null) {
      setformDateErrorLike("please select start date");
    } else if (selectedfromdateLike > selectedtodateLike) {
      toast.error("end date should be grater than start date");
    } else {
      GetCustomerLike();
    }
  };

  const handleValidationSubscription = () => {
    if (selectedfromdateSubscription != null && selectedtodateSubscription == null) {
      settoDateErrorSubscription("please select end date");
    } else if (selectedtodateSubscription != null && selectedfromdateSubscription == null) {
      setformDateErrorSubscription("please select start date");
    } else if (selectedfromdateSubscription > selectedtodateSubscription) {
      toast.error("end date should be grater than start date");
    } else {
      GetSubscriptionPurchase();
    }
  };

  const handleValidationGroup = () => {
    if (selectedfromdateGroup != null && selectedtodateGroup == null) {
      settoDateErrorGroup("please select end date");
    } else if (selectedtodateGroup != null && selectedfromdateGroup == null) {
      setformDateErrorGroup("please select start date");
    } else if (selectedfromdateGroup > selectedtodateGroup) {
      toast.error("end date should be grater than start date");
    } else {
      GetGroupList();
    }
  };

  useEffect(() => {
    handleCustomerViewDetails();
    handlePurchaseResource();
    handleResource();
    GetSaveResourceList();
    GetSharedResourceList();
    GetChildPermissionShare();
    GetReview();
    GetCustomerComment();
    GetCustomerLike();
    GetSubscriptionPurchase();
    GetFriendList();
    GetMentoringSession();
    getGradeLevel();
    GetChildData();
    // setTabno();
    GetGroupList();
    //GetStripeList();
  }, []);

  return (
    <>
      <Head title="Customer Details"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4">
          {" "}
          Customer Details
          <button
            onClick={() => history.goBack()}
            className="btn btn-outline-light bg-white d-none d-sm-inline-flex float-end"
          >
            <em className="icon ni ni-arrow-left"></em>
            <span>Back</span>
          </button>
        </h3>

        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }}>
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <>
            <div style={{ marginBottom: "4px" }}>
              <PreviewCard>
                <div className="user-card">
                  <div className="imguploadBox">
                    {userData && userData?.photo ? (
                      <img className="customerImg" src={S3_BUCKET_URL + userData?.photo} />
                    ) : (
                      <img src={bnb} alt="image" />
                    )}
                  </div>

                  <div className="user-info">
                    <span className="lead-text">
                      {userData && userData.firstName ? userData.firstName : "--"}{" "}
                      {userData && userData.lastName ? userData.lastName : ""}{" "}
                    </span>
                    <span className="sub-text">{userData.email}</span>
                  </div>
                </div>
              </PreviewCard>
            </div>

            <PreviewCard>
              <Row className="g-gs">
                <Col md={4}>
                  <ul className="nav link-list-menu border border-light round m-0">
                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "1",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("1");
                          sessionStorage.setItem("tabcust", "1");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="user"></Icon>
                        <span>Customer Details</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "2",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("2");
                          //   sessionStorage.setItem("tabcust", "2");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="users"></Icon>
                        <span>Students Details</span>
                      </a>
                    </li>
                    <li>
                      <li>
                        <a
                          href="#tab"
                          className={classnames({
                            active: verticalIconTab === "3",
                          })}
                          onClick={(ev) => {
                            ev.preventDefault();
                            setVerticalIconTab("3");
                            //   sessionStorage.setItem("tabcust", "3");
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <Icon name="file-docs"></Icon>
                          <span>Resource Information</span>
                        </a>
                      </li>

                      <li>
                        <a
                          href="#tab"
                          className={classnames({
                            active: verticalIconTab === "4",
                          })}
                          onClick={(ev) => {
                            ev.preventDefault();
                            setVerticalIconTab("4");
                            //   sessionStorage.setItem("tabcust", "4");
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <Icon name="external"></Icon>
                          <span>Student Permission Shared</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#tab"
                          className={classnames({
                            active: verticalIconTab === "5",
                          })}
                          onClick={(ev) => {
                            ev.preventDefault();
                            setVerticalIconTab("5");
                            //   sessionStorage.setItem("tabcust", "5");
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <Icon name="file-docs"></Icon>
                          <span>Purchase Resource Details</span>
                        </a>
                      </li>

                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "6",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("6");
                          //   sessionStorage.setItem("tabcust", "6");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="star"></Icon>
                        <span>Reviews</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "7",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("7");
                          //   sessionStorage.setItem("tabcust", "7");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="save"></Icon>
                        <span>Shared Resources</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "8",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("8");
                          //   sessionStorage.setItem("tabcust", "8");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="save-fill"></Icon>
                        <span>Saved Resources</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "9",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("9");
                          //   sessionStorage.setItem("tabcust", "9");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="comments"></Icon>
                        <span>Comment Details</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "10",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("10");
                          //   sessionStorage.setItem("tabcust", "10");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="link"></Icon>
                        <span>Scheduled Sessions Details</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "11",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("11");
                          //   sessionStorage.setItem("tab", "11");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="sign-dollar"></Icon>
                        <span>Subscription Purchase Details</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "12",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("12");
                          //   sessionStorage.setItem("tabcust", "12");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="user-list-fill"></Icon>
                        <span>Friend List Details</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "14",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("14");
                          //   sessionStorage.setItem("tabcust", "14");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="users"></Icon>
                        <span>Group List Details</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "13",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("13");
                          //   sessionStorage.setItem("tabcust", "13");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="thumbs-up"></Icon>
                        <span>Resource Like Details</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="#tab"
                        className={classnames({
                          active: verticalIconTab === "15",
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalIconTab("15");
                          //   sessionStorage.setItem("tabcust", "15");
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Icon name="sign-dollar"> </Icon>
                        <span>Stripe Account Details</span>
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col md={8}>
                  <TabContent activeTab={verticalIconTab}>
                    <TabPane tabId="1">
                      <Card className="card-bordered">
                        <div>
                          <PreviewCard>
                            <div className="user-card">
                              <div className="imguploadBox">
                                {userData && userData?.photo ? (
                                  <img className="customerImg" src={S3_BUCKET_URL + userData?.photo} />
                                ) : (
                                  <img src={bnb} alt="image" />
                                )}
                              </div>

                              <div className="user-info">
                                <span className="lead-text">
                                  {userData && userData.firstName ? userData.firstName : "--"}{" "}
                                  {userData && userData.lastName ? userData.lastName : ""}{" "}
                                </span>
                                <span className="sub-text">{userData.email}</span>
                              </div>
                            </div>
                          </PreviewCard>
                        </div>

                        <ul className="data-list is-compact">
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Phone Number</div>
                              <div className="data-value text-soft">{userData.phone ? userData.phone : "--"}</div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Number of Students</div>
                              <div className="data-value">
                                {userData && userData.number_of_child ? userData.number_of_child : "--"}
                              </div>
                            </div>
                          </li>

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Ideal Homeschool Schedule</div>
                              <div className="data-value">
                                {/* {userData?.tbl_ideal_homeschools?.[0]?.name ? userData?.tbl_ideal_homeschools?.[0]?.name:"--"}  */}
                                {/* {userData?.tbl_ideal_homeschools?.length > 0 ?  userData?.tbl_ideal_homeschools  && userData.tbl_ideal_homeschools.map ((val)=>{

							return(
								<div className="data-value">{val.name}</div>
							)
						}):"--"} */}

                                {userData?.tbl_ideal_homeschools?.length > 0
                                  ? userData.tbl_ideal_homeschools.map((val) => {
                                      const modifiedName = val?.name?.includes("experimental")
                                        ? val?.name?.replace("experimental", "Experiential")
                                        : val?.name;

                                      return <div className="data-value">{modifiedName}</div>;
                                    })
                                  : "--"}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Faith Background</div>
                              <div className="data-value text-break">
                                {userData && userData.faith_background ? userData.faith_background : "--"}
                              </div>
                            </div>
                          </li>
                          {userData && userData.faith_background === "No" ? (
                            ""
                          ) : (
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Faith Affiliation</div>
                                <div className="data-value">
                                  {userData && userData.faith_affiliation ? userData.faith_affiliation : "--"}
                                </div>
                              </div>
                            </li>
                          )}

                          {userData && userData.faith_background === "No" ? (
                            ""
                          ) : (
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Importance of Integrating Faith Background</div>
                                <div className="data-value text-break">
                                  {userData && userData.family_faith_background
                                    ? userData.family_faith_background
                                    : "--"}
                                </div>
                              </div>
                            </li>
                          )}

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Importance of Integrating Character and Values</div>
                              <div className="data-value">
                                {userData && userData.child_education_character_value
                                  ? userData.child_education_character_value
                                  : "--"}
                              </div>
                            </div>
                          </li>

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">User Type</div>
                              <div className="data-value">
                                {userData?.tbl_users_roles?.length > 0
                                  ? userData?.tbl_users_roles &&
                                    userData?.tbl_users_roles.map((val, i) => {
                                      return <div className="data-value">{val.tbl_user_role.role_name}</div>;
                                    })
                                  : "--"}
                              </div>
                            </div>
                          </li>

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Stripe ID</div>
                              <div className="data-value">
                                {userData && userData?.stripe_accountId ? userData?.stripe_accountId : "--"}
                              </div>
                            </div>
                          </li>

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Registration Date</div>
                              <div className="data-value">{moment(userData.created_at).format("MM-DD-YYYY")}</div>
                            </div>
                          </li>

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Bio</div>
                              <div className="data-value">
                                {userData && userData.bio_about ? userData.bio_about : "--"}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Latitude</div>
                              <div className="data-value">
                                {userData && userData.latitude ? userData.latitude : "--"}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Longitude</div>
                              <div className="data-value">
                                {userData && userData.longitude ? userData.longitude : "--"}
                              </div>
                            </div>
                          </li>

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Zip Code</div>
                              <div className="data-value">{userData && userData.zipcode ? userData.zipcode : "--"}</div>
                            </div>
                          </li>

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Address</div>
                              <div className="data-value">{userData && userData.address ? userData.address : "--"}</div>
                            </div>
                          </li>
                        </ul>
                      </Card>
                    </TabPane>
                    <TabPane tabId="2">
                      {getchildinfo.length == 0 ? (
                        <div className="nodatafounds">No Data Found...</div>
                      ) : (
                        getchildinfo.map((val) => {
                          return (
                            <>
                              <PreviewCard>
                                <div className="user-card">
                                  <div className="imguploadBox">
                                    {val?.photo == null ? (
                                      <img src={bnb} alt="images" />
                                    ) : (
                                      <img src={S3_BUCKET_URL + val?.photo} alt="images" style={{ height: "40px" }} />
                                    )}
                                  </div>

                                  <div className="user-info">
                                    <span className="lead-text">
                                      {val.firstName} {val.lastName}
                                    </span>
                                  </div>
                                </div>
                              </PreviewCard>

                              <Card className="card-bordered mb-5">
                                <ul className="data-list is-compact">
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Date of Birth</div>
                                      <div className="data-value">
                                        {/* {moment(val?.dob).format('MM-DD-YYYY')} */}
                                        {val.dob_month != null && val.dob_year != null
                                          ? val.dob_month + "-" + val.dob_year
                                          : val.dob_month != null && val.dob_year == null
                                          ? val.dob_month
                                          : val.dob_year != null && val.dob_month == null
                                          ? val.dob_year
                                          : "--"}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Registration Date</div>
                                      <div className="data-value">
                                        {moment(val?.created_at ? val?.created_at : "--").format("MM-DD-YYYY")}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Gender</div>
                                      <div className="data-value">{val?.gender ? val?.gender : "--"}</div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Profile Completed</div>
                                      <div className="data-value text-break">
                                        {val?.profile_completed ? val?.profile_completed : "--"}
                                      </div>
                                    </div>
                                  </li>

                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Grade Level</div>
                                      <div className="data-value text-break">
                                        {val?.tbl_grade_level?.name ? val?.tbl_grade_level?.name : "--"}
                                      </div>
                                    </div>
                                  </li>
                                  {/* <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">User Name</div>
						<div className="data-value text-break">{val?.userName ? val?.userName :"--"}</div>
                      </div>
                    </li> */}

                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Educational Background</div>
                                      {/* <div className="data-value text-break">{val.childEductionbackground[0]?.tbl_educational_course?.name ? val.childEductionbackground[0]?.tbl_educational_course?.name : "--"}</div> */}
                                      <div className="data-label">
                                        {val.childEductionbackground.length > 0
                                          ? val.childEductionbackground.map((edu, i) => (
                                              <div key={edu.id}>
                                                {edu.tbl_educational_course ? edu.tbl_educational_course.name : "--"}
                                                {i < val.childEductionbackground.length - 1 ? ", " : ""}
                                              </div>
                                            ))
                                          : "--"}{" "}
                                      </div>
                                    </div>
                                  </li>

                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Instructional Style</div>
                                      {/* <div className="data-value text-break">{val.childInstructionalStyle[0]?.tbl_instructional_style?.name ? val.childInstructionalStyle[0]?.tbl_instructional_style?.name : "--"}</div> */}
                                      <div className="data-label">
                                        {val.childInstructionalStyle.length > 0
                                          ? val.childInstructionalStyle.map((learning, i) => (
                                              <div key={learning.id}>
                                                {learning.tbl_instructional_style
                                                  ? learning.tbl_instructional_style?.name
                                                  : "--"}
                                                {i < val.childInstructionalStyle.length - 1 ? ", " : ""}
                                              </div>
                                            ))
                                          : "--"}{" "}
                                      </div>
                                    </div>
                                  </li>

                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Learning Style</div>
                                      {/* <div className="data-value text-break">{val.childlearningstyle[0]?.tbl_learning_style?.name ? val.childlearningstyle[0]?.tbl_learning_style?.name : "--"}</div> */}
                                      <div className="data-label">
                                        {val.childlearningstyle.length > 0
                                          ? val.childlearningstyle.map((learning, i) => (
                                              <div key={learning.id}>
                                                {learning.tbl_learning_style ? learning.tbl_learning_style?.name : "--"}
                                                {i < val.childlearningstyle.length - 1 ? ", " : ""}
                                              </div>
                                            ))
                                          : "--"}{" "}
                                      </div>
                                    </div>
                                  </li>

                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Special Learning Considerations</div>
                                      {/* <div className="data-value text-break">{val.childspeciallearning[0]?.tbl_special_learning_course?.name ? val.childspeciallearning[0]?.tbl_special_learning_course?.name : "--"}</div> */}
                                      <div className="data-label">
                                        {val.childspeciallearning.length > 0
                                          ? val.childspeciallearning.map((learning, i) => (
                                              <div key={learning.id}>
                                                {learning.tbl_special_learning_course
                                                  ? learning.tbl_special_learning_course?.name
                                                  : "--"}
                                                {i < val.childspeciallearning.length - 1 ? ", " : ""}
                                              </div>
                                            ))
                                          : "--"}{" "}
                                      </div>
                                    </div>
                                  </li>

                                  <p
                                    style={{
                                      margin: "33px 0px -10px 26px",
                                      fontSize: "20px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Admin User List
                                  </p>
                                  {val.childUser.length > 0 ? (
                                    <ReactDataTable
                                      data={val.childUser}
                                      columns={columnsAdminUser}
                                      className="nk-tb-list"
                                      // searchable={true}
                                      // pagination
                                    />
                                  ) : (
                                    <div className="sc-fLcnxK dApqnJ">
                                      <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                                        <div className="sc-iveFHk bzRnkJ">
                                          <div className="p-2">Access not granted</div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </ul>
                              </Card>
                            </>
                          );
                        })
                      )}
                    </TabPane>

                    <TabPane tabId="3">
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong> Select Resource Mode</strong>
                          </label>

                          <select value={resouceMode} className="form-select" onChange={HandleSlelectresourceType}>
                            {/* <option value="">Select Resource Mode</option> */}
                            <option value="save">Save</option>
                            <option value="serve">Serve</option>
                          </select>
                        </div>
                      </div>
                      {getcustomerResourceInfo.length == 0 ? (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <ReactDataTable
                          data={getcustomerResourceInfo}
                          columns={columns}
                          className="nk-tb-list"
                          // searchable={true}
                          // pagination
                        />
                      )}

                      {getcustomerResourceInfo?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countResource}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeResource}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPane>

                    <TabPane tabId="4">
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Select Student</strong>
                            </label>
                            <select value={studentId} className="form-select" onChange={HandleSlelectStudent}>
                              <option value="">All</option>
                              {getStudentFilterData.map((val) => {
                                return (
                                  <>
                                    <option value={val.tbl_child.id}>
                                      {val.tbl_child.firstName} {val.tbl_child.lastName}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdateChildPermission}
                              className="form-control date-picker"
                              onChange={HandleFromDateChildPermission}
                            />
                          </div>

                          <span className="error">{formDateErrorChildPermission}</span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodateChildPermission}
                              className="form-control date-picker"
                              onChange={HandleToDateChildPermission}
                            />
                          </div>
                          <span className="error">{toDateErrorChildPermission}</span>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="outerTypeBox mb-3">
                          <button
                            className="btn btn-md btn-primary mt-2"
                            type="submit"
                            onClick={handleValidationChildPermission}
                          >
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>

                      {childPermissionShare?.length == 0 ? (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <ReactDataTable data={childPermissionShare} columns={columns3} className="nk-tb-list" />
                      )}
                      {childPermissionShare?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count2}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange2}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage2 - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPane>

                    <TabPane tabId="5">
                      <div className="row pb-2">
                        {/* <div className="col-lg-4">			
			   <div className="form-control border-0 px-0">
			 <label><strong>Select Student</strong></label> 
			   <select value={childId} className="form-select" onChange={HandleSlelectChild}>
			    <option value="">All</option>
				{
					getchildinfo.map((val)=>{
						return(<>
						<option value={val.id}>{val.firstName}{" "}{val.lastName}</option>
						</>)

					})
				}
			   </select>
			   </div>
         </div> */}

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Resource type</strong>
                            </label>
                            <select
                              value={resourceTypeStatus}
                              className="form-select"
                              onChange={HandleResourceTypeStatus}
                            >
                              <option value="">All</option>
                              <option value="group">Group</option>
                              <option value="individual">Individual</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              className="form-control date-picker"
                              onChange={HandleFromDate}
                            />
                          </div>
                          <span className="error">{formDateError}</span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              className="form-control date-picker"
                              onChange={HandleToDate}
                            />{" "}
                          </div>
                          <span className="error">{toDateError}</span>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="outerTypeBox mb-3">
                          <button className="btn btn-md btn-primary mt-2" type="submit" onClick={handleValidation}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>

                      {purchaseResourceData?.length == 0 ? (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <ReactDataTable
                          data={purchaseResourceData}
                          columns={columns1}
                          className="nk-tb-list"
                          // searchable={true}
                          // pagination
                        />
                      )}

                      {purchaseResourceData?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPane>

                    <TabPane tabId="6">
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdateReview}
                              className="form-control date-picker"
                              onChange={HandleFromDateReview}
                            />
                          </div>
                          <span className="error">{formDateErrorReview} </span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodateReview}
                              className="form-control date-picker"
                              onChange={HandleToDateReview}
                            />{" "}
                          </div>
                          <span className="error">{toDateErrorReview}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button className="btn btn-md btn-primary" type="submit" onClick={handleValidationReview}>
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </div>

                      {review.length == 0 ? (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <ReactDataTable
                          data={review}
                          columns={columns5}
                          className="nk-tb-list"
                          // searchable={true}
                          // pagination
                        />
                      )}
                      {review?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count3}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange3}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <Modal isOpen={isOpen} toggle={openModal}>
                        <ModalHeader
                          toggle={openModal}
                          close={
                            <button className="close" onClick={openModal}>
                              <Icon name="cross" />
                            </button>
                          }
                        >
                          Resource Review
                        </ModalHeader>
                        <ModalBody>
                          <Card className="card-bordered">
                            <div className="card-inner">
                              <BlockHeadContent>
                                <BlockHead size="lg">
                                  <div className="customerDetailsTable">
                                    <div className="row">
                                      {/* <div className="card-inner border-bottom"> */}
                                      <div className="card-title-group">
                                        {ratingId && ratingId.length === 0 && (
                                          <CardTitle>
                                            {" "}
                                            <h6 className="title">No reviews found</h6>{" "}
                                          </CardTitle>
                                        )}
                                      </div>
                                      {/* </div> */}
                                      <ul className="nk-support">
                                        <li className="nk-support-item">
                                          {/* <UserAvatar
                                            theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
                                            text={findUpper(
                                              ratingId?.tbl_user_resource?.resource_title
                                                ? ratingId?.tbl_user_resource?.resource_title
                                                : "--"
                                            )}
                                          /> */}
                                          <div className="nk-support-content">
                                            <div className="title">
                                              <span>
                                                {" "}
                                                {ratingId?.tbl_user_resource?.resource_title
                                                  ? ratingId?.tbl_user_resource?.resource_title
                                                  : "--"}
                                              </span>
                                              <div className="rating">
                                                {star.map((temp) => (
                                                  <>
                                                    {temp == ratingId.rating ? (
                                                      <div className="star">
                                                        <FontAwesomeIcon icon={faStarHalfAlt} />
                                                      </div>
                                                    ) : temp < ratingId.rating ? (
                                                      <div className="star">
                                                        <FontAwesomeIcon icon={faStar} />
                                                      </div>
                                                    ) : (
                                                      <div className="star unfilled">
                                                        <FontAwesomeIcon icon={faStar} />
                                                      </div>
                                                    )}
                                                  </>
                                                ))}
                                              </div>
                                            </div>
                                            <span className="time">
                                              Posted on: {moment(ratingId.created_at).format("MM-DD-YYYY HH:mm")}
                                            </span>
                                            <span className="time">Message: {ratingId.message}</span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </BlockHead>
                              </BlockHeadContent>
                            </div>
                          </Card>
                        </ModalBody>
                        <ModalFooter className="">
                          <Button color="primary" onClick={openModal}>
                            OK
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </TabPane>

                    <TabPane tabId="7">
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Select Shared With</strong>
                            </label>
                            <select value={selectShareWith} className="form-select" onChange={HandleSlelectShareWith}>
                              <option value="">Select shared with</option>
                              <option value="local">Local MatchED Community</option>
                              <option value="friend">Friends</option>
                              <option value="group">Groups</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdateSharedResource}
                              className="form-control date-picker"
                              onChange={HandleFromDateSharedResource}
                            />
                          </div>
                          <span className="error">{formDateErrorSharedResource}</span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodateSharedResource}
                              className="form-control date-picker"
                              onChange={HandleToDateSharedResource}
                            />{" "}
                          </div>
                          <span className="error"> {toDateErrorSharedResource} </span>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="outerTypeBox mb-3">
                          <button
                            className="btn btn-md btn-primary mt-2"
                            type="submit"
                            onClick={handleValidationSharedResource}
                          >
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>

                      {sharedResourceList ? (
                        <ReactDataTable
                          data={sharedResourceList}
                          columns={columns4}
                          className="nk-tb-list"
                          //   searchable={true}
                          //   pagination
                        />
                      ) : (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}

                      {sharedResourceList?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count4}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange4}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <Modal isOpen={isSharedOpen} toggle={shareModal}>
                        <ModalHeader
                          toggle={shareModal}
                          close={
                            <button className="close" onClick={shareModal}>
                              <Icon name="cross" />
                            </button>
                          }
                        >
                          Shared details
                        </ModalHeader>
                        <ModalBody>
                          <Card className="card-bordered">
                            <div className="card-inner">
                              <BlockHeadContent>
                                <BlockHead size="lg">
                                  <div className="customerDetailsTable">
                                    <div className="row">
                                      {/* <div className="card-inner border-bottom"> */}
                                      <div className="card-title-group"></div>
                                      {/* </div> */}
                                      <table>
                                        <tr>
                                          <td width="200">
                                            <h5>(Friends)</h5>
                                          </td>
                                          <td width="200">
                                            <h5>(Groups)</h5>
                                          </td>
                                        </tr>

                                        {friendlistshare.length === 0 && grouplistshare.length === 0 ? (
                                          <>
                                            <tr>
                                              <td width="200">
                                                <h5>-All Friends-</h5>
                                              </td>
                                              <td width="200">
                                                <h5>-All Groups-</h5>
                                              </td>
                                            </tr>
                                          </>
                                        ) : (
                                          <>
                                            <tr>
                                              <td>
                                                {friendlistshare && friendlistshare.length > 0
                                                  ? friendlistshare.map((val, i) => {
                                                      return (
                                                        <>
                                                          {val.firstName} {val.lastName}
                                                          {i < friendlistshare.length - 1 ? "," : ""}
                                                        </>
                                                      );
                                                    })
                                                  : "--"}
                                              </td>
                                              <td>
                                                {grouplistshare && grouplistshare.length > 0
                                                  ? grouplistshare.map((val, i) => {
                                                      return (
                                                        <>
                                                          {val.group_name} {i < grouplistshare.length - 1 ? "," : ""}
                                                        </>
                                                      );
                                                    })
                                                  : "--"}
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                </BlockHead>
                              </BlockHeadContent>
                            </div>
                          </Card>
                        </ModalBody>
                        <ModalFooter className="">
                          <Button color="primary" onClick={shareModal}>
                            OK
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </TabPane>

                    <TabPane tabId="8">
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Select Student</strong>
                            </label>
                            <select value={child} className="form-select" onChange={HandleSlelectChildId}>
                              <option value="">Select Student</option>
                              {childData?.length > 0
                                ? childData?.map((val) => {
                                    return (
                                      <>
                                        <option value={val.tbl_child.id}>
                                          {val.tbl_child.firstName} {val.tbl_child.lastName}
                                        </option>
                                      </>
                                    );
                                  })
                                : ""}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Select Grade</strong>
                            </label>
                            <select value={gradeId} className="form-select" onChange={HandleSlelectGrade}>
                              <option value="">Select Grade</option>
                              {manageGrade.map((val) => {
                                return (
                                  <>
                                    <option value={val.id}>{val.name}</option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Status</strong>
                            </label>
                            <select value={selectedSaveFor} className="form-select" onChange={HandleSlelectSaveFor}>
                              <option value="">Status</option>
                              <option value="past">Past</option>
                              <option value="in progress">In Progress</option>
                              <option value="for the future">Future</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdateSaveResource}
                              className="form-control date-picker"
                              onChange={HandleFromDateSaveResource}
                            />
                          </div>
                          <span className="error">{formDateErrorSaveResource}</span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodateSaveResource}
                              className="form-control date-picker"
                              onChange={HandleToDateSaveResource}
                            />{" "}
                          </div>
                          <span className="error">{toDateErrorSaveResource}</span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button
                              className="btn btn-md btn-primary"
                              type="submit"
                              onClick={handleValidationSaveResource}
                            >
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </div>

                      {savedResourceList ? (
                        <ReactDataTable
                          data={savedResourceList}
                          columns={columns2}
                          className="nk-tb-list"
                          //   searchable={true}
                          //   pagination
                        />
                      ) : (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                      {savedResourceList?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countSave}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeSave}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPane>

                    <TabPane tabId="9">
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdateComment}
                              className="form-control date-picker"
                              onChange={HandleFromDateComment}
                            />
                          </div>
                          <span className="error">{formDateErrorComment}</span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodateComment}
                              className="form-control date-picker"
                              onChange={HandleToDateComment}
                            />{" "}
                          </div>
                          <span className="error">{toDateErrorComment}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button className="btn btn-md btn-primary" type="submit" onClick={handleValidationComment}>
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </div>

                      {customerComment ? (
                        <ReactDataTable
                          data={customerComment}
                          columns={columns6}
                          className="nk-tb-list"
                          //   searchable={true}
                          //   pagination
                        />
                      ) : (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                      {customerComment?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countComment}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeComment}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <Modal isOpen={isOpen1} toggle={openModal1}>
                        <ModalHeader
                          toggle={openModal1}
                          close={
                            <button className="close" onClick={openModal1}>
                              <Icon name="cross" />
                            </button>
                          }
                        >
                          Comment
                        </ModalHeader>
                        <ModalBody>
                          <Card className="card-bordered">
                            <div className="card-inner pt-1">
                              <BlockHeadContent>
                                <BlockHead size="lg">
                                  <div className="customerDetailsTable">
                                    <div className="row">
                                      {comment.comment}
                                      <div className="card-title-group">
                                        {comment && comment.length === 0 && (
                                          <CardTitle>
                                            {" "}
                                            <h6 className="title">No Comment Found</h6>{" "}
                                          </CardTitle>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </BlockHead>
                              </BlockHeadContent>
                            </div>
                          </Card>
                        </ModalBody>
                        <ModalFooter className="">
                          <Button color="primary" onClick={openModal1}>
                            OK
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </TabPane>

                    <TabPane tabId="10">
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              <strong>User Type</strong>
                            </label>
                            <select className="form-control" onChange={handleSearchby} value={userMentorId}>
                              <option value="user">User</option>
                              <option value="mentor">Mentor</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdatementor}
                              className="form-control date-picker"
                              onChange={HandleFromDatementor}
                            />
                          </div>
                          <span className="error">{formDateErrormentor}</span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodatementor}
                              className="form-control date-picker"
                              onChange={HandleToDatementor}
                            />{" "}
                          </div>
                          <span className="error">{toDateErrormentor}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button className="btn btn-md btn-primary" type="submit" onClick={handleValidationmentor}>
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </div>

                      {mentoringSessionData ? (
                        <ReactDataTable
                          data={mentoringSessionData}
                          columns={
                            userMentorId === "user" || userMentorId === "" ? columnsmentoring : columnsmentoringMentor
                          }
                          className="nk-tb-list"
                        />
                      ) : (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                      {mentoringSessionData?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countMentoring}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeMentoring}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPageMentoring - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <Modal isOpen={isMentorOpen} toggle={mentorModal}>
                        <ModalHeader
                          toggle={mentorModal}
                          close={
                            <button className="close" onClick={mentorModal}>
                              <Icon name="cross" />
                            </button>
                          }
                        >
                          Payment Details
                        </ModalHeader>
                        <ModalBody>
                          <Card className="card-bordered">
                            <div className="card-inner">
                              <BlockHeadContent>
                                <BlockHead size="lg">
                                  <div className="customerDetailsTable">
                                    <div className="row">
                                      {/* <div className="card-inner border-bottom"> */}
                                      <div className="card-title-group"></div>
                                      {/* </div> */}
                                      <table>
                                        <ul className="nk-support">
                                          <tr>
                                            <td width="200">Invoice No </td>
                                            <td>
                                              {mentorDetails.invoice_number ? mentorDetails.invoice_number : "--"}{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Payment Reference No </td>
                                            <td>
                                              {mentorDetails.payment_intent ? mentorDetails.payment_intent : "--"}{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Payment Amount </td>
                                            <td>
                                              {mentorDetails?.total_amount
                                                ? new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  }).format(mentorDetails?.total_amount)
                                                : "$0.00"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Tax Amount </td>
                                            <td>
                                              {mentorDetails.tax_amount
                                                ? new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  }).format(mentorDetails.tax_amount)
                                                : "$0.00"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>Transaction Fees </td>
                                            <td>
                                              {mentorDetails.stripe_fee
                                                ? new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  }).format(mentorDetails.stripe_fee)
                                                : "$0.00"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>Admin Fees </td>
                                            <td>
                                              {mentorDetails.admin_fee
                                                ? new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  }).format(mentorDetails.admin_fee)
                                                : "$0.00"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>Invoice </td>
                                            <td>
                                              {mentorDetails?.invoice_pdf_link ? (
                                                <a
                                                  href={`${S3_BUCKET_URL}${mentorDetails?.invoice_pdf_link}`}
                                                  target="_blank"
                                                >
                                                  {" "}
                                                  View{" "}
                                                </a>
                                              ) : (
                                                <span>No Invoice Available</span>
                                              )}
                                            </td>
                                          </tr>
                                        </ul>
                                      </table>
                                    </div>
                                  </div>
                                </BlockHead>
                              </BlockHeadContent>
                            </div>
                          </Card>
                        </ModalBody>
                        <ModalFooter className="">
                          <Button color="primary" onClick={mentorModal}>
                            OK
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </TabPane>

                    <TabPane tabId="11">
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Payment Type</strong>
                            </label>
                            <select
                              value={selectPaymentType}
                              className="form-select"
                              onChange={HandleSlelectPaymenttype}
                            >
                              <option value="">Payment Type</option>
                              <option value="free">Free</option>
                              <option value="monthly">Monthly</option>
                              <option value="yearly">Yearly</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdateSubscription}
                              className="form-control date-picker"
                              onChange={HandleFromDateSubscription}
                            />
                          </div>
                          <span className="error">{formDateErrorSubscription}</span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodateSubscription}
                              className="form-control date-picker"
                              onChange={HandleToDateSubscription}
                            />{" "}
                          </div>
                          <span className="error">{toDateErrorSubscription}</span>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="outerTypeBox mb-3">
                          <button
                            className="btn btn-md btn-primary mt-2"
                            type="submit"
                            onClick={handleValidationSubscription}
                          >
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>

                      {subscriptionPurchase ? (
                        <ReactDataTable
                          data={subscriptionPurchase}
                          columns={columns7}
                          className="nk-tb-list"
                          //   searchable={true}
                          //   pagination
                        />
                      ) : (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}

                      {subscriptionPurchase?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countSubscription}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeSubscription}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPane>

                    <TabPane tabId="12">
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Members Type</strong>
                          </label>
                          <select
                            value={selectFriendMemberType}
                            className="form-select"
                            onChange={HandleFriendMembertype}
                          >
                            <option value="matchedmember">MatchED Members</option>
                            <option value="nonmatchedmember">Non MatchED Members</option>
                          </select>
                        </div>
                      </div>

                      {selectFriendMemberType === "matchedmember" || selectFriendMemberType === "" ? (
                        <div>
                          {
                            <div
                              className="teampurchasepages"
                              style={{ border: "1px solid lightgray", padding: "15px" }}
                            >
                              <div className="teamboxinnercontent">
                                <div className="row">
                                  {friendList?.length > 0 ? (
                                    friendList?.map((val, index) => {
                                      return (
                                        <>
                                          <div className="col-lg-4 col-md-3 col-sm-3 col-12 teamcolumnmob">
                                            <div className="teamboxinnerpart">
                                              <div className="imgcircle">
                                                {val?.photo == "" ? (
                                                  <img src={bnb} alt="images" />
                                                ) : (
                                                  <img className="img-fluid" src={val?.photo} />
                                                )}
                                              </div>
                                              <h2 className="cursor-pointer text-capitalize">{val?.name}</h2>
                                              <div className="accpectbuttoncover">
                                                <>
                                                  <button className="accpectbutton">{val.status.toUpperCase()}</button>
                                                </>
                                                <></>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <div className="sc-fLcnxK dApqnJ">
                                      <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                                        <div className="sc-iveFHk bzRnkJ">
                                          <div className="p-2">No Data Found</div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          }

                          {friendList?.length > 0 ? (
                            <div>
                              <ReactPaginate
                                previousLabel={"<<"}
                                nextLabel={">>"}
                                pageCount={count1}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange1}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                activeClassName={"active"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                forcePage={currentPage1 - 1}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div>
                          {
                            <div
                              className="teampurchasepages"
                              style={{ border: "1px solid lightgray", padding: "15px" }}
                            >
                              <div className="teamboxinnercontent">
                                <div className="row">
                                  {friendListNonMatched?.length > 0 ? (
                                    friendListNonMatched?.map((val, index) => {
                                      return (
                                        <>
                                          <div className="col-lg-4 col-md-3 col-sm-3 col-12 teamcolumnmob">
                                            <div className="teamboxinnerpart">
                                              <div className="imgcircle">
                                                {val?.photo == "" ? (
                                                  <img src={bnb} alt="images" />
                                                ) : (
                                                  <img className="img-fluid" src={val?.photo} />
                                                )}
                                              </div>
                                              <h2 className="cursor-pointer text-capitalize">{val?.name}</h2>
                                              <div className="accpectbuttoncover">
                                                <>
                                                  <button className="accpectbutton">
                                                    {val.status === "requested" ? "PENDING" : val.status.toUpperCase()}
                                                  </button>
                                                </>
                                                <></>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <div className="sc-fLcnxK dApqnJ">
                                      <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                                        <div className="sc-iveFHk bzRnkJ">
                                          <div className="p-2">No Data Found</div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          }

                          {friendListNonMatched?.length > 0 ? (
                            <div>
                              <ReactPaginate
                                previousLabel={"<<"}
                                nextLabel={">>"}
                                pageCount={countNonMatched}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChangeNonMatched}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                activeClassName={"active"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                forcePage={currentPageNonMatched - 1}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </TabPane>

                    <TabPane tabId="13">
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdateLike}
                              className="form-control date-picker"
                              onChange={HandleFromDateLike}
                            />
                          </div>
                          <span className="error">{formDateErrorLike}</span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodateLike}
                              className="form-control date-picker"
                              onChange={HandleToDateLike}
                            />{" "}
                          </div>
                          <span className="error">{toDateErrorLike}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button className="btn btn-md btn-primary" type="submit" onClick={handleValidationLike}>
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </div>

                      {customerLike ? (
                        <ReactDataTable
                          data={customerLike}
                          columns={columnsLike}
                          className="nk-tb-list"
                          //   searchable={true}
                          //   pagination
                        />
                      ) : (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                      {customerLike?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countLike}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeLike}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPane>

                    <TabPane tabId="14">
                      {/* <p>Group List</p> */}
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Joined As</strong>
                            </label>
                            <select value={joinedAsType} className="form-select" onChange={HandleJoinedAs}>
                              <option value="">All</option>
                              <option value="admin">Admin</option>
                              <option value="member">Member</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdateGroup}
                              className="form-control date-picker"
                              onChange={HandleFromDateGroup}
                            />
                          </div>
                          <span className="error">{formDateErrorGroup}</span>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodateGroup}
                              className="form-control date-picker"
                              onChange={HandleToDateGroup}
                            />{" "}
                          </div>
                          <span className="error">{toDateErrorGroup}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button className="btn btn-md btn-primary" type="submit" onClick={handleValidationGroup}>
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </div>

                      {groupList ? (
                        <ReactDataTable data={groupList} columns={columnsGroup} className="nk-tb-list" />
                      ) : (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                      {groupList?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countGroup}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeGroup}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPageGroup - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPane>

                    <TabPane tabId="15">
                      {/* <p className="nk-block-title page-title">Stripe Account Details</p> */}
                      <label>
                        <strong>Stripe Account Details</strong>
                      </label>

                      {stripeList ? (
                        <ReactDataTable
                          data={stripeList}
                          columns={columnsStripe}
                          className="nk-tb-list"
                          //   searchable={true}
                          //   pagination
                        />
                      ) : (
                        <div className="sc-fLcnxK dApqnJ">
                          <div className="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div className="sc-iveFHk bzRnkJ">
                              <div className="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}

                      {stripeList?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countStripe}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeStripe}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </PreviewCard>
          </>
        )}
      </Content>
    </>
  );
}

export default ViewCustomerDetails;
