import Select from 'react-select';
import { useState, useRef } from 'react';
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Card,
} from "reactstrap";
import { useHistory, useNavigate, useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { useEffect } from 'react';
import { makePostRequest, makeGetRequest, makePostRequestForAll, BASE_URL, isProduction, secretKey, imageUrl } from "../../../service/api";
import { URLS } from "../../../constants/urls";
import { getUserDetailsFromToken, isValidFileUploaded, checkUrl, toastifyMessage, formatSize } from '../../../service/auth'
import ChildCard from "./ChildCard";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PdfViewer from "./PdfViewer";
import moment from "moment";
import axios from 'axios';
import CryptoJS from "crypto-js";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DatePicker from "react-datepicker";
import JoditEditor from 'jodit-react';
import ImgCrop from "antd-img-crop";
import { Upload, Button } from "antd";
import { useLocation } from 'react-router-dom';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import Publishpopup from "./Publishpopup";

const filter = createFilterOptions();
const editorConfig = {
  buttons: [
    'bold', // Bold
    'italic', // Italic
    'underline', // Underline
    'strikethrough', // Strikethrough
    'superscript', // Superscript
    'subscript', // Subscript
    'ul', // Unordered List
    'ol', // Ordered List
    'outdent', // Decrease Indentation
    'indent', // Increase Indentation
    'align', // Align (left, center, right, justify)
    '|', // Separator
    'link', // Insert Link
    'table', // Insert Table
    'undo', // Undo
    'redo', // Redo
  ],
};



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 8,
};
function EditResource(props) {

  const history = useHistory();
  let valid = 0;
  const handleBack = () => {
    if (valid === 0 && props?.location?.state?.Offensive_list_status) {
      history.push('/offensive-list');
    } else if (valid === 0) {
      history.push('/resource-list');
    }

  }
  function firstFunction() {
    saveResourceData()
  }


  function callFunctions() {
    //update resource function calling
    firstFunction();

    //back to list function called after delay
    setTimeout(() => {
      handleBack();
    }, 3000);

  }


  const shareResourceOptions = [
    { value: "matched", label: "All MatchED" },
    { value: "local", label: "Local MatchED Community" },
    { value: "friend", label: "Friends" },
    { value: "group", label: "Groups" },
    { value: "none", label: "Private" }
  ];
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [toggleIndex, setToggleIndex] = useState("");
  const [toggleMessage, setToggleMessage] = useState("");
  const [emailId, setEmailId] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [chnageState, setChnageState] = useState("")
  const [message, setMessage] = useState({
    friend: false,
    group: false,
    grade: false,
    share: false
  })
  const [shareFriend, setShareFriends] = useState("")
  const [friendData, setFriendData] = useState("")
  const [friendList, setFriendList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupId, setGroupId] = useState("")
  const [groupData, setGroupData] = useState("")
  const [shareResourceSelectedData, setShareResourceSelectedData] = useState("");
  const [storingType, setStoringType] = useState("Learning Artifacts");
  const [resourceType, setResourceType] = useState()
  const [instructionalStyle, setInstructionalStyle] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [state, setState] = useState({});
  const [saveFor, setSaveFor] = useState("Past");
  const [shareResource, setShareResource] = useState();
  const [keywords, setKeywords] = useState([]);
  const [address, setAddress] = useState("");
  const [parentRating, setParentRating] = useState(0);
  const [childsRating, setChildsRating] = useState([]);
  const [loader, setloader] = useState(false);
  const [uploadContentName, setUploadContentName] = useState("");
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [childRatingChekbox, setChildRatingChekbox] = useState([]);
  const [fundingData, setFundingData] = useState([]);
  const [fundingType, setFundingType] = useState("");
  const [organizationData, setOrganizationData] = useState([]);
  const [organizationType, setOrganizationType] = useState("");
  const [locationType, setLocationType] = useState([]);
  const [sendMsg, setSendMsg] = useState("Hi {customer_name}, I am here to help you for {resource_name}");
  const [affiliateButton, setAffiliateButton] = useState({
    "affiliate_purchase_button": 0,
    "affiliate_signup_button": 0,
    "affiliate_enroll_button": 0,
    "affiliate_add_info_button": 0,
  })

  const [purchaseRes, setPurchaseRes] = useState("");
  const [address1, setAddress1] = useState("");
  const [facilityData, setFacilityData] = useState([]);
  const [facilityType, setFacilityType] = useState("");
  const [experienceData, setExperienceData] = useState([]);
  const [experienceType, setExperienceType] = useState("");
  const [supportTypeData, setSupportTypeData] = useState([]);
  const [supportTypes, setSupportTypes] = useState(null);
  const [customerType, setCustomerType] = useState("");
  const [customerTypeActive1, setCustomerTypeActive1] = useState(false);
  const [customerTypeActive2, setCustomerTypeActive2] = useState(false);
  const [customerTypeActive3, setCustomerTypeActive3] = useState(false);
  const [otherSupport, setOtherSupport] = useState("");
  const [comments, setComments] = useState("");
  const [keywordsData, setKeywordsData] = useState([]);
  const [resourceContent, setResourceContent] = useState(null);
  const [addMore, setAddMore] = useState(false)
  const [thumbnailId, setThumbnailId] = useState([])
  const [accreditedResource, setAccreditedResource] = useState({
    accredited_res: false,
    accrediting_inst: "",
  });
  const [dualEnrolment, setDualEnrolment] = useState({
    dual_enrol: false,
    credit_granting_ins: "",
    no_of_college_worth: "",
  });
  const [contentPreview, setContentPreview] = useState({
    imgurl: "",
    imgtype: "",
  });
  const [isVisible, setVisible] = useState(false);
  const [locationState, setLocationState] = useState({
    country: "",
    state: "",
    city: "",
    zipcode: "",
    latitude: "",
    longitude: "",
  });

  const [locationState1, setLocationState1] = useState({
    country: "",
    state: "",
    city: "",
    zipcode: "",
    latitude: "",
    longitude: "",
  });
  const [contentTypeData, setcontentTypeData] = useState([]);
  const [contentType, setContentType] = useState("");
  const [childApiRes, setChildApiRes] = useState(false);
  const [userDtl, setUserDtl] = useState("");
  const [showModalFileSpaceCheck, setShowModalFileSpaceCheck] = useState(false);
  const [fileSpaceCheckRes, setFileSpaceCheckRes] = useState("");
  const [description, setDescription] = useState("");
  const [aboutResource, setAboutResource] = useState("");
  const [aboutInstructor, setAboutInstructor] = useState("");
  const [requirementsTool, setRequirementsTool] = useState("");
  const [toggleValues, setToggleValues] = useState([]);
  const [inputlist, setInputlist] = useState([{ Link: "", error: "" }]);
  const [fromDetails, setFromDetails] = useState(false);
  const [handleSatate, setHandleState] = useState("");
  const [resourceToggle, setresourcetoggle] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let Emailvalidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  let Zipcode = /^[a-zA-Z0-9]{5,6}$/;
  // let Zipcode = /^\d{5,6}$/
  let keywordFocus;
  let my_token = sessionStorage.getItem("auth-token");
  let userAuth = getUserDetailsFromToken()
  // console.log(userAuth,"getdetailfromtoken")
  let { id } = useParams();
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const autoCompleteRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRefGrade = useRef();
  const inputRefType = useRef();
  const inputRefInStyle = useRef();
  const inputRefExpType = useRef();
  const inputRefLocation = useRef();
  const inputFacilityType = useRef();
  const inputLocationType = useRef();
  const emailIdFocus = useRef();
  const inputRefZipcode = useRef();
  const TimeFocus = useRef();
  const DateRef = useRef(null);
  const customerFocus = useRef(null);
  const datePickerRef = useRef(null);
  const TimeRef = useRef(null);
  const fundingFocus = useRef(null);
  const shareResFocus = useRef();
  const keywordref = useRef();
  const ChildFocus = useRef(null);
  const inputRef1 = useRef();
  const resourceUrl = useRef();
  const inputAffiliate = useRef();
  const priorityFocus = useRef()
  const purchasePrciceFocus = useRef();
  const accreditingInstRef = useRef(null);
  const creditGrantingInsRef = useRef(null);
  const noOfCollegeWorthRef = useRef(null);
  const [childData, setChildData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [instructionalStyleData, setInstructionalStyleData] = useState([]);
  const [learningPillarData, setLearningPillarData] = useState([]);
  const [gradeLevelData, setGradeLevelData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [childIds, setChildIds] = useState([]);
  const [timeFrame, setTimeFrame] = useState("");
  const [timeFrameActive1, setTimeFrameActive1] = useState(false);
  const [timeFrameActive2, setTimeFrameActive2] = useState(false);
  const [timeFrameActive3, setTimeFrameActive3] = useState(false);
  const [timeFrameActive4, setTimeFrameActive4] = useState(false);
  const [learningPillarIds, setLearningPillarIds] = useState([]);
  const [gradeLevelIds, setGradeLevelIds] = useState([]);
  const [approvedStatesIds, setApprovedStatesIds] = useState([]);

  const [uploadContent, setUploadContent] = useState("");
  const [groupResource, setGroupResource] = useState(false)
  //const [viewable, setViewable] = useState(false);


  const [resourceId, setResourceId] = useState("");

  const [thumbnail, setThumbnail] = useState("");
  const [thumbnailImgName, setThumbnailImgName] = useState("");
  const [userId, setUserId] = useState("")
  const [ids, setIds] = useState("")
  const [reviewd, setReviewd] = useState(false);
  const [affiliateValue, setAffiliateValue] = useState(false)

  //api call for Group resource data
  const [groupStatusforPayload, setGroupStatusforPayload] = useState("")
  const [countryName, setCountryName] = useState("")
  const [groupResourceId, setGroupResourceId] = useState("");
  const [groupStatus, setGroupStatus] = useState("");
  const [groupAdminList, setGroupAdminList] = useState([])
  const [showAdmin, setShowAdmin] = useState("");
  const [getadminName, setGetadminName] = useState("");

  // console.log(groupList, "groupList..............")
  // console.log(countryName,groupStatusforPayload,"countryName...,groupStatusforPayload")

  const [contentPreview1, setContentPreview1] = useState({
    imgurl: "",
    imgtype: "",
  });
  const [isVisible1, setVisible1] = useState(false);

  const [priority, setPriority] = useState("")
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [resourceMode, setResourceMode] = useState("")

  // console.log(resourceMode,"resource mode")

  useEffect(() => {
    const param1 = queryParams.get('form') || null;
    if (param1 == "details") {
      setFromDetails(true)
      setResourceData({ ...resourceData })
    }
  }, [location.search])




  //pushed childsids to show selected child
  const handleChildIds = (id, isactive) => {
    if (isactive === true) {
      setChildIds((current) => [...current, id]);
    } else if (isactive === false) {
      let freshArray = childIds.filter((val) => val !== id);
      setChildIds([...freshArray]);
    }
  };

  const handleTimeFrame = (value, index) => {
    if (index === "1") {
      setTimeFrameActive1(!timeFrameActive1);
      setTimeFrameActive2(false);
      setTimeFrameActive3(false);
      setTimeFrameActive4(false);
    } else if (index === "2") {
      setTimeFrameActive1(false);
      setTimeFrameActive2(!timeFrameActive2);
      setTimeFrameActive3(false);
      setTimeFrameActive4(false);
    } else if (index === "3") {
      setTimeFrameActive1(false);
      setTimeFrameActive2(false);
      setTimeFrameActive3(!timeFrameActive3);
      setTimeFrameActive4(false);
    } else if (index === "4") {
      setTimeFrameActive1(false);
      setTimeFrameActive2(false);
      setTimeFrameActive3(false);
      setTimeFrameActive4(!timeFrameActive4);
    }
    setTimeFrame(value);
  };

  const handleCustomerType = (value, index) => {
    if (index === "1") {
      setCustomerTypeActive1(!customerTypeActive1);
      setCustomerTypeActive2(false);
      setCustomerTypeActive3(false);
    } else if (index === "2") {
      setCustomerTypeActive1(false);
      setCustomerTypeActive3(false);
      setCustomerTypeActive2(!customerTypeActive2);
    } else if (index === "3") {
      setCustomerTypeActive1(false);
      setCustomerTypeActive2(false);
      setCustomerTypeActive3(!customerTypeActive3);
    }
    setCustomerType(value);
  };


  //timeframe state will be set if any timeframeactive state  is true otherwise timeframe state value will be empty
  useEffect(() => {
    if (
      timeFrameActive1 ||
      timeFrameActive2 ||
      timeFrameActive3 ||
      timeFrameActive4
    ) {
      setTimeFrame(timeFrame);
    } else {
      setTimeFrame("");
    }
  });

  const [groupListForMatchGroupResourceId, setGroupListForMatchGroupResourceId] = useState([]);
  const [groupCountryName, setGroupCountryName] = useState("")
  // console.log(groupCountryName, "groupCountryNameby state...")
  // console.log(groupResourceId, "groupResourceId....")
  useEffect(() => {
    if (groupListForMatchGroupResourceId && groupListForMatchGroupResourceId.data) {
      let getCountryName = groupListForMatchGroupResourceId.data.find((val) => val.id === groupResourceId);

      if (getCountryName) {
        setGroupCountryName(getCountryName.country_name)
        // console.log(getCountryName.country_name, "getCountryName ......");
      } else {
        console.log("No group found");
      }
    } else {
      console.log("error");
    }
  }, [groupListForMatchGroupResourceId]);




  useEffect(() => {
    if (shareResource?.includes("friend")) {
      let data = {
        user_id: userId,
      };
      makePostRequest(URLS.friendList, null, data, null)
        .then((res) => {
          var arr = [];
          res?.data?.length > 0 && arr.push({ label: "All", value: "all" });
          res?.data.map((val) => {
            return arr.push({ label: val.name, value: val.id });
          });
          setFriendList(arr);
        }).catch((error) => {
          console.log("error", error)
        })
    }
    if (shareResource?.includes("group")) {
      let data = {
        user_id: userId,
      };
      makePostRequest(URLS.getGroupList, null, data, null)
        .then((res) => {
          console.log(res, "grouplis......")
          setGroupListForMatchGroupResourceId(res.data.myGroups)
          var arr = [];
          res?.data?.myGroups?.data?.length > 0 && arr.push({ label: "All", value: "all", type: "" })
          res?.data?.myGroups?.data?.map((val, index) => {
            return arr.push({
              label: val?.group_name + " ( " + val?.member_type + " ) ",
              value: val?.id,
              type: val?.member_type,
            });
          });
          setGroupList(arr);
        })
        .catch((error) => {
          console.log("error:::", error)
        })
    }

  }, [shareResource])


  const handleSupportType = (supportVal) => {
    setSupportTypes(supportVal);
  };

  const handleShareResource = (shareResourceVal) => {
    let shareResArr = [];

    shareResourceVal?.length > 0 &&
      shareResourceVal.forEach((val, i) => {
        if (shareResArr.includes("none")) {
          setMessage({
            ...message,
            share: true
          })
        } else {
          shareResArr.push(val.value);
          setMessage({
            ...message,
            share: false
          })
        }
      });
    if (shareResArr.includes("none")) {
      setShareResourceSelectedData([{ value: "none", label: "Private" }]);
      setShareResource(["none"]);
    }
    else {
      setShareResourceSelectedData(shareResourceVal);
      setShareResource(shareResArr)
      setMessage({
        ...message,
        share: false
      })
    }
  };


  const handleadd = () => {
    const values = [...inputlist];
    values.push({
      Link: ""
    });
    setInputlist(values);
  };
  // this function is use for remove inputfield
  const handleremove = (e, index) => {
    e.preventDefault()
    const values = [...inputlist];
    values.splice(index, 1);
    setInputlist(values);
  };
  const handleInputChange = (index, event) => {
    const values = [...inputlist];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setInputlist(values);
  };

  // const handleFriendsId = (shareResourceVal) => {
  //   let shareArr = [];

  //   shareResourceVal.length > 0 &&
  //     shareResourceVal.map((val, i) => {
  //       shareArr.push(val.value);
  //     });
  //   setShareFriends(shareArr);
  //   setFriendData(shareResourceVal)


  // };


  // const handleGroupId = (shareResourceVal) => {
  //   let shareArr = [];
  //   shareResourceVal.length > 0 &&
  //     shareResourceVal.map((val, i) => {
  //       shareArr.push(val.value);
  //     });
  //   setGroupData(shareResourceVal);
  //   setGroupId(shareArr)
  // };



  const handleGroupId = (shareResourceVal) => {
    let shareArr = [];
    shareResourceVal?.length > 0 &&
      shareResourceVal?.forEach((val, i) => {
        if (shareArr.includes("all")) {
          setMessage({
            ...message,
            group: true
          })
        } else {
          shareArr.push(val.value);
          setMessage({
            ...message,
            group: false
          })
        }
      });
    if (shareArr.includes("all")) {
      setGroupData([{ label: "All", value: "all" }]);
      setGroupId(["all"]);
    }
    else {
      setGroupData(shareResourceVal);
      setGroupId(shareArr)
      setMessage({
        ...message,
        group: false
      })
    }
  };

  const handleFriendsId = (shareResourceVal) => {
    let shareArr = [];
    shareResourceVal?.length > 0 &&
      shareResourceVal.forEach((val, i) => {
        if (shareArr.includes("all")) {
          setMessage({
            ...message,
            friend: true
          })
        } else {
          shareArr.push(val.value);
          setMessage({
            ...message,
            friend: false
          })
        }

      });
    if (shareArr.includes("all")) {
      setShareFriends(["all"]);
      setFriendData([{ label: "All", value: "all" }])
    } else {
      setMessage({
        ...message,
        friend: false
      })
      setShareFriends(shareArr);
      setFriendData(shareResourceVal)
    }
  };


  const handleGrade = (e) => {
    let arr = [];
    e?.length > 0 &&
      e?.map((val, i) => {
        if (arr.includes("all")) {
          setMessage({
            ...message,
            grade: true
          })
        } else {
          arr.push(val.value);
          setMessage({
            ...message,
            grade: false
          })
        }

      });
    if (arr.includes("all")) {
      setGradeLevelIds([{ label: "All", value: "all" }])
    } else {
      setMessage({
        ...message,
        grade: false
      })
      setGradeLevelIds(e)
    }
  }


  //api call to get childs of the user
  const getUserChilds = () => {
    setloader(true);
    let data = {
      user_id: userId,
    };
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
        // console.log(res,"child rate")
        if (res.code === 200) {
          setloader(false);
          if (res?.data && res?.data?.length > 0) {
            setChildApiRes(false);
            let arr = [];
            let childDataArr = [];
            for (let i = 0; i < res?.data?.length; i++) {
              if (res.data[i].childOwner == 1) {
                let childObj = { child_id: "", rating: 0 };
                arr.push(childObj);

                childDataArr.push({
                  id: res.data[i].id,
                  firstName: res.data[i].firstName,
                  lastName: res.data[i].lastName,
                });
              }
            }
            setChildsRating(arr);
            setChildData(childDataArr);
          } else {
            setChildApiRes(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserChilds();
  }, [userId])


  //api call to get resources type
  const getAllResources = () => {
    setloader(true);
    makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          setResourceData(res.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  //api call to get instructional style data
  const getAllInstructionalStyleData = () => {
    makeGetRequest(URLS.GET_ALL_INSTRUCTION_STYLE, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          setInstructionalStyleData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };




  //api call to get learning pillar  data
  const getLearningPillarsData = () => {
    makeGetRequest(URLS.GET_ALL_LEARNING_PILLER, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          let options = [];
          res?.data?.length > 0 &&
            res.data.forEach((val, i) => {
              options.push({ value: val.id, label: val.name });
            });

          setLearningPillarData(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };




  //Admin opions
  const handleAdminOption = (shareResourceVal) => {
    setShowAdmin(shareResourceVal);
    setGetadminName(shareResourceVal.value);
  };


  const HandleGroupAdminList = () => {
    const data = {
      "group_id": groupResourceId,
      "item_per_page": 10,
      "page_number": 1
    }
    makePostRequest(URLS.GET_ADMIN_USER, null, data, null)
      .then((res) => {
        // console.log(res, "Adminlist");
        let options = [];
        res?.data?.data.forEach((val, i) => {
          options.push({
            value: val.user_id,
            label: val.tbl_user.firstName + " " + val.tbl_user.lastName
          });
        });
        setGroupAdminList(options)
      }).catch((error) => {
        console.log("error:::", error)
      })
  };

  useEffect(() => {
    HandleGroupAdminList();
  }, [])

  useEffect(() => {
    if (groupStatus) {
      HandleGroupAdminList();
      setGroupResource(true)
    }
  }, [groupStatus, groupResourceId]);


  //api call to get grade level data
  const getGradeLevelData = () => {
    makeGetRequest(URLS.GET_ALL_GRADE_LEVEL, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          let options = [{ value: "all", label: "All" }];
          res?.data?.length > 0 &&
            res.data.map((val, i) => {
              options.push({ value: val.id, label: val.name });
            });

          setGradeLevelData(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  //api call to get states data
  const getAllStatesData = () => {
    makeGetRequest(URLS.GET_ALL_STATES, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          let options = [];
          res?.data?.length > 0 &&
            res.data.forEach((val, i) => {
              options.push({ value: val.id, label: val.name });
            });

          setStatesData(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  //api call to get funding type data

  const getAllFundingTypes = () => {
    makeGetRequest(URLS.GET_ALL_FUNDING_TYPE, null, null, null)
      .then((res) => {
        // console.log(res,"funding")
        if (res.code === 200) {
          let datafilter = res.data.filter(val => (val.status === "enable"))
          setFundingData(datafilter);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  //api call to get organizational types data
  const getAllOrganizationTypes = () => {
    makeGetRequest(URLS.GET_ALL_ORGANISATION_TYPE, null, null, null)
      .then((res) => {
        // console.log(res,"organisation")
        if (res.code === 200) {

          let dataFilter = res.data.filter(val => (val.status === "enable"))
          setOrganizationData(dataFilter);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //api call to get facilities type data
  const getAllFacilityTypes = () => {
    makeGetRequest(URLS.GET_ALL_FACILITY_TYPE, null, null, null)
      .then((res) => {
        // console.log(res,"facility")
        if (res.code === 200) {
          let filterData = res.data.filter(val => (val.status === "enable"))
          setFacilityData(filterData);


        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //api call to get all content type data
  const getAllContentTypes = () => {
    makeGetRequest(URLS.GET_ALL_CONTENT_TYPE, null, null, null)
      .then((res) => {
        // console.log(res,"content")
        if (res.code === 200) {
          let filterstatus = res.data.filter(val => (val.status === "enable"))
          setcontentTypeData(filterstatus);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //api call to get experiences type data
  const getAllExperienceTypes = () => {
    makeGetRequest(URLS.GET_ALL_EXPERIENCE_TYPE, null, null, null)
      .then((res) => {
        // console.log(res,"experience")
        if (res.code === 200) {
          let datafil = res.data.filter(val => (val.status === "enable"))
          setExperienceData(datafil);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //api call to get support types data

  const getSupportTypesData = () => {
    makeGetRequest(URLS.GET_ALL_SUPPORT_TYPE, null, null, null)
      .then((res) => {
        // console.log(res,"student support")
        if (res.code === 200) {
          let options = [];
          if (resourceType == 4) {
            options.push({ value: "other", label: "Other" });
          }

          res?.data?.length > 0 &&
            res.data.map((val, i) => {
              if (val.status === "enable") {
                options.push({ value: val.id, label: val.name });
              }

            });

          setSupportTypeData(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const options = {
    componentRestrictions: { country: ["US", "IND"] },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };


  const getGoogleAddressStreetTwo = () => {

    // autoCompleteRef1.current = new window.google.maps.places.Autocomplete(
    //   inputRefLocation.current,
    //   options
    // );
    if (window.google && window.google.maps) {
      autoCompleteRef1.current = new window.google.maps.places.Autocomplete(
        inputRefLocation.current,
        options
      );
    }
    autoCompleteRef1.current.addListener("place_changed", async function () {
      const place1 = await autoCompleteRef1.current.getPlace();
      let addressComponent = place1.address_components;
      let fullAddress = "";
      addressComponent?.forEach((component) => {
        fullAddress += component.long_name + ", ";
      });
      fullAddress = fullAddress.slice(0, -2);
      setAddress1(fullAddress);
      let obj = {
        country: "",
        city: "",
        zipcode: "",
        state: "",
        latitude: "",
        longitude: "",
      };
      obj.latitude = place1.geometry.location.lat();
      obj.longitude = place1.geometry.location.lng();

      for (let i = 0; i < addressComponent.length; i++) {
        if (addressComponent[i]?.types[0] === "locality") {
          obj.city = addressComponent[i]?.long_name;
        }

        if (addressComponent[i]?.types[0] === "postal_code") {
          obj.zipcode = addressComponent[i]?.long_name;
        }

        if (addressComponent[i]?.types[0] === "administrative_area_level_1") {
          obj.state = addressComponent[i]?.long_name;
        }
        if (addressComponent[i]?.types[0] === "country") {
          obj.country = addressComponent[i]?.long_name;
        }
      }

      setLocationState1(obj);
    });
  };


  useEffect(() => {
    if (!loader) {
      const found =
        Array.isArray(shareResourceSelectedData) &&
        shareResourceSelectedData.some((el) => el.value == "local");
      //console.log("found::", found);
      if (found) {
        // getGoogleAddressStreetOne();
      }
      if (
        parseInt(resourceType) === 1 ||
        parseInt(resourceType) === 7 ||
        parseInt(resourceType) === 3 ||
        parseInt(resourceType) === 4 ||
        parseInt(resourceType) === 5
      ) {
        getGoogleAddressStreetTwo();
      }
    }
  }, [!loader, resourceType, shareResourceSelectedData]);


  //function called to check validations and call create or update function to save resources based on condition
  const saveResourceData = (ps) => {
    // if (childData.length > 0 && childIds.length === 0  ) {
    //   if (childData.length > 1) {
    //     // errors.childids = "Please select at least one child.";
    //     ChildFocus.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });

    //   }
    // }

    if (resourceType === "") {
      inputRef2.current.focus();
    } else if (!state.resource_title) {
      inputRef3.current.focus();
    } else if (gradeLevelIds.length === 0) {
      inputRefGrade?.current.focus();
    } else if (shareResourceSelectedData == null || shareResourceSelectedData.length == 0) {
      shareResFocus?.current?.focus();
    } else if (keywords.length === 0) {
      keywordFocus?.focus();
    }
    else if (affiliateButton.affiliate_purchase_button === 1 && purchaseRes == "") {
      purchase_focus?.current?.focus();
    } else if (purchaseRes == "no" && !state.resourceurl) {
      resourceUrl?.current?.focus();
    }
    else if ((state.purchase_price === undefined || state.purchase_price === "") && affiliateButton?.affiliate_purchase_button === 1 && purchaseRes === "yes") {
      purchasePrciceFocus.current.focus();
    }
    // else if (affiliateButton.affiliate_purchase_button == 0 && affiliateButton.affiliate_signup_button == 0
    //   && affiliateButton.affiliate_enroll_button == 0 && affiliateButton.affiliate_add_info_button == 0) {
    //   inputAffiliate?.current?.focus();
    // }
    else if (affiliateButton.affiliate_enroll_button === 1 && emailId === "") {
      emailIdFocus?.current?.focus();
    } else if (affiliateButton.affiliate_enroll_button === 1 && !Emailvalidation.test(emailId)) {
      emailIdFocus?.current?.focus();
    } else if (addMore && state.public_funding && state.public_funding === "1") {
      if (approvedStatesIds.length === 0) {
        fundingFocus?.current?.focus();
      }
    }
    let errors = {};
    // console.log(errors,"error")
    let currentDate = new Date();
    currentDate = currentDate.setHours(0, 0, 0, 0);
    if (priority == "") {

      errors.priority = "Please select priority type.";
      priorityFocus?.current?.focus()
      valid++;
    }
    // if (state.purchase_price) {
    //   if (isNaN(state.purchase_price)) {
    //     errors.purchase_price = "Please enter number.";
    //     valid++;
    //   } else if (state.purchase_price < 0) {
    //     errors.purchase_price = "Negative number not allowed.";
    //     valid++;
    //   }
    // }

    // if ((state.purchase_price ===  undefined || state.purchase_price === "" || state.purchase_price) && affiliateButton?.affiliate_purchase_button === 1 && purchaseRes === "yes") {
    //   if (state.purchase_price ===  undefined || state.purchase_price === ""){
    //     errors.purchase_price = "Please add purchase price.";
    //     valid++;
    //   }else if (isNaN(state.purchase_price)) {
    //     errors.purchase_price = "Please enter number.";
    //     purchasePrciceFocus.current.focus();
    //     valid++;
    //   } else if (state.purchase_price < 0) {
    //     errors.purchase_price = "Negative number is not allowed.";
    //     purchasePrciceFocus.current.focus();
    //     valid++;
    //   }else if ( state.purchase_price == 0){
    //     errors.purchase_price = "Purchase price can't be 0.";
    //     purchasePrciceFocus.current.focus();
    //     valid++;
    //   }
    // }
    if ((state.purchase_price === undefined || state.purchase_price === "" || state.purchase_price) && affiliateButton?.affiliate_purchase_button === 1 && purchaseRes === "yes") {
      if (state.purchase_price === undefined || state.purchase_price === "") {
        errors.purchase_price = "Please add purchase price.";
        purchasePrciceFocus.current.focus();
        valid++;
      } else if (isNaN(state.purchase_price)) {
        errors.purchase_price = "Please enter a valid number.";
        purchasePrciceFocus.current.focus();
        valid++;
      } else if (state.purchase_price < 0) {
        errors.purchase_price = "Negative number is not allowed.";
        purchasePrciceFocus.current.focus();
        valid++;
      } else if (state.purchase_price == 0) {
        errors.purchase_price = "Purchase price can't be 0.";
        purchasePrciceFocus.current.focus();
        valid++;
      } else {
        errors.purchase_price = "";
      }
    }


    if (state.resourceurl) {
      let isValid = checkUrl(state.resourceurl.startsWith("http") ? state.resourceurl : state.resourceurl.startsWith("https") == false ? "https://" + state.resourceurl : state.resourceurl);
      if (isValid && state.resourceurl.startsWith("https") == false) {
        state.resourceurl = "https://" + state.resourceurl;
      }
      if (!isValid) {
        errors.resourceurl = "Url is not valid.";
        resourceUrl?.current?.focus();
        valid++;
      }
    }
    if (parseInt(resourceType) === 2) {
      if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
          datePickerRef?.current.setOpen(true);
          errors.endDate = "End date should be greater than start date.";
          valid++;
        }

        if (startTime && endTime) {
          let date1 = new Date(startDate);
          let date2 = new Date(endDate);
          // console.log(date1.getDate() == date2.getDate() &&
          //   date1.getMonth() == date2.getMonth() &&
          //   date1.getFullYear() == date2.getFullYear())
          if (
            date1.getDate() == date2.getDate() &&
            date1.getMonth() == date2.getMonth() &&
            date1.getFullYear() == date2.getFullYear()
          ) {
            let str1 = startTime.split(":");
            let str2 = endTime.split(":");
            str1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[0]);
            str2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[0]);
            // console.log(str1, str2)
            if (str1 > str2) {
              TimeFocus.current.focus();
              errors.endTime = "Please do not select past time.";
              valid++;
            }
          }
        }
      }

      if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
          datePickerRef.current.setOpen(true);
          DateRef.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      }
      //  else if (!instructionalStyle) {
      //   errors.instructionalStyle = "Instructional style is required.";
      //   inputRefInStyle.current.focus();
      //   valid++;
      // }
      // if (!timeFrame) {
      //   errors.timeFrame = "Timeframe  is required.";
      //   TimeRef.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      //   valid++;
      // }

      // if (accreditedResource.accredited_res) {
      //   if (!accreditedResource.accrediting_inst) {
      //     errors.accrediting_inst = "Accrediting institution  is required.";
      //     valid++;
      //   }
      // }
      // if (dualEnrolment.dual_enrol) {
      //   if (!dualEnrolment.credit_granting_ins && dualEnrolment.no_of_college_worth === "" && dualEnrolment.no_of_college_worth !== null) {
      //     errors.credit_granting_ins =
      //       "Credit granting institution is required.";
      //     valid++;
      //   }
      //   if (dualEnrolment.no_of_college_worth < 0) {
      //     errors.no_of_college_worth = "Negative number not allowed.";
      //     valid++;
      //   }
      //   else if (dualEnrolment.no_of_college_worth === "" && dualEnrolment.no_of_college_worth !== null) {
      //     // console.log(dualEnrolment.no_of_college_worth)
      //     errors.no_of_college_worth = "No. of college worth is required.";
      //     valid++;
      //   }
      //   else if (isNaN(dualEnrolment.no_of_college_worth)) {
      //     errors.no_of_college_worth = "Please enter number.";
      //     valid++;
      //   }

      // }


      let focusSet = false; // Flag to check if focus has been set

      if (accreditedResource.accredited_res) {

        if (!accreditedResource.accrediting_inst) {
          errors.accrediting_inst = "Accrediting institution is required.";
          valid++;
          if (!focusSet) {
            accreditingInstRef.current.focus();
            focusSet = true;
          }
        }
      }


      if (dualEnrolment.dual_enrol) {
        if (!dualEnrolment.credit_granting_ins) {
          errors.credit_granting_ins = "Credit granting institution is required.";
          valid++;

          if (!focusSet) {
            creditGrantingInsRef.current.focus();
            focusSet = true;
          }
        }

        if (dualEnrolment.no_of_college_worth === "" || dualEnrolment.no_of_college_worth === null) {
          errors.no_of_college_worth = "No. of college worth is required.";
          valid++;
          if (!focusSet) {
            noOfCollegeWorthRef.current.focus();
            focusSet = true;
          }
        } else if (isNaN(dualEnrolment.no_of_college_worth)) {
          errors.no_of_college_worth = "Please enter a valid number.";
          valid++;
          if (!focusSet) {
            noOfCollegeWorthRef.current.focus();
            focusSet = true;
          }
        } else if (dualEnrolment.no_of_college_worth < 0) {
          errors.no_of_college_worth = "Negative number not allowed.";
          valid++;
          if (!focusSet) {
            noOfCollegeWorthRef.current.focus();
            focusSet = true;
          }
        }
      }

      if (!contentType) {
        errors.contentType = "Please select content type.";
        inputRefType.current.focus();
        valid++;
      }
    }
    if (parseInt(resourceType) === 6) {
      if (!fundingType) {
        inputRefType.current.focus();
        errors.fundingType = "Please select funding type.";
        valid++;
      }
    }
    if (parseInt(resourceType) === 1) {
      if (!organizationType) {
        errors.organizationType = "Please select organization type.";
        inputRefType.current.focus();
        valid++;
      }
      if (locationType.length <= 0) {
        errors.locationType = "Please select location.";
        inputLocationType.current.focus();
        valid++;
      }
      else if (locationType.includes("In Person") && locationState1.zipcode == "") {
        errors.locationState1 = "Please enter the Zip Code.";
        inputRefZipcode.current.focus();
        valid++;
      }
      if (locationState1.zipcode) {
        if (!Zipcode.test(locationState1.zipcode.replace(/\s/g, ""))) {
          errors.locationState1 = "Invalid Zip Code.";
          inputRefZipcode.current.focus();
          valid++;
        }
      }
      if (!organizationType) {
        inputRefType.current.focus();
      }

    }
    if (parseInt(resourceType) === 7) {
      if (!facilityType) {
        errors.facilityType = "Please select facility type.";
        valid++;
      }
      if (locationType.length <= 0) {
        errors.locationType = "Please select location.";
        inputLocationType.current.focus();
        valid++;
      }
      else if (locationType.includes("In Person") && locationState1.zipcode === "") {
        errors.locationState1 = "Please enter the Zip Code.";
        inputRefZipcode.current.focus();
        valid++;
      }
      if (locationState1.zipcode) {
        if (!Zipcode.test(locationState1.zipcode.replace(/\s/g, ""))) {
          errors.locationState1 = "Invalid Zip Code.";
          inputRefZipcode.current.focus();
          valid++;
        }
      }
      if (!facilityType) {
        inputFacilityType.current.focus();
      }
    }
    if (parseInt(resourceType) === 3) {
      if (!experienceType) {
        errors.experienceType = "Please select experience type.";
        valid++;
      }

      if (!locationState1.zipcode) {
        errors.locationState1 = "Please enter the Zip Code.";
        inputRefZipcode.current?.focus();
        valid++;
      }
      if (locationState1.zipcode) {
        if (!Zipcode.test(locationState1?.zipcode?.replace(/\s/g, ""))) {
          errors.locationState1 = "Invalid Zip Code.";
          inputRefZipcode.current?.focus();
          valid++;
        }
      }

      else if (locationState1.zipcode === "") {
        inputRefZipcode.current?.focus();
      }
      if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
          datePickerRef.current.setOpen(true);
          DateRef.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
          errors.endDate = "End date should be greater than start date.";
          valid++;
        }
      }
      if (!experienceType) {
        inputRefExpType.current.focus();
      } else if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
          datePickerRef.current.setOpen(true);
          DateRef.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      }
    }
    if (parseInt(resourceType) === 5) {
      if (!supportTypes) {
        errors.supportTypes = "Please select at least one support type.";
        valid++;
      }
      if (!customerType) {
        errors.customerType = "Please select customerType.";
        valid++;
      }
      if (locationType.length <= 0) {
        errors.locationType = "Please select location.";

        valid++;
      }
      else if (locationType.includes("In Person") && locationState1.zipcode == "") {
        errors.locationState1 = "Please enter the Zip Code.";
        inputRefZipcode.current.focus();
        valid++;
      }
      if (locationState1.zipcode) {
        if (!Zipcode.test(locationState1.zipcode.replace(/\s/g, ""))) {
          errors.locationState1 = "Invalid Zip Code.";
          inputRefZipcode.current.focus();
          valid++;
        }
      }
      if (!customerType) {
        customerFocus.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }
      else if (!supportTypes) {
        inputRefType.current.focus();
      }
      else if (locationType.length <= 0) {
        inputLocationType.current.focus();
      }
    }
    if (parseInt(resourceType) === 4) {
      if (!supportTypes) {
        errors.supportTypes = "Please select at least one support type";
        valid++;
      }
      if (locationType.length <= 0) {
        errors.locationType = "Please select location";
        inputLocationType.current.focus();
        valid++;
      }
      else if (locationType.includes("In Person") && locationState1.zipcode == "") {
        errors.locationState1 = "Please enter the Zip Code";
        inputRefZipcode.current.focus();
        valid++;
      }
      if (locationState1.zipcode) {
        if (!Zipcode.test(locationState1.zipcode.replace(/\s/g, ""))) {
          errors.locationState1 = "Invalid Zip Code";
          inputRefZipcode.current.focus();
          valid++;
        }
      }
      if (
        supportTypes &&
        supportTypes.length > 0 &&
        supportTypes.some((el) => el.value === "other") == true &&
        !otherSupport
      ) {
        errors.otherSupport = "Please give your support type";
        inputRefType.current.focus();
        valid++;
      }
      if (!supportTypes) {
        inputRefType.current.focus();
      }
    }
    // if (childData.length > 0 && childIds.length === 0  && resourceMode !== "serve") {
    //   if (childData.length > 1) {
    //     window.scrollTo(0, 0);
    //     errors.childids = "Please select at least one child.";
    //   } else {
    //     window.scrollTo(0, 0);
    //     errors.childids = "Please select child.";
    //   }
    //   valid++;
    // }
    if (!resourceType) {
      errors.resourceType = "Resource type is required.";
      valid++;
    }
    if (!state.resource_title) {
      errors.resource_title = "Resource title  is required.";
      valid++;
    }
    if (addMore && state.public_funding && state.public_funding === "1") {
      if (approvedStatesIds.length === 0) {
        errors.approvedStatesIds =
          "Please select public funding approved states.";
        //  fundingFocus.current.focus();
        valid++;
      }
    }
    if (gradeLevelIds.length === 0) {
      errors.gradeLevelIds = "Please select at least one grade range.";
      valid++;
    }
    if (shareResourceSelectedData == null || shareResourceSelectedData.length == 0) {
      errors.shareResourceError = "Please select who you would like to share this with.";
      valid++;
    }
    if (keywords.length === 0) {
      errors.keywords = "Please select at least one keyword or add new keyword.";
      valid++;
    }
    if (affiliateButton.affiliate_purchase_button === 1 && purchaseRes == "") {
      errors.Purchase_res = "Please select above field";
      valid++;
    }

    if (purchaseRes == "no" && !state.resourceurl && affiliateButton?.affiliate_purchase_button === 1) {
      errors.res_url = "Please enter url";
      valid++;
    }
    if (affiliateButton.affiliate_enroll_button === 1 && (emailId === "" || emailId === null)) {
      errors.emailId = "Please enter email id";
      valid++;
    } else if (affiliateButton.affiliate_enroll_button === 1 && !Emailvalidation.test(emailId)) {
      setEmailErr("Please enter a valid email id.");
      valid++
    } else {
      setEmailErr("");
    }
    // if (affiliateButton.affiliate_purchase_button === 0 && affiliateButton.affiliate_signup_button === 0
    //   && affiliateButton.affiliate_enroll_button === 0 && affiliateButton.affiliate_add_info_button === 0) {
    //   errors.choose_anyone = "Please select at least one option.";
    //   valid++;
    // }
    if (shareResource.includes("local")) {
      if (!locationState.zipcode) {
        inputRef.current.focus()
        errors.address = "Zip code is required.";
        valid++;
      }
      if (locationState.zipcode && locationState.zipcode !== undefined) {
        if (!Zipcode.test(locationState.zipcode.replace(/\s/g, ""))) {
          errors.address = "Invalid Zip Code.";
          inputRef.current.focus()
          valid++;
        }
      }
      if (locationState.zipcode == "") {
        inputRef.current.focus()

      } else if (locationState.zipcode && !Zipcode.test(locationState.zipcode.replace(/\s/g, ""))) {
        inputRef.current.focus()
      }
    }
    if (inputlist.length > 0) {
      const arr = inputlist.map((item, index) => {
        if (item.Link) {
          const isValid = checkUrl(
            item.Link.startsWith("http")
              ? item.Link
              : item.Link.startsWith("https") == false
                ? "https://" + item.Link
                : item.Link
          );
          if (item.Link && !isValid) {
            valid++;
          }
          return {
            Link: item.Link,
            error: !isValid
          };

        } else {
          return {
            Link: item.Link,
            error: false
          };
        }
      });
      setInputlist(arr)
    }
    setValidationErrors({ ...validationErrors, errors: errors });
    if (valid === 0) {

      updateResource(ps);
    }
  };

  let { errors } = validationErrors;
  const updateResource = async (ps) => {

    setloader(true);
    let data = {};
    data.id = id;
    data.user_id = userId;
    data.affiliate_resource = affiliateValue === true ? "1" : "0";
    data.resource_mode = resourceMode ? resourceMode : "save";

    if (resourceMode === "save") {
      data.storing_type = storingType;
      data.save_for = saveFor ? saveFor : "Past";
    }

    data.resource_type = resourceType;
    data.resource_type_status = groupStatusforPayload;

    data.chat_user_id = getadminName ? getadminName : "";
    // data.chat_user_id = showAdmin.value ? showAdmin.value :"" ;
    data.purchase_from_me = purchaseRes ? purchaseRes : "";
    data.group_announcement = state.group_announcement
      ? state.group_announcement
      : "no";
    if (childIds && childIds.length > 0) {
      //childIds.forEach((childid) => formData.append("childIds[]", childid));
      data.childIds = childIds;
    }
    if (parseInt(resourceType) === 2) {
      data.timeframe = (timeFrame && [timeFrame]) || [];
      if (startDate) {
        data.start_date = moment(startDate).format("YYYY-MM-DD");
      }
      else {
        data.start_date = null
      }
      if (endDate) {
        data.end_date = moment(endDate).format("YYYY-MM-DD");
      } else {
        data.end_date = null;
      }

      data.start_time = startTime;
      data.end_time = endTime;
      data.instructional_style = (instructionalStyle && [instructionalStyle]) || [];
      // data.instructional_style = (instructionalStyle && instructionalStyle.length > 0) ? [instructionalStyle] : [0];

      data.isbn = state.isbn_number ? state.isbn_number : "";
      data.accredited_resource = accreditedResource.accredited_res
        ? "yes"
        : "no";
      data.accrediting_institution = accreditedResource.accredited_res
        ? accreditedResource.accrediting_inst
        : "";
      data.dual_enrollment_resource = dualEnrolment.dual_enrol
        ? "yes"
        : "no";
      data.credit_granting_institution = dualEnrolment.dual_enrol
        ? dualEnrolment.credit_granting_ins
        : "";
      data.college_credits_number = dualEnrolment.dual_enrol
        ? parseInt(dualEnrolment.no_of_college_worth)
        : 0;

      let contentTypeArr = [];
      contentTypeArr.push(contentType);
      if (contentTypeArr && contentTypeArr?.length > 0) {
        data.resource_contents = contentTypeArr;
      }

    } else if (parseInt(resourceType) === 6) {
      let fundingArr = [];
      fundingArr.push(fundingType);
      if (fundingArr && fundingArr?.length > 0) {
        data.funding_id = fundingArr;
      }
    } else if (parseInt(resourceType) === 1) {
      let organizationTypeArr = [];
      organizationTypeArr.push(organizationType);
      if (organizationTypeArr && organizationTypeArr?.length > 0) {
        data.organisation_id = organizationTypeArr;
      }

      data.location_type = (locationType && locationType) || [];
      data.define_address = address1;
      if (locationState1.latitude) {
        data.define_latitude = locationState1.latitude;
      }
      if (locationState1.longitude) {
        data.define_longitude = locationState1.longitude;
      }

      data.define_zipcode = locationState1.zipcode;
      data.define_city = locationState1.city;
      data.define_country = locationState1.country;
      data.define_state = locationState1.state;
    } else if (parseInt(resourceType) === 7) {
      let facilityTypeArr = [];
      facilityTypeArr.push(facilityType);
      if (facilityTypeArr && facilityTypeArr?.length > 0) {
        data.facility_id = facilityTypeArr;
      }

      data.location_type = (locationType && locationType) || [];
      data.define_address = address1;
      if (locationState1.latitude) {
        data.define_latitude = locationState1.latitude;
      }
      if (locationState1.longitude) {
        data.define_longitude = locationState1.longitude;
      }

      data.define_zipcode = locationState1.zipcode;
      data.define_city = locationState1.city;
      data.define_country = locationState1.country;
      data.define_state = locationState1.state;
    } else if (parseInt(resourceType) === 3) {
      let experienceTypeArr = [];
      experienceTypeArr.push(experienceType);
      if (experienceTypeArr && experienceTypeArr?.length > 0) {
        data.experience_id = experienceTypeArr;
      }

      data.define_address = address1;
      if (locationState1.latitude) {
        data.define_latitude = locationState1.latitude;
      }
      if (locationState1.longitude) {
        data.define_longitude = locationState1.longitude;
      }

      data.define_zipcode = locationState1.zipcode;
      data.define_city = locationState1.city;
      data.define_country = locationState1.country;
      data.define_state = locationState1.state;
      data.timeframe = (timeFrame && [timeFrame]) || [];
      if (startDate) {
        data.start_date = moment(startDate).format("YYYY-MM-DD");
      }
      else {
        data.start_date = null
      }
      if (endDate) {
        data.end_date = moment(endDate).format("YYYY-MM-DD");
      }
      else {
        data.end_date = null
      }
    } else if (parseInt(resourceType) === 5) {
      if (supportTypes && supportTypes?.length > 0) {
        let supportTypesArr = [];
        supportTypes.forEach((support) =>
          supportTypesArr.push(support.value)
        );
        data.support_id = supportTypesArr;
      }

      data.define_address = address1;
      if (locationState1.latitude) {
        data.define_latitude = locationState1.latitude;
      }
      if (locationState1.longitude) {
        data.define_longitude = locationState1.longitude;
      }

      data.define_zipcode = locationState1.zipcode;
      data.define_city = locationState1.city;
      data.define_country = locationState1.country;
      data.define_state = locationState1.state;
      data.customer_type = (customerType && [customerType]) || [];
      data.location_type = (locationType && locationType) || [];
    } else if (parseInt(resourceType) === 4) {
      if (supportTypes && supportTypes?.length > 0) {
        let supportTypesArr = [];
        supportTypes.forEach((support) => {
          if (support.value !== "other") {
            supportTypesArr.push(support.value);
          }
        });
        data.support_id = supportTypesArr;
      }
      data.define_address = address1;
      if (locationState1.latitude) {
        data.define_latitude = locationState1.latitude;
      }
      if (locationState1.longitude) {
        data.define_longitude = locationState1.longitude;
      }

      data.define_zipcode = locationState1.zipcode;
      data.define_city = locationState1.city;
      data.define_country = locationState1.country;
      data.define_state = locationState1.state;
      if (
        supportTypes &&
        supportTypes?.length > 0 &&
        supportTypes.some((el) => el.value === "other") == true
      ) {
        data.other_support = otherSupport;
      }
      data.location_type = locationType && locationType || [];
    }

    data.resource_title = state.resource_title ? state.resource_title : "";
    data.description = description ? description : "";
    data.about_resource = aboutResource ? aboutResource : "";
    data.about_instructor = aboutInstructor ? aboutInstructor : "";
    data.resource_requirement = requirementsTool ? requirementsTool : "";
    data.location = shareResource.includes("local") ? address : null;
    if (locationState.latitude) {
      data.latitude = shareResource.includes("local") ? locationState.latitude : null;
    }
    if (locationState.longitude) {
      data.longitude = shareResource.includes("local") ? locationState.longitude : null;
    }
    data.zipcode = shareResource.includes("local") ? locationState.zipcode : null;
    data.city = shareResource.includes("local") ? locationState.city : null;
    data.country = shareResource.includes("local") ? locationState.country : null;
    data.state = shareResource.includes("local") ? locationState.state : null;
    data.resource_url = state.resourceurl ? state.resourceurl : "";
    data.purchase_price = state.purchase_price ? state.purchase_price : 0.0;

    if (keywords && keywords?.length > 0) {
      let keywordsArr = [];
      keywords?.forEach((val) => keywordsArr.push(val?.keyword || val));
      data.keywords = keywordsArr;
    }
    if (learningPillarIds && learningPillarIds?.length > 0) {
      let learning_pillars_arr = [];
      learningPillarIds.forEach((learningpillarid) =>
        learning_pillars_arr.push(learningpillarid.value)
      );
      data.learning_pillars = learning_pillars_arr;
    }
    if (gradeLevelIds && gradeLevelIds?.length > 0) {
      let gradeLevelIdArr = [];
      gradeLevelIds.forEach((gradelevelid) =>
        gradeLevelIdArr.push(gradelevelid.value)
      );
      data.grades = gradeLevelIdArr;
    }
    if (approvedStatesIds && approvedStatesIds?.length > 0) {
      let approvedStatesIdsArr = [];
      approvedStatesIds.forEach((approvedStatesId) =>
        approvedStatesIdsArr.push(approvedStatesId.value)
      );
      data.funding_states = approvedStatesIdsArr;
    }

    data.public_funding =
      state.public_funding === "1"
        ? "yes"
        : state.public_funding === "2"
          ? "no"
          : null;
    if (
      shareResourceSelectedData &&
      shareResourceSelectedData.some((el) => el.value === "local") == true
    ) {
      data.local_public = "yes";
    } else {
      data.local_public = "no";
    }

    if (
      shareResourceSelectedData &&
      shareResourceSelectedData.some((el) => el.value === "friend") == true
    ) {
      data.friends = "yes";
    } else {
      data.friends = "no";
    }

    if (
      shareResourceSelectedData &&
      shareResourceSelectedData.some((el) => el.value === "group") == true
    ) {
      data.groups = "yes";
    } else {
      data.groups = "no";
    }
    let parentReviews = [
      {
        message: comments,
        rating: parentRating,
      },
    ];
    data.parent_review = parentReviews;
    if (childsRating && childsRating?.length > 0) {
      let childsRatingArr = [];
      childsRating.forEach((val, i) => {
        if (val.child_id) {
          childsRatingArr.push({
            child_id: val.child_id,
            rating: val.rating,
          });
        }
      });
      data.child_review = childsRatingArr;
    }
    data.shared_with = shareResource ? shareResource : [];
    data.shared_id = shareFriend ? shareFriend : [];
    data.group_id = groupId ? groupId : [];
    data.group_announcement_list = toggleValues ? toggleValues.filter(value => value !== null && value !== undefined) : []
    data.group_with_share_list = resourceToggle ? resourceToggle.filter(value => value !== null && value !== undefined) : []
    data.resource_links = inputlist?.map(obj => obj.Link).filter(link => link && link.trim() !== "") || []
    data.affiliate_purchase_button = affiliateButton?.affiliate_purchase_button
    data.affiliate_signup_button = affiliateButton?.affiliate_signup_button
    data.affiliate_enroll_button = affiliateButton?.affiliate_enroll_button
    data.affiliate_add_info_button = affiliateButton.affiliate_add_info_button
    // data.chat_user_id = userData.userAuth.id;
    data.message_send = sendMsg ? sendMsg : null;
    data.enrollment_email = emailId;
    data.priority_of_resource = priority ? priority : "P3";
    data.is_review = reviewd ? "yes" : "no";
    // console.log("data:::", data)
    makePostRequestForAll(URLS.updateResources, data).then((res) => {
      // console.log(res, "response");
      if (res.code === 200) {
        setloader(false);
        toastifyMessage(res.message, "success");
        //Upload Content File

        if (thumbnailId.length > 0) {
          uploadFileContentData(id, ps);
        } else {
          setTimeout(() => {
            if (ps) {

              // navigate(`/create-schedule/?resource=${res?.data?.id}`);
            } else {
              // navigate("/save-resources-list");
            }
          }, 1000);
        }
      } else if (res.code === 400) {
        setloader(false);
        if (res.message) {
          toastifyMessage(res.message, "error");
        } else {
          toastifyMessage("Something went wrong", "error");
        }
      } else {
        setloader(false);
      }
    });
    // }
    // }

  };

  useEffect(() => {

    getUserChilds();
    getAllResources();
    getAllInstructionalStyleData();
    getLearningPillarsData();
    getGradeLevelData();
    getAllStatesData();
    getAllFundingTypes();
    getAllOrganizationTypes();
    getAllFacilityTypes();
    getAllExperienceTypes();
    getAllUniqueKeywordsData();
    getAllContentTypes();


  }, []);


  useEffect(() => {
    if (parseInt(resourceType) === 4 || parseInt(resourceType) === 5) {
      getSupportTypesData();
    }
  }, [resourceType]);

  const [zipCodeChangeData1, setZipCodeChangeData1] = useState("")
  const [zipCodeChangeData2, setZipCodeChangeData2] = useState("")
  // console.log(zipCodeChangeData1, "zipCodeChangeData1")
  // console.log(zipCodeChangeData2, "zipCodeChangeData2")
  const handleInput = (e) => {

    let name = e.target.name;
    let value = e.target.value;
    // console.log(name, value, "name,value.......")

    let obj = {
      country: "",
      city: "",
      zipcode: "",
      state: "",
      latitude: "",
      longitude: "",
    };
    if (name === "resourceType") {
      const type = e.target.options[e.target.selectedIndex].text
      setResourceType(value);
      if (value == 2) {
        setTimeFrame("");
        setStartDate("");
        setEndDate("");
        setTimeFrameActive1(false);
        setTimeFrameActive2(false);
        setTimeFrameActive3(false);
        setTimeFrameActive4(false);
      } else if (value == 3) {
        setTimeFrame("");
        setStartDate("");
        setEndDate("");
        setTimeFrameActive1(false);
        setTimeFrameActive2(false);
        setTimeFrameActive3(false);
        setTimeFrameActive4(false);
        setAddress1("");
        setLocationState1(obj);
      } else if (value == 7) {
        setAddress1("");
        setLocationType([]);
        setLocationState1(obj);
      } else if (value == 1) {
        setAddress1("");
        setLocationType([]);
        setLocationState1(obj);
      } else if (value == 4) {
        setAddress1("");
        setLocationType([]);
        setSupportTypes(null);
        setOtherSupport("");
        setLocationState1(obj);
      } else if (value == 5) {
        setAddress1("");
        setLocationType([]);
        setSupportTypes(null);
        setLocationState1(obj);
      } else {
      }
    } else if (name === "fundingType") {
      setFundingType(value);
    } else if (name === "organizationType") {
      setOrganizationType(value);
    } else if (name === "facilityType") {
      setFacilityType(value);
    } else if (name === "experienceType") {
      setExperienceType(value);
    } else if (name === "contentType") {
      setContentType(value);
    } else if (name === "group_announcement") {
      if (e.target.checked) {
        setState({ ...state, group_announcement: "yes" });
      } else {
        setState({ ...state, group_announcement: "no" });
      }
    } else {
      // if (name === "purchase_price") {
      //   setState({ ...state, [name]: value.replaceAll("$", "") });
      // } else {
      //   setState({ ...state, [name]: value });
      // }
      if (name === "purchase_price") {
        setState({ ...state, [name]: value.replaceAll("$", "") });
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          errors: { ...prevErrors.errors, purchase_price: "" }
        }));
      } else {
        setState({ ...state, [name]: value });
      }
    }

    if (name === "address") {
      setAddress(value);
      setLocationState(obj);
    }
    if (name === "address1") {
      setAddress1(value);
      setLocationState1(obj);
    }
    if (name === "zipcode") {
      setLocationState1({ ...locationState1, [name]: value });
      setZipCodeChangeData1(value)
    }
    if (name === "zipcode1") {
      setLocationState({ ...locationState, "zipcode": value });
      setZipCodeChangeData2(value)
    }
  };


  // const handleFocus = (e) => {
  //   if (state.purchase_price !== "") {
  //     const formattedValue = parseFloat(state.purchase_price).toFixed(2);
  //     setState({ ...state, "purchase_price": formattedValue })
  //   }
  // };

  //funtion to set state for childs rating
  const handleChildRatings = (event, newValue, index, id) => {
    if (childsRating && childsRating?.length > 0) {
      childsRating.map((val, i) => {
        if (val.child_id === id) {
          const newArray = Array.from(childsRating);
          newArray[i].rating = newValue;
          setChildsRating(newArray);
        } else {
          if (i === index) {
            const newArray = Array.from(childsRating);
            newArray[i].rating = newValue;
            newArray[i].child_id = id;
            setChildsRating(newArray);
          }
        }
      });
    } else {
      let arr = [];
      for (let i = 0; i < childData?.length; i++) {
        if (i === index) {
          let childObj = { child_id: id, rating: newValue };
          arr.push(childObj);
        } else {
          let childObj = { child_id: "", rating: 0 };
          arr.push(childObj);
        }
      }
      setChildsRating(arr);
    }
  };
  //funtion to set state for childs rating checkboxes which checkbox is selected
  const handleChildRatingsCheckboxes = (e) => {
    if (e.target.checked === true) {
      setChildRatingChekbox([...childRatingChekbox, e.target.value]);
    } else if (e.target.checked === false) {
      let freshArray = childRatingChekbox.filter(
        (val) => val !== e.target.value
      );

      setChildRatingChekbox([...freshArray]);

      childsRating?.length > 0 &&
        childsRating.forEach((val, i) => {
          if (val.child_id == e.target.value) {
            const newArray = Array.from(childsRating);
            newArray[i].rating = 0;
            newArray[i].child_id = "";
            setChildsRating(newArray);
          }
        });
    }
  };


  //function to set state for isparent checked and if parent checkbox not checked parent rating will be 0 and childsrating and their checkboxes also 0 and unchecked
  const handleParentCheckbox = (e) => {
    setIsParentChecked(!isParentChecked);
    if (e.target.checked === false) {
      setParentRating(0);
      setChildsRating([]);
      setChildRatingChekbox([]);
    }
  };

  //function to upload content
  const handleFileUpload = (e) => {
    let content = e.target.files;
    let fileTypeValidationRes = [];
    for (let i = 0; i < content?.length; i++) {
      fileTypeValidationRes.push(isValidFileUploaded(content[i]));
    }
    if (
      fileTypeValidationRes.length > 0 &&
      fileTypeValidationRes.includes(false)
    ) {
      toastifyMessage(
        "Only png, jpeg, jpg, pdf, mpeg, mp4, mp3, avi, mov are allowed",
        "error"
      );
    } else {
      getUploadedFileSize(content[0], "content")

    }
  }

  //remove content temporary or by using api call if any content id exists
  const removeUploadContent = (contentid, index, name) => {
    const newArray = Array.from(uploadContentName);
    newArray.splice(index, 1);
    setUploadContentName(newArray);
    if (resourceContent && resourceContent?.length > 0) {
      const newArray1 = Array.from(uploadContent);
      let i = newArray1.findIndex((x) => x.name === name);
      newArray1.splice(i, 1);
      setUploadContent(newArray1);
    } else {
      const newArray1 = Array.from(uploadContent);
      newArray1.splice(index, 1);
      setUploadContent(newArray1);
    }
    if (contentid) {
      setloader(true);
      let data = {
        // userAuth:userAuth.id,
        id: contentid,
      };
      setOpen(false);
      //console.log(data,"delete payload", contentid,"cid")
      makePostRequest(URLS.deleteResourceContentFile, null, data, null).then(
        (res) => {
          if (res.code === 200) {
            toastifyMessage(res.message, "success");
            setloader(false);
          } else {
            toastifyMessage(res.message, "error");
            setloader(false);
          }
        }
      );
    }
  };


  //api call to get uploaded content data for the resources
  const getResourceContent = () => {
    let data = {
      resource_id: id,
    };
    makePostRequest(URLS.contentRead, null, data, null).then(
      (res) => {
        let resourceData = res?.data;
        // console.log(resourceData, "resource data");
        setResourceContent(resourceData);
        let arr = [];
        resourceData &&
          resourceData?.length > 0 &&
          resourceData.forEach((val, i) => {
            if (val.status == "not_deleted") {
              let imgName = val.file_path;
              imgName = imgName.split("/");
              if (val.content_thumbnail === "yes") {
                // setThumbnailImgName(imgName);
              } else {
                arr.push({
                  id: val.id,
                  name: imgName[3],
                  imgurl: val.content,
                  imgType: val.file_type.replace(".", ""),
                  type: "get"
                });
              }
            }
          });
        setUploadContentName(arr);
      }
    );
  };

  //api call to get all unique keywords data

  const getAllUniqueKeywordsData = () => {
    makeGetRequest(URLS.GET_ALL_KEYWORDS, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          let options = [];
          res?.data?.length > 0 &&
            res.data.map((val, i) => {
              options.push({ keyword: val.keyword, label: val.keyword });
            });

          setKeywordsData(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLearningPillar = (learningPillarVal) => {
    setLearningPillarIds(learningPillarVal);
  };
  const handleApprovedStates = (stateVal) => {
    setApprovedStatesIds(stateVal);
  };

  const HandlePriority = (e) => {
    setPriority(e.target.value)
  };

  const handleAffiliateResource = (e) => {
    setAffiliateValue(!affiliateValue)

  }

  //get file uploadedsize
  const getUploadedFileSize = async (file, type) => {
    setloader(true);
    let formData = new FormData();
    if (type === "content") {
      formData.append("content", file);
    }
    if (type === "Thumb") {
      formData.append("contentThumbnail", file);
    }
    // console.log("type", file)
    await axios
      .post(BASE_URL + URLS.FILE_SAVE, formData, {
        headers: {
          Authorization: "Bearer " + my_token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setloader(false);
        if (isProduction == "true") {
          let decryptdata = CryptoJS.AES.decrypt(
            response.data?.data,
            secretKey
          ).toString(CryptoJS.enc.Utf8);
          let plainText = JSON.parse(decryptdata);
          // console.log("plainText", plainText)
          if (plainText.code === 200) {
            if (plainText.data[0]?.id) {
              setThumbnailId([...thumbnailId, plainText.data[0]?.id])
              if (type === "content") {
                setUploadContentName([...uploadContentName, {
                  id: plainText.data[0]?.id,
                  name: plainText.data[0]?.file_path,
                  imgurl: "https://matchstagenode.makemagento.com" + plainText.data[0]?.file_path,
                  imgType: plainText.data[0]?.file_type.replace(".", ""),
                  size: formatSize(plainText?.data[0]?.new_size),
                  type: "temp"
                }])
              } else {
                setThumbnailImgName({ "id": plainText.data[0]?.id, "image": "https://matchstagenode.makemagento.com" + plainText.data[0]?.file_path, size: formatSize(plainText?.data[0]?.new_size) })
              }
              toastifyMessage(plainText.message, "success")
            }
          } else {
            toastifyMessage(plainText.message, "error")
          }
        }
      })
      .catch((error) => {
        setloader(false);
        console.log("errrr", error);
      });
  };

  const removeImage = (id) => {
    setloader(true);
    const body = {
      id_temp: [id]
    }
    makePostRequest(URLS.REMOVE_FILE, null, body, null)
      .then((res) => {
        setloader(false);
        if (res.code === 200) {
          toastifyMessage(res?.message, "success")
          if (thumbnailImgName.id === id) {
            setThumbnailImgName({})
          } else { setThumbnailImgName(thumbnailImgName) }
          setThumbnailId(thumbnailId.filter(item => ![id].includes(item)))
          setUploadContentName(uploadContentName.filter(item => item.id !== id))
        } else {
          toastifyMessage(res?.message, "error")
        }
      }).catch((error) => {
        console.log("error::", error)
      })
  }

  /*=============================get friend and group data=============================*/
  const GetFriendGroupData = () => {
    let data = {
      "id": id,
    };
    // console.log(data,"reshareListData")
    makePostRequest(URLS.GET_GROUP_FRIENDRESOURCE, null, data, null)
      .then((res) => {
        // console.log("resshareListGroups:::", res)
        let frienArr = [];
        let idArr = [];
        for (let i = 0; i < res?.data?.friend?.length; i++) {
          frienArr.push({ value: res?.data?.friend[i]?.id, label: res?.data?.friend[i].firstName + " " + res?.data?.friend[i]?.lastName });
          idArr.push(res?.data?.friend[i]?.id)
        }
        // console.log(frienArr, "friendarr")
        setFriendData(frienArr);
        setShareFriends(idArr)

        const groupArr = [];
        const groupIdArr = [];

        for (let i = 0; i < res?.data?.group?.length; i++) {
          groupArr.push({ value: res?.data?.group[i]?.id, label: res?.data?.group[i].group_name });
          groupIdArr.push(res?.data?.group[i]?.id)
        }
        setGroupData(groupArr);
        setGroupId(groupIdArr)
      }).catch((error) => {
        console.log("error:::", error)
      })
  }

  useEffect(() => {
    GetFriendGroupData();
  }, [])
  /*==========================Review Api calling here=================*/
  const ReviewResource = () => {

    let data = {
      id: resourceId,
      // id:id,
      is_review: reviewd ? "yes" : "no",

    };
    // console.log(data,"reviewdata")
    makePostRequest(URLS.REVIEW_RESOURCE, null, data, null)
      .then((res) => {
        // console.log(res,"review")
        if (res.code === 200) {
          // toast.success(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {

    ReviewResource();

  }, [reviewd])

  //api call to get resource detail by resource id
  const getListData = () => {
    makePostRequest(URLS.GET_GROUP_FRIENDRESOURCE, null, { "id": id }, null)
      .then((res) => {
        // console.log(res, "resGrouplist............")
        const frienArr = [];
        const idArr = [];
        res?.data?.friend?.forEach((val, i) => {
          // if (userData.userAuth.id != val?.id) {
          frienArr.push({
            label: val?.firstName + " " + val?.lastName,
            value: val?.id,
          });
          idArr.push(val?.id);
          // }
        });
        setFriendData(frienArr);
        setShareFriends(idArr);

        const groupArr = [];
        // console.log(groupArr,"garray")
        const groupIdArr = [];
        const toggle = [];
        const resource = [];
        res?.data?.group?.forEach((val, i) => {
          groupArr.push({
            label: val?.group_name,
            value: val?.id,
            type: val?.tbl_group_members[0]?.isAdmin == 1 ? "Admin" : "Member"
          });
          groupIdArr.push(val?.id);
        });
        res?.data?.group_announcement_list?.forEach((val, i) => {
          toggle.push(val?.id)
        })
        res?.data?.group_with_share_list?.forEach((val, i) => {
          resource.push(val?.id)
        })
        setGroupId(groupIdArr);
        setToggleValues(toggle);
        setresourcetoggle(resource);
        const array2Map = new Map(res?.data?.group_announcement_list?.map(obj => [obj.id, obj]));
        const array3Map = new Map(res?.data?.group_with_share_list?.map(obj => [obj.id, obj]));

        const mergedArray1 = groupArr.map(obj1 => {

          const obj2 = array2Map.get(obj1.value);
          const obj3 = array3Map.get(obj1.value);
          if (obj2 && obj3) {
            return { ...obj1, type: "Admin", check: true, resourceCheck: true };
          }
          if (obj3) {
            return { ...obj1, type: "Admin", resourceCheck: true };
          }
          if (obj2) {
            return { ...obj1, type: "Admin", check: true };
          }
          return obj1;
        });
        setGroupData(mergedArray1);

      }).catch((error) => {
        console.log("error:::", error)
      })
  }

  useEffect(() => {
    getListData();
  }, [])

  const handleYes = () => {
    const updatedGroupData = [...groupData];
    updatedGroupData[toggleIndex].check = true;
    setGroupData(updatedGroupData);
    const updatedToggleValues = updatedGroupData
      .filter((item) => item.check)
      .map((item) => item.value);
    setToggleValues(updatedToggleValues)
  }
  const handleNo = () => {
    const updatedGroupData = [...groupData];
    updatedGroupData[toggleIndex].check = false;
    setGroupData(updatedGroupData);
    const updatedToggleValues = updatedGroupData
      .filter((item) => item.check)
      .map((item) => item.value);
    setToggleValues(updatedToggleValues)
  }
  const handleToggleChange = (index, e) => {
    if (e.target.checked) {
      setIsPopupOpen(true);
      setToggleIndex(index)
      setToggleMessage("Do you want to pin this to your group bulletin board?")
      setHandleState(true);
      setChnageState("announce")
    } else {
      setIsPopupOpen(true);
      setToggleIndex(index)
      setToggleMessage("Do you want to unpin this to your group bulletin board?")
      setHandleState(false);
      setChnageState("announce")
    }

  };
  const resourceHandleToggleChange = (index, e) => {
    if (e.target.checked) {
      setIsPopupOpen(true);
      setToggleIndex(index)
      setToggleMessage("Do you want to share this to your group resources?")
      setHandleState(true);
      setChnageState("resource");
    } else {
      setIsPopupOpen(true);
      setToggleIndex(index)
      setToggleMessage("Do you want to remove this from your group resources?")
      setHandleState(false);
      setChnageState("resource")
    }

  };

  const handleResourceYes = () => {
    const updatedGroupData = [...groupData];
    updatedGroupData[toggleIndex].resourceCheck = true;
    setGroupData(updatedGroupData);
    const updatedToggleValues = updatedGroupData
      .filter((item) => item.resourceCheck)
      .map((item) => item.value);
    setresourcetoggle(updatedToggleValues)
  }
  const handleResourceNo = () => {
    const updatedGroupData = [...groupData];
    updatedGroupData[toggleIndex].resourceCheck = false;
    setGroupData(updatedGroupData);
    const updatedToggleValues = updatedGroupData
      .filter((item) => item.resourceCheck)
      .map((item) => item.value);
    setresourcetoggle(updatedToggleValues)
  }
  const geResourcesById = () => {
    //setloader(true);
    let data = {
      id: id,
      // user_id: userData.userAuth.id,
    };
    makePostRequest(URLS.getResourceById, null, data, null)
      .then((res) => {
        console.log(res, "getoneresource......................//////")
        if (res.code === 200) {
          let data = res?.data;
          setUserDtl(data.tbl_user.firstName)
          setResourceMode(data.resource_mode)
          setGroupResourceId(data.group_resource_id)
          setAboutInstructor(data.about_instructor);
          setRequirementsTool(data.resource_requirement);
          setCountryName(data?.tbl_user?.country_name)
          if (data.resource_type_status === 'group') {
            setGroupStatus('group');
          }
          setGroupStatusforPayload(data.resource_type_status)
          if (data.resource_type_status === 'group') {
            data?.chat_user && setShowAdmin({ value: data?.chat_user?.id, label: data?.chat_user?.firstname + " " + data?.chat_user?.lastName })
            setGetadminName(data?.chat_user?.id)
          }
          setUserId(data.user_id)
          setIds(data.id)

          setPriority(data.priority_of_resource);
          setReviewd(data.is_review === "yes" ? true : false);
          setAffiliateValue(data.affiliate_resource === "1" ? true : false)
          setState({
            ...state,
            purchase_price: data.purchase_price ? data.purchase_price?.toFixed(2) : "",
            resourceurl:
              data.resource_url && data.resource_url !== "undefined"
                ? data.resource_url
                : "",
            resource_title: data.resource_title ? data.resource_title : "",
            group_announcement: data?.group_announcement
              ? data?.group_announcement
              : "no",
            comments: "",
            isbn_number:
              data.isbn && data.isbn !== "undefined"
                ? data.isbn
                : "",
            public_funding:
              data.public_funding === "yes"
                ? "1"
                : data.public_funding === "no"
                  ? "2"
                  : "",
          });
          setPurchaseRes(data?.purchase_from_me);
          data?.resource_thumbnail_image && setThumbnailImgName({ image: imageUrl + data?.resource_thumbnail_image })
          // if (data.enrollment_email !== null) {
          // setEmailId(data.enrollment_email)
          setEmailId(data.tbl_user.email)
          // }
          setSendMsg(data.message_send);
          setAboutResource(data.about_resource);
          setDescription(data.description);
          setStoringType(data.storing_type ? data.storing_type : "Learning Artifacts");
          setInstructionalStyle(
            data?.tbl_resource_instruction_styles[0]?.instruction_id ? data?.tbl_resource_instruction_styles[0]?.instruction_id : ""
          );
          setResourceType(data.resource_type);
          setSaveFor(data.save_for ? data.save_for : "Past");
          if (data.tbl_resource_type_master.name === "Curriculum Content") {
            setTimeFrame(data?.tbl_resource_timeframes[0]?.timeframe);
            if (data?.tbl_resource_timeframes[0]?.timeframe === "Flexible") {
              setTimeFrameActive1(true);
              setTimeFrameActive2(false);
              setTimeFrameActive3(false);
              setTimeFrameActive4(false);
            } else if (data?.tbl_resource_timeframes[0]?.timeframe === "less than a semester") {
              setTimeFrameActive1(false);
              setTimeFrameActive2(true);
              setTimeFrameActive3(false);
              setTimeFrameActive4(false);
            } else if (data?.tbl_resource_timeframes[0]?.timeframe === "semester") {
              setTimeFrameActive1(false);
              setTimeFrameActive2(false);
              setTimeFrameActive3(true);
              setTimeFrameActive4(false);
            } else if (data?.tbl_resource_timeframes[0]?.timeframe === "school year") {
              setTimeFrameActive1(false);
              setTimeFrameActive2(false);
              setTimeFrameActive3(false);
              setTimeFrameActive4(true);
            }
          }
          if (data.tbl_resource_type_master.name === "Experiences") {
            setTimeFrame(data?.timeframe);
            if (data?.timeframe === "days") {
              setTimeFrameActive1(true);
              setTimeFrameActive2(false);
              setTimeFrameActive3(false);
              setTimeFrameActive4(false);
            } else if (data?.timeframe === "weeks") {
              setTimeFrameActive1(false);
              setTimeFrameActive2(true);
              setTimeFrameActive3(false);
              setTimeFrameActive4(false);
            } else if (data?.timeframe === "months") {
              setTimeFrameActive1(false);
              setTimeFrameActive2(false);
              setTimeFrameActive3(true);
              setTimeFrameActive4(false);
            } else if (data?.timeframe === "years") {
              setTimeFrameActive1(false);
              setTimeFrameActive2(false);
              setTimeFrameActive3(false);
              setTimeFrameActive4(true);
            }
          }
          const start = new Date(data.start_date);
          const startDate = start.toString();
          const sDate = new Date(startDate);
          data.start_date == null ? setStartDate("") : setStartDate(sDate);
          const end = new Date(data.end_date);
          const endDate = end.toString();
          const eDate = new Date(endDate);
          data.end_date == null ? setEndDate("") : setEndDate(eDate);
          let startTime = data.start_time ? data.start_time : "00:00:00";
          startTime = startTime.split(":");
          setStartTime(startTime[0] + ":" + startTime[1]);
          let endTime = data.end_time ? data.end_time : "00:00:00";
          endTime = endTime.split(":");
          setEndTime(endTime[0] + ":" + endTime[1]);
          let childArrs = [];
          data.tbl_resource_saveds?.length > 0 &&
            data.tbl_resource_saveds.forEach((val, i) => {
              childArrs.push(val.child_id);
            });
          childArrs = childArrs.filter(
            (item, index) => childArrs.indexOf(item) === index
          );
          setChildIds(childArrs);
          let options = [];
          data.tbl_pillar_resource_mappings?.length > 0 &&
            data.tbl_pillar_resource_mappings.forEach((val, i) => {
              options.push({
                value: val.tbl_learning_pillar_master.id,
                label: val.tbl_learning_pillar_master.name,
              });
            });
          setLearningPillarIds(options);
          let options1 = [];
          data.tbl_grade_resource_mappings?.length > 0 &&
            data?.tbl_grade_resource_mappings?.forEach((val, i) => {
              options1.push({
                value: val.tbl_grade_level.id,
                label: val.tbl_grade_level.name,
              });
            });
          setGradeLevelIds(options1);
          let options3 = [];
          data.tbl_resource_funding_states?.length > 0 &&
            data.tbl_resource_funding_states.forEach((val, i) => {
              options3.push({
                value: val.states_id,
                label: val.tbl_state.name,
              });
            });
          setApprovedStatesIds(options3);
          if (data?.tbl_resource_urls?.length > 0) {
            const url = [];
            data?.tbl_resource_urls?.length > 0 &&
              data?.tbl_resource_urls.forEach((val, i) => {
                url.push({
                  Link: val.link,
                });
              });

            setInputlist(url)
          }
          else {
            setInputlist([{ "Link": "" }])
          }
          setAffiliateButton({
            "affiliate_purchase_button": data?.affiliate_purchase_button,
            "affiliate_signup_button": data?.affiliate_signup_button,
            "affiliate_enroll_button": data?.affiliate_enroll_button,
            "affiliate_add_info_button": data.affiliate_add_info_button,
          })
          setAddress(data.location);
          setAddress1(data.define_address);
          var location = []
          data?.tbl_resource_location_types?.map((val) => {
            return location.push(val.location_type)
          }
          )
          setLocationType(location);
          let addressObj = {
            country: data?.country,
            city: data?.city,
            zipcode: (data?.zipcode && data?.zipcode !== undefined && data?.zipcode !== null) ? data?.zipcode : "",
            state: data?.state,
            latitude: data?.latitude,
            longitude: data?.longitude,
          };
          setLocationState(addressObj);
          let addressObj1 = {
            country: data?.define_country,
            city: data?.define_city,
            zipcode: (data?.define_zipcode && data?.define_zipcode !== undefined && data?.define_zipcode !== null) ? data?.define_zipcode : "",
            state: data?.define_state,
            latitude: data?.define_latitude,
            longitude: data?.define_longitude,
          };
          setLocationState1(addressObj1);
          let options4 = [];
          let id = [];
          if (data.local_public === "yes") {
            options4.push({ value: "local", label: "Local matched community" });
            id.push("local");
          }
          if (data.friends === "yes") {
            options4.push({ value: "friend", label: "Friends" });
            id.push("friend");
          }
          if (data.groups === "yes") {
            options4.push({ value: "group", label: "Groups" });
            id.push("group");
          }
          if (data.is_matched === "yes") {
            options4.push({ value: "matched", label: "All MatchED" });
            id.push("matched");
          }
          if (data.status === "under_review") {
            options4.push({ value: "none", label: "Private" });
            id.push("none");
          }
          setShareResource(id);
          setShareResourceSelectedData(options4);
          let keywordArr = [];
          data?.tbl_resource_keywords?.length > 0 &&
            data?.tbl_resource_keywords?.forEach((val, i) => {
              keywordArr.push({
                keyword: val.keyword,
                label: val.keyword,
              });
            });
          setKeywords(keywordArr);
          setParentRating(data?.tbl_resource_reviews[0]?.rating);
          setComments(data?.tbl_resource_reviews[0]?.message);
          if (data?.tbl_resource_reviews[0]?.rating) {
            setIsParentChecked(true);
          }
          let checkboxIdArr = [];
          childData?.length > 0 &&
            childData.forEach((itm, index) => {
              data?.tbl_resource_reviews[0]?.tbl_resource_child_reviews?.length >
                0 &&
                data?.tbl_resource_reviews[0]?.tbl_resource_child_reviews.forEach(
                  (val, i) => {
                    if (itm.id === val.child_id) {
                      const newArray = Array.from(childsRating);
                      newArray[index].rating = val.rating;
                      newArray[index].child_id = val.child_id;
                      setChildsRating(newArray);
                      checkboxIdArr.push(val.child_id);
                    }
                  }
                );
            });
          setChildRatingChekbox(checkboxIdArr);
          setExperienceType(
            data?.tbl_resource_experiences &&
              data?.tbl_resource_experiences?.length > 0
              ? data?.tbl_resource_experiences[0]?.experience_id : "");
          setFacilityType(
            data?.tbl_resource_facilities &&
              data?.tbl_resource_facilities?.length > 0
              ? data?.tbl_resource_facilities[0]?.facility_id : "");

          setFundingType(
            data?.tbl_resource_fundings && data?.tbl_resource_fundings?.length > 0
              ? data?.tbl_resource_fundings[0]?.funding_id : "");

          setOrganizationType(
            data?.tbl_resource_organisations &&
              data?.tbl_resource_organisations?.length > 0
              ? data?.tbl_resource_organisations[0]?.organisation_id : "");
          setContentType(
            data?.tbl_resource_contents && data?.tbl_resource_contents?.length > 0
              ? data?.tbl_resource_contents[0]?.content_id : "");
          let options5 = [];
          data?.tbl_resource_supports &&
            data?.tbl_resource_supports?.length > 0 &&
            data?.tbl_resource_supports?.forEach((val, i) => {
              options5.push({
                value: val.support_id,
                label: val.tbl_type_support_master.name,
              });
            });
          setSupportTypes(options5);
          if (data.resource_type === 5) {
            setCustomerType(data.tbl_resource_customer_types[0]?.customer_type ? data.tbl_resource_customer_types[0]?.customer_type : "");
            if (data.tbl_resource_customer_types[0]?.customer_type === "Teacher") {
              setCustomerTypeActive1(true);
              setCustomerTypeActive2(false);
              setCustomerTypeActive3(false);
            } else if (data.tbl_resource_customer_types[0]?.customer_type === "Parent") {
              setCustomerTypeActive1(false);
              setCustomerTypeActive2(true);
              setCustomerTypeActive3(false);
            }
            else if (data.tbl_resource_customer_types[0]?.customer_type === "Other") {
              setCustomerTypeActive1(false);
              setCustomerTypeActive2(false);
              setCustomerTypeActive3(true);
            }
          }

          setAccreditedResource({
            ...accreditedResource,
            accredited_res: data?.accredited_resource === "yes" ? true : false,
            accrediting_inst: data?.accrediting_institution,
          });
          setDualEnrolment({
            ...dualEnrolment,
            dual_enrol: data?.dual_enrollment_resource === "yes" ? true : false,
            credit_granting_ins: data?.credit_granting_institution,
            no_of_college_worth: data?.college_credits_number,
          });
          setloader(false);
        } else {
          setloader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) {
      geResourcesById();
      getResourceContent();

      getListData();
    }
  }, [id]);

  useEffect(() => {
    if (customerTypeActive1 || customerTypeActive2 || customerTypeActive3) {
      setCustomerType(prevType => prevType); // Keep the existing customer type
    } else {
      setCustomerType(""); // Reset customer type if none is active
    }
  }, [customerTypeActive1, customerTypeActive2, customerTypeActive3]);

  useEffect(() => {
    if (id && childData) {
      geResourcesById();
    }
  }, [id, childData]);

  //show Image/video content  preview on click of preview button
  const showContentPreview = (imgurl, imgname) => {
    // let imgType = imgname.split(".");

    setContentPreview({
      ...contentPreview,
      imgurl: imgurl,
      imgtype: imgname,
    });
    setVisible(true);
  };

  const uploadFileContentData = (resourceId) => {
    const body = {
      "temp_resource": thumbnailId,
      "id": resourceId
    }
    // console.log("res:::", body)
    makePostRequest(URLS.UPLOAD_SAVE, null, body, null)
      .then((res) => {
        // console.log("resThumbnail:::", res)
        if (res.code === 201) {
          toastifyMessage(res.message, "success")
          setTimeout(() => {
            // navigate("/save-resources-list");
          }, 1000);
        } else {
          toastifyMessage(res.message, "success")
        }
      }).catch((error) => {
        console.log("error:::", error)
      })
  }
  const beforeCrop = (file) => {
    if (
      file.name.includes("jpg") ||
      file.name.includes("png") ||
      file.name.includes("jpeg")
    ) {
      // setimgflag(false);
      return true;
    } else {
      toastifyMessage("Only png, jpg, jpeg are allowed", "error");
      return false;
    }
  };

  //upload thumbnail
  const handleThumbnail = (e) => {
    const validExtensions = ["png", "jpg", "jpeg"];
    let file = e;
    const fileExtension = file?.type?.split("/")[1];
    if (!validExtensions.includes(fileExtension)) {
      toastifyMessage("Only png, jpg, jpeg are allowed", "error");
      return;
    }

    setThumbnail(file);
    getUploadedFileSize(file, "Thumb")

  };


  return (
    <>
      <Head title="Edit Resource"></Head>
      {/* <ToastContainer /> */}

      {loader == true ?
        <div className="overflowloader" id="preload">
          <div className="circle-square" >
            <div className="red" style={{ background: "#AAC0CE" }}></div>
            <div className="blue" style={{ background: "#265472" }}></div>
            <div className="green" style={{ background: "#F19357" }}></div>
            <div className="yellow" style={{ background: "#F0B285" }}></div>
          </div>
        </div> :

        <Content>
          <h3 className="nk-block-title page-title  pb-4">Edit Resource ({resourceMode})

            <button onClick={() => history.goBack()} className="btn btn-outline-light bg-white d-none d-sm-inline-flex float-end">
              <em className="icon ni ni-arrow-left"></em><span>Back</span>
            </button>
          </h3>
          <Card className="card-bordered edit-resource">
            <div className="container">
              <div className="row">
                <div className="col-lg-0 marginboottom">
                  {/* <Sidebar /> */}
                </div>
                <div className="col-lg-12">

                  <div className='save-main-container'>
                    <div className="mb-2 mt-2 col-12">
                      <strong className="float-start">Mark as Reviewed</strong>
                      <div className="custom-control custom-switch mx-2">
                        <input
                          type="checkbox"
                          value={reviewd}
                          checked={reviewd}
                          onChange={(e) => setReviewd(!reviewd)}
                          className="custom-control-input"
                          id="latest-sale"
                        />
                        <label className="custom-control-label" for="latest-sale"></label>
                      </div>
                    </div>

                    <div className="mb-2 mt-2 col-12">
                      <strong className="float-start">Affiliate Resource</strong>
                      <div className="custom-control custom-switch mx-2">
                        <input
                          type="checkbox"
                          value={affiliateValue}
                          checked={affiliateValue}
                          onChange={handleAffiliateResource}
                          className="custom-control-input"
                          id="sale"
                        />
                        <label className="custom-control-label" for="sale"></label>
                      </div>
                    </div>

                    <div className="save-type-of-Outer mb-4">
                      <label className="save-type-of-r">
                        Select Priority
                        <span className="start-color">*</span>{" "}
                        <span className="select-one"> (select one)</span>

                      </label>
                      <select
                        className="form-select f-select mb-0"
                        aria-label="Default select example"
                        name="priority"
                        onChange={HandlePriority}
                        value={priority}
                        ref={priorityFocus}
                      >
                        <option value="" className="select-val">Select Priority</option>
                        <option value="P1" className="select-val">P1 </option>
                        <option value="P2" className="select-val">P2 </option>
                        <option value="P3" className="select-val">P3 </option>
                        <option value="P4" className="select-val">P4 </option>
                        <option value="P5" className="select-val">P5 </option>

                      </select>

                      <span className="error-span">
                        {(errors && priority.length <= 0) ? errors.priority : ""}
                      </span>
                    </div>

                    <div className="r-title-div mb-1">
                      <h3>
                        Keywords
                        <span className="start-color">*</span>
                        <span className="select-one">
                          {" "}
                          &nbsp; (enter any/all keywords associated with this
                          resource/artifact)
                        </span>{" "}

                      </h3>

                    </div>

                    <div className="kayword-associatedNew">
                      <Autocomplete
                        multiple
                        value={keywords}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setKeywords({
                              keyword: newValue,
                            });
                          } else if (newValue && newValue.inputValue) {
                            setKeywords({
                              keyword: newValue.inputValue,
                            });
                          } else {
                            let filteredArray = newValue.filter(item => {
                              if (typeof item === 'string') {
                                return item.trim() !== '';
                              }
                              if (item.hasOwnProperty('inputValue') && typeof item.inputValue === 'string') {
                                return item.inputValue.trim() !== '';
                              }
                              return true;
                            });
                            setKeywords(filteredArray);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          const isExisting = options.some(
                            (option) => inputValue === option.keyword
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              keyword: inputValue,
                            });
                          }

                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={keywordsData}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.keyword;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option.keyword}</li>
                        )}
                        sx={{ width: 300 }}
                        freeSolo
                        filterSelectedOptions

                        renderInput={(params) => (

                          <TextField {...params} label="" inputRef={input => {
                            keywordFocus = input;
                          }} />
                        )}
                      />
                      <span className="error-span">
                        <span className="error-span">{errors && keywords?.length === 0 ? errors.keywords : ""}</span>
                      </span>
                    </div>

                    <div className="mul-sel-div mb-4">
                      <h3>
                        Grade Range <span className="start-color">*</span>{" "}
                        <span className="select-one"> (select one or multiple)</span>
                      </h3>
                      <div>
                        <Select
                          value={gradeLevelIds ? gradeLevelIds : null}
                          options={gradeLevelData}
                          isMulti
                          selectMultiple={true}
                          ref={inputRefGrade}
                          onChange={handleGrade}
                        />
                        <span className="error-span">
                          {(errors && gradeLevelIds.length <= 0) ? errors.gradeLevelIds : ""}
                        </span>
                        <span className="error-span">
                          {
                            message.grade && "Note: Now you can not able to select any grade"
                          }
                        </span>
                      </div>
                    </div>


                    <div className="r-title-div">
                      <h3>Upload Thumbnail Image</h3>
                      <div>
                        <div >
                          <section >
                            <span className="u-content">
                              {" "}
                              <i className="fa fa-cloud-upload uploadicon"></i>
                              <span>
                                {thumbnailImgName.image ? thumbnailImgName.image : "Upload Thumbnail"}
                              </span>
                            </span>

                            <ImgCrop
                              showGrid
                              cropperProps={{ restrictPosition: false }}
                              minZoom={0.2}
                              zoomSlider
                              rotationSlider
                              showReset
                              aspect={9.5 / 9}
                              height={1200}
                              beforeCrop={(file) => beforeCrop(file)}
                              onModalOk={(cropedimg) => handleThumbnail(cropedimg)}
                            >
                              <Upload
                                showUploadList={false}
                                onChange={({ fileList: newFileList }) => console.log("newFileList", newFileList)} // Prevent default upload behavior
                              >
                                <Button >Upload Image</Button>
                              </Upload>
                            </ImgCrop>
                          </section>
                        </div>
                        <p className="mt-1">Note: Only png, jpg, jpeg are allowed.</p>
                      </div>
                      {
                        thumbnailImgName.image && <div className="duplod1 ">
                          <h4 className="dp float-start">
                            <button
                              className="btn btn-md"
                              onClick={() => {
                                showContentPreview(thumbnailImgName.image, "png");
                                window.scrollTo({
                                  bottom: 0,
                                  behavior: "smooth",
                                });
                                if (
                                  document.body.classList.contains("savepage")
                                ) {
                                  document.body.classList.add("removescroll");
                                } else {
                                  document.body.classList.remove("removescroll");
                                }
                              }}
                            ><p><img src={thumbnailImgName.image} width={200} heigth={200} className="image_pre" alt="upload " /></p></button>
                            {thumbnailImgName.image.split("/").pop()}{thumbnailImgName?.size && " (Resize to: " + thumbnailImgName?.size + ")"}
                          </h4>
                          {
                            thumbnailImgName.id && <div className="float-end">
                              <button className="btn btn-md px-0 mt-0 border-0" style={{ marginRight: "16px" }} onClick={() => removeImage(thumbnailImgName.id)}>Remove</button>
                            </div>
                          }
                        </div>

                      }
                    </div>


                    {resourceMode === "serve" ? "" : <>
                      <h4>Store a Resource or Artifact<span className="select-one">&nbsp;(select one)</span></h4>

                      <div className="radiosdiv">

                        <input
                          className="form-check-input"
                          type="radio"
                          id="css"
                          name="storing_type"
                          value="Learning Resource"
                          onChange={(e) => setStoringType(e.target.value)}
                          checked={storingType === "Learning Resource"}
                        />
                        <label for="css">Learning Resource</label>

                        <input
                          className="form-check-input"
                          type="radio"
                          id="html"
                          name="storing_type"
                          value="Learning Artifacts"
                          onChange={(e) => setStoringType(e.target.value)}
                          checked={storingType === "Learning Artifacts"}
                        />
                        <label for="html">Learning Artifact</label>

                      </div>
                    </>}

                    {/* {resourceMode=== "serve" ?"":<>
                    {childData.length > 0 && (
              <>
                <div className="save-heading" ref={ChildFocus} >
                  <h4>
                    Applies to<span className="start-color">*</span>
                    <span className="select-one">
                      {" "}
                      (select one or multiple)
                    </span>
                  </h4>
                </div>
                <div className="save-div-name-outer" >
                  <div className="save-div-name">

                    {childData &&
                      childData.length > 0 &&
                      childData?.map((val, i) => {
                        return (
                          <ChildCard
                            title={val}
                            handleChildIds={(id, active) => {
                              handleChildIds(id, active);
                            }}
                            selectedChildIds={childIds}
                          />
                        );
                      })}
                    <span className="error-span">
                      {(errors && childIds.length <= 0) ? errors.childids : ""}
                    </span>
                  </div>
                </div>
              </>
            )}
                  </>   }  */}

                    <div className="save-type-of-Outer mb-4">
                      <label className="save-type-of-r">
                        Type of Resource <span className="start-color">*</span>{" "}
                        <span className="select-one"> (select one)</span>
                      </label>
                      <select
                        className="form-select f-select mb-0"
                        aria-label="Default select example"
                        name="resourceType"
                        onChange={handleInput}
                        value={resourceType}
                        ref={inputRef2}
                        disabled={fromDetails}
                      >
                        <option value="" className="select-val">
                          Select Resource
                        </option>
                        {resourceData.length > 0 &&
                          resourceData?.map((val, i) => {
                            return (
                              <option value={val.id} key={i + 1}>
                                {val.name}
                              </option>
                            );
                          })}
                      </select>
                      <span className="error-span">
                        {(errors && !resourceType) ? errors.resourceType : ""}
                      </span>
                    </div>


                    {parseInt(resourceType) === 2 && (
                      <div className="save-container-3">
                        <label className="save-type-of-r">
                          Type of Content <span className="start-color">*</span>{" "}
                          <span className="select-one"> (select one)</span>
                        </label>
                        <select
                          className="form-select f-select"
                          aria-label="Default select example"
                          name="contentType"
                          onChange={handleInput}
                          value={contentType}
                          ref={inputRefType}
                        >
                          <option value="" className="select-val">
                            Select Content Type
                          </option>
                          {contentTypeData.length > 0 &&
                            contentTypeData?.map((val, i) => {
                              return (
                                <option value={val.id} key={i + 1}>
                                  {val.name}
                                </option>
                              );
                            })}
                        </select>
                        <span className="error-span">
                          {(errors && contentType.length <= 0) ? errors.contentType : ""}
                        </span>
                        <h5>
                          Instructional Style
                          {/* <span className="start-color">*</span> */}
                          {" "}
                          <span className="select-one"> (select one)</span>
                        </h5>
                        <div className="instructionStylecontetWithRadio">
                          {instructionalStyleData.length > 0 &&
                            instructionalStyleData?.map((val, i) => {
                              return (
                                <div className="save-radio-div2" key={i + 1}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id={i + 1}
                                    name="instructionl_style"
                                    value={val.id}
                                    onChange={(e) => {
                                      setInstructionalStyle(e.target.value);
                                    }}
                                    checked={instructionalStyle == val.id}
                                    ref={inputRefInStyle}
                                  />
                                  <label htmlFor={i + 1}>{val.name}</label>
                                  <br></br>
                                </div>
                              );
                            })}
                        </div>
                        <span className="error-span">
                          {(errors && instructionalStyle.length <= 0) ? errors.instructionalStyle : ""}
                        </span>
                        <div className="time-fram-divOuter mb-4">
                          <h5>
                            Time Frame
                            {/* <span className="start-color">*</span> */}
                            {" "}
                            <span className="select-one"> (select one)</span>
                          </h5>
                          <div className="time-fram-div">
                            <div ref={TimeRef}
                              id="timeframe"
                              className={` ${timeFrameActive1 ? "active" : ""}`}
                              onClick={() => {
                                handleTimeFrame("Flexible", "1");
                              }}
                            >
                              <h3 className="savetext1">Flexible</h3>
                            </div>
                            <div
                              className={` ${timeFrameActive2 ? "active" : ""}`}
                              onClick={() => {
                                handleTimeFrame("less than a semester", "2");
                              }}
                            >
                              <h3 className="savetext2">Less Than a Semester</h3>
                            </div>
                            <div
                              className={` ${timeFrameActive3 ? "active" : ""}`}
                              onClick={() => {
                                handleTimeFrame("semester", "3");
                              }}
                            >
                              <h3 className="savetext1">Semester</h3>
                            </div>
                            <div
                              className={` ${timeFrameActive4 ? "active" : ""}`}
                              onClick={() => {
                                handleTimeFrame("school year", "4");
                              }}
                            >
                              <h3 className="savetext1">School year</h3>
                            </div>
                          </div>
                          <span className="error-span">
                            {(errors && timeFrame.length <= 0) ? errors.timeFrame : ""}
                          </span>
                        </div>
                        <h3 className="date-h3">
                          Date Range{" "}
                          <span className="select-one"> (if applicable)</span>{" "}
                        </h3>
                        <div className="date-picker-main-div">
                          <div className="date-picker-div">
                            <DatePicker
                              className=""
                              id="start_date"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              placeholderText="mm-dd-yyyy"
                              dateFormat="MM-dd-yyyy"
                            />
                            <span className="error-span">
                              {(errors && startDate.length <= 0) ? errors.startDate : ""}
                            </span>
                          </div>
                          <div className="date-picker-div">
                            <div ref={DateRef}>
                              <DatePicker
                                ref={datePickerRef}
                                className=""
                                id="end_date"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                placeholderText="mm-dd-yyyy"
                                dateFormat="MM-dd-yyyy"
                              />
                            </div>
                            <span className="error-span">
                              {(errors) ? errors.endDate : ""}
                            </span>
                          </div>
                        </div>
                        <h3 className="date-h3">
                          Available times{" "}
                          <span className="select-one"> (if applicable)</span>{" "}
                        </h3>
                        <div className="date-picker-main-div">
                          <div className="date-picker-div">
                            <input
                              type="time"
                              placeholder="Start Time"
                              onChange={(e) => setStartTime(e.target.value)}
                              min="00:00"
                              max="23:59"
                              step="60"
                              value={startTime}
                            />
                          </div>
                          <div className="date-picker-div">
                            <input
                              type="time"
                              placeholder="End Time"
                              onChange={(e) => setEndTime(e.target.value)}
                              min="00:00"
                              max="23:59"
                              step="60"
                              value={endTime}
                              ref={TimeFocus}
                            />
                            <span className="error-span">
                              {(errors && endTime.length <= 0) ? errors.endTime : ""}
                            </span>
                          </div>
                        </div>
                        <div className="viewableFriend pb-1">
                          <h5>
                            {" "}
                            <span className="viewableSwitchtag">
                              Accredited Resource
                            </span>
                            <label className="switch" htmlFor="accredited-resource">
                              <input
                                type="checkbox"
                                id="accredited-resource"
                                value={accreditedResource.accredited_res}
                                checked={accreditedResource.accredited_res}
                                onChange={(e) =>
                                  setAccreditedResource({
                                    ...accreditedResource,
                                    accredited_res: !accreditedResource.accredited_res,
                                  })
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                          </h5>
                        </div>
                        {accreditedResource.accredited_res && (
                          <div className="r-title-div accre-span">
                            <h3>
                              Accrediting Institution
                              <span className="start-color">*</span>
                            </h3>
                            <div>
                              <input
                                type="text"
                                placeholder=""
                                value={accreditedResource.accrediting_inst}
                                ref={accreditingInstRef}
                                onChange={(e) =>
                                  setAccreditedResource({
                                    ...accreditedResource,
                                    accrediting_inst: e.target.value,
                                  })

                                }
                              />
                            </div>
                            <span className="error-span">
                              {(errors && accreditedResource.accrediting_inst <= 0) ? errors.accrediting_inst : ""}
                            </span>
                          </div>
                        )}
                        <div className="viewableFriend">
                          <h5 className="mb-0">
                            {" "}
                            <span className="viewableSwitchtag">
                              Dual Enrollment Resource
                            </span>
                            <label className="switch" htmlFor="dual-enrolment">
                              <input
                                type="checkbox"
                                id="dual-enrolment"
                                value={dualEnrolment.dual_enrol}
                                checked={dualEnrolment.dual_enrol}
                                onChange={(e) =>
                                  setDualEnrolment({
                                    ...dualEnrolment,
                                    dual_enrol: !dualEnrolment.dual_enrol,
                                  })
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                          </h5>
                        </div>
                        {dualEnrolment.dual_enrol && (
                          <>
                            <div className="row">
                              <div className="r-title-div col-lg-6">
                                <h3>
                                  Credit Granting Institution
                                  <span className="start-color">*</span>
                                </h3>
                                <div>
                                  <input
                                    ref={creditGrantingInsRef}
                                    type="text"
                                    placeholder=""
                                    value={dualEnrolment.credit_granting_ins}
                                    onChange={(e) =>
                                      setDualEnrolment({
                                        ...dualEnrolment,
                                        credit_granting_ins: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <span className="error-span">
                                  {/* {errors ? errors.credit_granting_ins : ""} */}
                                  {errors && dualEnrolment.credit_granting_ins === "" ? errors.credit_granting_ins : ""}
                                </span>
                              </div>
                              <div className="r-title-div col-lg-6">
                                <h3>
                                  How many college credits is it worth ?
                                  <span className="start-color">*</span>
                                </h3>
                                <div>
                                  <input
                                    ref={noOfCollegeWorthRef}
                                    type="text"
                                    placeholder=""
                                    value={dualEnrolment.no_of_college_worth}
                                    onChange={(e) =>
                                      setDualEnrolment({
                                        ...dualEnrolment,
                                        no_of_college_worth: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <span className="error-span">
                                  {/* {errors ? errors.no_of_college_worth : ""} */}
                                  {errors && (dualEnrolment.no_of_college_worth === "") ? errors.no_of_college_worth : ""}
                                  {errors && (dualEnrolment.no_of_college_worth < 0) ? "Negative number not allowed." : ""}
                                  {errors && isNaN(dualEnrolment.no_of_college_worth) ? "Please enter a valid number." : ""}


                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}

                    {parseInt(resourceType) === 6 && (
                      <div className="save-container-3">
                        <label className="save-type-of-r">
                          Type of Funding <span className="start-color">*</span>{" "}
                          <span className="select-one"> (select one)</span>
                        </label>

                        <select
                          className="form-select f-select"
                          aria-label="Default select example"
                          name="fundingType"
                          onChange={handleInput}
                          value={fundingType}
                          ref={inputRefType}
                        >
                          <option value="" className="select-val">
                            Select Funding
                          </option>
                          {fundingData.length > 0 &&
                            fundingData?.map((val, i) => {
                              return (
                                <option value={val.id} key={i + 1}>
                                  {val.name}
                                </option>
                              );
                            })}
                        </select>
                        <span className="error-span">
                          {(errors && fundingType.length <= 0) ? errors.fundingType : ""}
                        </span>
                      </div>
                    )}


                    {parseInt(resourceType) === 1 && (
                      <div className="save-container-3">
                        <label className="save-type-of-r">
                          Type of Organization <span className="start-color">*</span>{" "}
                          <span className="select-one"> (select one)</span>
                        </label>

                        <select
                          className="form-select f-select"
                          aria-label="Default select example"
                          name="organizationType"
                          onChange={handleInput}
                          value={organizationType}
                          ref={inputRefType}
                        >
                          <option value="" className="select-val">
                            Select Organization
                          </option>
                          {organizationData.length > 0 &&
                            organizationData?.map((val, i) => {
                              return (
                                <option value={val.id} key={i + 1}>
                                  {val.name}
                                </option>
                              );
                            })}
                        </select>
                        <span className="error-span">
                          {(errors && organizationType.length <= 0) ? errors.organizationType : ""}
                        </span>
                        <div>
                          <h5>
                            Location<span className="start-color">*</span>{" "}
                            <span className="select-one"> (select one)</span>
                          </h5>
                          <div className="radiosdiv">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="html"
                              name="location_type"
                              value="Online"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setLocationType([...locationType, e.target.value])
                                } else {
                                  setLocationType(locationType.filter(item => item !== e.target.value));
                                }
                              }}
                              checked={locationType.includes("Online")}
                              ref={inputLocationType}
                            />
                            <label htmlFor="html">Online</label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="css"
                              name="location_type1"
                              value="In Person"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setLocationType([...locationType, e.target.value])
                                } else {
                                  setLocationType(locationType.filter(item => item !== e.target.value));
                                }
                              }}
                              checked={locationType.includes("In Person")}
                            />
                            <label htmlFor="css">In Person</label>
                          </div>
                          <span className="error-span">
                            {(errors && locationType.length <= 0) ? errors.locationType : ""}
                          </span>
                        </div>
                        <div className="row">
                          <div
                            className={`${locationType.includes("In Person")
                              ? "r-title-div col-lg-6"
                              : "r-title-div col-lg-12"
                              }`}
                          >
                            <h3>
                              Define the Location of the Event
                            </h3>
                            <div>
                              <input
                                className="locationDefine"
                                type="text"
                                placeholder="Enter address"
                                value={address1 ? address1 : ""}
                                name="address1"
                                onChange={handleInput}
                                ref={inputRefLocation}
                              />
                            </div>
                            <span className="error-span">
                            </span>
                          </div>
                          {locationType.includes("In Person") && (
                    <div className="r-title-div zipcodeaddress col-lg-6">
                      <h3>Zip Code<span className="start-color">*</span></h3>
                      <div>
                        <input
                          className=""
                          type="text"
                          placeholder="Enter address"
                          value={locationState1.zipcode}
                          name="zipcode"
                          onChange={handleInput}
                          ref={inputRefZipcode}
                        />
                         <div className="facustomeI">i<div className="tootlippillerinfo">
                  For users outside the U.S. and Canada: If you don't have a zip code,
                   please enter '111111' to continue.
                    This will help us gather location data from the city and state you provide.
                  </div></div>
                      </div>
                      <span className="error-span">
                        {(errors && !Zipcode.test(locationState1.zipcode.replace(/\s/g, ""))) ? errors.locationState1 : ""}
                      </span>
                    </div>
                  )}
                        </div>
                      </div>
                    )}


                    {parseInt(resourceType) === 7 && (
                      <div className="save-container-3">
                        <label className="save-type-of-r">
                          Type of Facility <span className="start-color">*</span>{" "}
                          <span className="select-one"> (select one)</span>
                        </label>
                        <select
                          className="form-select f-select"
                          aria-label="Default select example"
                          name="facilityType"
                          onChange={handleInput}
                          value={facilityType}
                          ref={inputFacilityType}
                        >
                          <option value="" className="select-val">
                            Select Facility
                          </option>
                          {facilityData?.length > 0 &&
                            facilityData?.map((val, i) => {
                              return (
                                <option value={val.id} key={i + 1}>
                                  {val.name}
                                </option>
                              );
                            })}
                        </select>
                        <span className="error-span">
                          {(errors && facilityType.length <= 0) ? errors.facilityType : ""}
                        </span>
                        <div>
                          <h5>
                            Location<span className="start-color">*</span>{" "}
                            <span className="select-one"> (select one)</span>
                          </h5>
                          <div className="radiosdiv">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="html"
                              name="location_type"
                              value="Online"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setLocationType([...locationType, e.target.value])
                                } else {
                                  setLocationType(locationType.filter(item => item !== e.target.value));
                                }
                              }}
                              checked={locationType.includes("Online")}
                              ref={inputLocationType}
                            />
                            <label htmlFor="html">Online</label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="css"
                              name="location_type1"
                              value="In Person"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setLocationType([...locationType, e.target.value])
                                } else {
                                  setLocationType(locationType.filter(item => item !== e.target.value));
                                }
                              }}
                              checked={locationType.includes("In Person")}
                            />
                            <label htmlFor="css">In Person</label>
                          </div>
                          <span className="error-span">
                            {(errors && locationType.length <= 0) ? errors.locationType : ""}
                          </span>
                        </div>
                        <div className="row">
                          <div
                            className={`${locationType.includes("In Person")
                              ? "r-title-div col-lg-6"
                              : "r-title-div col-lg-12"
                              }`}
                          >
                            <h3>
                              Define the Location of the Event
                            </h3>
                            <div>
                              <input
                                className="locationDefine"
                                type="text"
                                placeholder="Enter address"
                                value={address1 ? address1 : ""}
                                name="address1"
                                onChange={handleInput}
                                ref={inputRefLocation}
                              />
                            </div>
                            <span className="error-span">
                            </span>
                          </div>
                          {locationType.includes("In Person") && (
                    <div className="r-title-div zipcodeaddress col-lg-6">
                      <h3>Zip Code<span className="start-color">*</span></h3>
                      <div>
                        <input
                          className=""
                          type="text"
                          placeholder="Enter address"
                          value={locationState1.zipcode}
                          name="zipcode"
                          onChange={handleInput}
                          ref={inputRefZipcode}
                        />
                         <div className="facustomeI">i<div className="tootlippillerinfo">
                  For users outside the U.S. and Canada: If you don't have a zip code,
                   please enter '111111' to continue.
                    This will help us gather location data from the city and state you provide.
                  </div></div>
                      </div>
                      <span className="error-span">
                        {(errors && !Zipcode.test(locationState1.zipcode.replace(/\s/g, ""))) ? errors.locationState1 : ""}
                      </span>
                    </div>
                  )}
                        </div>
                      </div>
                    )}



                    {parseInt(resourceType) === 3 && (
                      <div className="save-container-3">
                        <div className="typeofexprience">
                          <label className="save-type-of-r">
                            Type of Experience <span className="start-color">*</span>{" "}
                            <span className="select-one"> (select one)</span>
                          </label>

                          <select
                            className="form-select f-select"
                            aria-label="Default select example"
                            name="experienceType"
                            onChange={handleInput}
                            value={experienceType}
                            ref={inputRefExpType}
                          >
                            <option value="" className="select-val">
                              Select experience
                            </option>
                            {experienceData.length > 0 &&
                              experienceData?.map((val, i) => {
                                return (
                                  <option value={val.id} key={i + 1}>
                                    {val.name}
                                  </option>
                                );
                              })}
                          </select>
                          <span className="error-span">
                            {(errors && experienceType.length <= 0) ? errors.experienceType : ""}
                          </span>
                        </div>
                        <div className="row ">
                          <div className="r-title-div defineLocation col-lg-6">
                            <h3 className="pt-3">
                              Define the Location of the Event
                            </h3>
                            <div className="">
                              <input
                                className=""
                                type="text"
                                placeholder="Enter address"
                                value={address1 ? address1 : ""}
                                name="address1"
                                onChange={handleInput}
                                ref={inputRefLocation}
                              />
                            </div>
                            <span className="clearnone"></span>
                            <span className="error-span pb-3">
                            </span>
                          </div>
                          <div className="r-title-div zipcodeaddress zipaddcode col-lg-6">
                    <h3 className="pt-3">Zip Code <span className="start-color">*</span></h3>
                    <div>
                      <input
                        className=""
                        type="text"
                        placeholder="Enter address"
                        value={locationState1.zipcode}
                        name="zipcode"
                        onChange={handleInput}
                        ref={inputRefZipcode}
                      />
                       <div className="facustomeI">i<div className="tootlippillerinfo">
                  For users outside the U.S. and Canada: If you don't have a zip code,
                   please enter '111111' to continue.
                    This will help us gather location data from the city and state you provide.
                  </div></div>
                    </div>
                    <span className="error-span">
                      {(errors && !Zipcode.test(locationState1.zipcode.replace(/\s/g, ""))) ? errors.locationState1 : ""}
                    </span>
                  </div>
                        </div>
                        Note: For recurring events or sign-up options, use our Scheduling Feature
                      </div>
                    )}



                    {parseInt(resourceType) === 5 && (
                      <div className="save-container-3">
                        <h5>
                          Customer Type<span className="start-color">*</span>{" "}
                          <span className="select-one"> (select one)</span>
                        </h5>
                        <div className="time-fram-div">
                          <div ref={customerFocus}
                            className={` ${customerTypeActive1 ? "active" : ""}`}
                            onClick={() => {
                              handleCustomerType("Teacher", "1");
                            }}
                          >
                            <h3 className="savetext1">Teacher</h3>
                          </div>
                          <div
                            className={` ${customerTypeActive2 ? "active" : ""}`}
                            onClick={() => {
                              handleCustomerType("Parent", "2");
                            }}
                          >
                            <h3 className="savetext1">Parent</h3>
                          </div>
                          <div
                            className={` ${customerTypeActive3 ? "active" : ""}`}
                            onClick={() => {
                              handleCustomerType("Other", "3");
                            }}
                          >
                            <h3 className="savetext1">Other</h3>
                          </div>
                        </div>
                        <div className="clearnone"></div>
                        <span className="error-span">
                          {(errors && customerType.length <= 0) ? errors.customerType : ""}
                        </span>
                        <div className="mul-sel-div">
                          <h3>
                            Type of Support <span className="start-color">*</span>{" "}
                            <span className="select-one">
                              {" "}
                              (select one or multiple)
                            </span>
                          </h3>
                          <div>
                            <Select
                              value={supportTypes}
                              options={supportTypeData}
                              isMulti
                              selectMultiple={true}
                              onChange={(supportVal) => setSupportTypes(supportVal)}
                              ref={inputRefType}
                            />
                            <span className="error-span">
                              {(errors && supportTypes <= 0) ? errors.supportTypes : ""}
                            </span>
                          </div>
                        </div>
                        <div>
                          <h5>
                            Location<span className="start-color">*</span>{" "}
                            <span className="select-one"> (select one)</span>
                          </h5>

                          <div className="radiosdiv">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="html"
                              name="location_type"
                              value="Online"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setLocationType([...locationType, e.target.value])
                                } else {
                                  setLocationType(locationType.filter(item => item !== e.target.value));
                                }
                              }}
                              checked={locationType.includes("Online")}
                              ref={inputLocationType}
                            />
                            <label htmlFor="html">Online</label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="css"
                              name="location_type1"
                              value="In Person"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setLocationType([...locationType, e.target.value])
                                } else {
                                  setLocationType(locationType.filter(item => item !== e.target.value));
                                }
                              }}
                              checked={locationType.includes("In Person")}
                            />
                            <label htmlFor="css">In Person</label>
                          </div>
                          <span className="error-span">
                            {(errors && locationType.length <= 0) ? errors.locationType : ""}
                          </span>
                        </div>
                        <div className="row">
                          <div
                            className={`${locationType.includes("In Person")
                              ? "r-title-div col-lg-6"
                              : "r-title-div col-lg-12"
                              }`}
                          >
                            <h3>
                              Define the Location of the Event
                            </h3>
                            <div>
                              <input
                                className="locationDefine"
                                type="text"
                                placeholder="Enter address"
                                value={address1 ? address1 : ""}
                                name="address1"
                                onChange={handleInput}
                                ref={inputRefLocation}
                              />
                            </div>
                            <span className="error-span">
                            </span>
                          </div>
                          {locationType.includes("In Person") && (
                    <div className="r-title-div zipcodeaddress col-lg-6">
                      <h3>Zip Code<span className="start-color">*</span></h3>
                      <div>
                        <input
                          className=""
                          type="text"
                          placeholder="Enter address"
                          value={locationState1.zipcode}
                          name="zipcode"
                          onChange={handleInput}
                          ref={inputRefZipcode}
                        />
                         <div className="facustomeI">i<div className="tootlippillerinfo">
                  For users outside the U.S. and Canada: If you don't have a zip code,
                   please enter '111111' to continue.
                    This will help us gather location data from the city and state you provide.
                  </div></div>
                      </div>
                      <span className="error-span">
                        {(errors && !Zipcode.test(locationState1.zipcode.replace(/\s/g, ""))) ? errors.locationState1 : ""}
                      </span>
                    </div>
                  )}
                        </div>
                      </div>
                    )}


                    {parseInt(resourceType) === 4 && (
                      <div className="save-container-3">
                        <div className="mul-sel-div">
                          <h3>
                            Type of Support <span className="start-color">*</span>{" "}
                            <span className="select-one">
                              {" "}
                              (select one or multiple)
                            </span>
                          </h3>
                          <div>
                            <Select
                              value={supportTypes}
                              options={supportTypeData}
                              isMulti
                              selectMultiple={true}
                              onChange={(supportVal) => setSupportTypes(supportVal)}
                              ref={inputRefType}
                            />
                            <span className="error-span">
                              {(errors && supportTypes <= 0) ? errors.supportTypes : ""}
                            </span>
                          </div>
                        </div>
                        {supportTypes &&
                          supportTypes.length > 0 &&
                          supportTypes.some((el) => el.value === "other") == true && (
                            <div className="r-title-div">
                              <h3>
                                Other<span className="start-color">*</span>
                                <span className="select-one">Type of Support</span>
                              </h3>
                              <div>
                                <input
                                  type="text"
                                  placeholder="Give your Support Type"
                                  value={otherSupport ? otherSupport : ""}
                                  onChange={(e) => {
                                    setOtherSupport(e.target.value);

                                  }}
                                  ref={inputRefType}
                                />
                              </div>
                              <span className="error-span">
                                {(errors && otherSupport.length <= 0) ? errors.otherSupport : ""}
                              </span>
                            </div>
                          )}
                        <div>
                          <h5>
                            Location<span className="start-color">*</span>{" "}
                            <span className="select-one"> (select one)</span>
                          </h5>
                          <div className="radiosdiv">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="html"
                              name="location_type"
                              value="Online"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setLocationType([...locationType, e.target.value])
                                } else {
                                  setLocationType(locationType.filter(item => item !== e.target.value));
                                }
                              }}
                              checked={locationType.includes("Online")}
                              ref={inputLocationType}
                            />
                            <label htmlFor="html">Online</label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="css"
                              name="location_type1"
                              value="In Person"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setLocationType([...locationType, e.target.value])
                                } else {
                                  setLocationType(locationType.filter(item => item !== e.target.value));
                                }
                              }}
                              checked={locationType.includes("In Person")}
                            />
                            <label htmlFor="css">In Person</label>
                          </div>
                          <span className="error-span">
                            {(errors && locationType.length <= 0) ? errors.locationType : ""}
                          </span>
                        </div>
                        <div className="row">
                          <div
                            className={`${locationType.includes("In Person")
                              ? "r-title-div col-lg-6"
                              : "r-title-div col-lg-12"
                              }`}
                          >
                            <h3>
                              Define the Location of the Event
                            </h3>
                            <div>
                              <input
                                className="locationDefine"
                                type="text"
                                placeholder="Enter address"
                                value={address1 ? address1 : ""}
                                name="address1"
                                onChange={handleInput}
                                ref={inputRefLocation}
                              />
                            </div>
                            <span className="error-span">
                            </span>
                          </div>
                          {locationType?.includes("In Person") && (
                    <div className="r-title-div zipcodeaddress col-lg-6">
                      <h3>Zip Code<span className="start-color">*</span></h3>
                      <div>
                        <input
                          className=""
                          type="text"
                          placeholder="Enter address"
                          value={locationState1.zipcode}
                          name="zipcode"
                          onChange={handleInput}
                          ref={inputRefZipcode}
                        />
                         <div className="facustomeI">i<div className="tootlippillerinfo">
                  For users outside the U.S. and Canada: If you don't have a zip code,
                   please enter '111111' to continue.
                    This will help us gather location data from the city and state you provide.
                  </div></div>
                      </div>
                      <span className="error-span">
                        {(errors && !Zipcode.test(locationState1.zipcode.replace(/\s/g, ""))) ? errors.locationState1 : ""}
                      </span>
                    </div>
                  )}
                        </div>
                      </div>
                    )}
                  </div>


                  <div className="clearnone"></div>
                  <div className="resource-url-div">

                    {parseInt(resourceType) === 2 && (
                      <div className="first-div1">
                        <h3>ISBN Number</h3>
                        <div className="w-100">
                          <input
                            type="text"
                            name="isbn_number"
                            value={state.isbn_number}
                            onChange={handleInput}
                            placeholder="ISBN Number"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="r-title-div mb-4">
                    <h3>
                      Resource Title<span className="start-color">*</span>
                    </h3>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter the title"
                        name="resource_title"
                        value={state.resource_title}
                        onChange={handleInput}
                        ref={inputRef3}
                      />
                    </div>
                    <span className="error-span">
                      {errors ? errors.resource_title : ""}
                    </span>
                  </div>
                  <div className="mul-sel-div">
                    <h3>
                      Learning Pillars{" "}
                      <span className="select-one">&nbsp;(select one or multiple)</span>
                    </h3>
                    <div>
                      <Select
                        value={
                          learningPillarIds && learningPillarIds.length > 0
                            ? learningPillarIds
                            : null
                        }
                        options={learningPillarData}
                        isMulti
                        selectMultiple={true}
                        onChange={handleLearningPillar}
                      />
                    </div>
                  </div>


                  {/* {resourceMode === "serve" ? "" :<>
                  <div className="save-radio-div3">
                    <h3>
                      Save for <span className="select-one">select one </span>
                    </h3>
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        id="a"
                        name="fav_language"
                        value="Past"
                        onChange={(e) => {
                          setSaveFor(e.target.value);
                        }}
                        checked={saveFor === "Past"}
                      />
                      <label for="a">Past</label>

                      <input
                        className="form-check-input"
                        type="radio"
                        id="b"
                        name="fav_language"
                        value="In progress"
                        onChange={(e) => {
                          setSaveFor(e.target.value);
                        }}
                        checked={saveFor === "In progress"}
                      />
                      <label for="b">In Progress</label>

                      <input
                        className="form-check-input"
                        type="radio"
                        id="c"
                        name="fav_language"
                        value="For the future"
                        onChange={(e) => {
                          setSaveFor(e.target.value);
                        }}
                        checked={saveFor === "For the future"}
                      />
                      <label for="c">For the Future</label>
                    </div>
                  </div>

</>} */}

                  <div className="mul-sel-div">
                    <h3>Who would you like to share this resource with? <span className="start-color">*</span>
                      <span className="select-one">
                        <div className="facustomeI">i<div className="tootlippillerinfo">
                          To make this resource searchable by the entire MatchED community, please choose "All MatchED." If you would like to share it with specific friends or groups, select "Friends/Groups," and then specify the particular friends or groups with whom you'd like to share it. Opting for "Local MatchED Community" will display it solely for users in your vicinity, and selecting "Private" will restrict this resource to your personal view only.
                        </div></div></span>
                    </h3>

                    <div>
                      <Select
                        value={
                          shareResourceSelectedData ? shareResourceSelectedData : null
                        }
                        options={shareResourceOptions}
                        isMulti
                        selectMultiple={true}
                        onChange={handleShareResource}
                        ref={shareResFocus}
                      />
                      <span className="error-span" >
                        {(errors && shareResourceSelectedData <= 0) ? errors.shareResourceError : ""}
                      </span>
                      <span className="error-span">
                        {
                          message.share && "Note: If you select private you will not be able to share the resource."
                        }
                      </span>
                    </div>
                  </div>

                  <div className="clearnone"></div>
                  {shareResource?.includes("friend") && (
                    <div className="mul-sel-div">
                      <h3>Select the friends you would like to share this with:</h3>
                      <div>
                        <Select
                          value={friendData ? friendData : null}
                          options={friendList}
                          isMulti
                          selectMultiple={true}
                          onChange={handleFriendsId}
                        />
                      </div>
                      <span className="error-span">
                        {
                          message.friend && "Note: Now you can not to select any more friends"
                        }
                      </span>
                    </div>
                  )}
                  {shareResource?.includes("group") && (
                    <div className="mul-sel-div">
                      <h3>Select the groups you would like to share this with:</h3>
                      <div>
                        <Select
                          value={groupData ? groupData : null}
                          options={groupList}
                          isMulti
                          selectMultiple={true}
                          onChange={handleGroupId}
                        />
                      </div>
                      <span className="error-span">
                        {
                          message.group && "Note: Now you can not able to select any Groups"
                        }
                      </span>
                    </div>
                  )}


                  {groupData?.length > 0 && (
                    <div className="mul-sel-div">

                      {groupData.some(val => val.type === "Admin") && (
                        <thead>
                          <tr>
                            <th scope="col" style={{ border: "none" }}>Group Name</th>
                            <th scope="col" style={{ border: "none" }}>Add To Bulletin Board</th>
                            <th scope="col" style={{ border: "none" }}>Add To Resource</th>
                          </tr>
                        </thead>
                      )}

                      {groupData.map((val, i) => {
                        if (val.type !== "Admin") return null;

                        return (
                          <tr key={i}>
                            <td style={{ border: "none" }}>
                              <p className="share-with-group m-0">{val.label}</p>
                            </td>
                            <td style={{ border: "none" }}>
                              <label className="switch mx-0">
                                <input
                                  type="checkbox"
                                  onChange={(e) => handleToggleChange(i, e)}
                                  checked={!!val?.check}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td style={{ border: "none" }}>
                              <label className="switch mx-0">
                                <input
                                  type="checkbox"
                                  onChange={(e) => resourceHandleToggleChange(i, e)}
                                  checked={!!val?.resourceCheck}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                          </tr>
                        );
                      })}
                    </div>
                  )}

                  <div className="clearnone"></div>

                  {shareResource?.includes("local") && (
                    <div className="loc-div mt-3">
                      <div className="r-title-div locationcomunity">
                        <h3>
                           {/* Define the <b>local matched community</b> for sharing  */}
                          Enter the zip code of the local MatchED community you would like to share this with.
                          <span className="start-color">*</span>
                        </h3>
                        <div>
                          <input
                            className="locationDefine"
                            type="text"
                            name="zipcode1"
                            placeholder="Enter zip code"
                            value={locationState.zipcode ? locationState.zipcode : ""}
                            onChange={handleInput}
                            ref={inputRef}
                          />
                           <div className="facustomeI">i<div className="tootlippillerinfo">
                  For users outside the U.S. and Canada: If you don't have a zip code,
                   please enter '111111' to continue.
                    This will help us gather location data from the city and state you provide.
                  </div></div>
                        </div>
                        <span className="error-span">
                    {(errors && locationState.zipcode?.length <= 0) ? errors.address : ""}
                    {(errors && (locationState.zipcode && !Zipcode.test(locationState.zipcode.replace(/\s/g, "")))) ? "Invalid Zip Code." : ""}
                  </span>
                      </div>
                    </div>
                  )}



                  <div className="texteditordescription scrolldescription">
                    {resourceMode === "serve" ? <h3>Description Summary</h3> : <h3>Description</h3>}
                    <JoditEditor
                      config={editorConfig}
                      value={description}
                      onChange={(newContent) => setDescription(newContent)}
                    />
                  </div>


                  <div className="texteditordescription scrolldescription">
                    <h3>About this Resource</h3>
                    <JoditEditor
                      config={editorConfig}
                      value={aboutResource}
                      onChange={(newContent) => setAboutResource(newContent)}
                    />
                  </div>

                  <div className="save-container-2  ">
                    <h3 className="affilation">How would you like users to engage with your resource?</h3>
                    <div className="radiosdiv radiosdivcheckboxouter">
                      <ul className="engagelist">
                        <li>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="html1"
                            name="affiliate_purchase_button"
                            value={affiliateButton?.affiliate_purchase_button}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAffiliateButton({ ...affiliateButton, [e.target.name]: 1 })
                              } else {
                                setAffiliateButton({ ...affiliateButton, [e.target.name]: 0 })
                                state.resourceurl = "";
                              }
                            }}
                            checked={affiliateButton?.affiliate_purchase_button === 1}
                            ref={inputAffiliate}
                          />
                          <label htmlFor="html1">Purchase</label>
                          <div className="facustomeI">i
                            <div className="tootlippillerinfo">
                              <p><span className="span-dark"></span> Enabling "Purchase" will allow Members to purchase this resource through a website you provide or from you directly through MatchED.<br />
                              </p>
                            </div>
                          </div>
                        </li>

                        {
                          (parseInt(resourceType) === 3 || parseInt(resourceType) === 7 || parseInt(resourceType) === 4 || parseInt(resourceType) === 5) &&

                          <li>


                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="signup"
                              name="affiliate_signup_button"
                              value={affiliateButton?.affiliate_signup_button}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setAffiliateButton({ ...affiliateButton, [e.target.name]: 1 })
                                } else {
                                  setAffiliateButton({ ...affiliateButton, [e.target.name]: 0 })
                                  state.resourceurl = "";
                                }
                              }}
                              checked={affiliateButton?.affiliate_signup_button === 1}
                              ref={inputAffiliate}
                            />

                            <label htmlFor="signup">Sign Up </label>
                            <div className="facustomeI">i
                              <div className="tootlippillerinfo">
                                <p><span className="span-dark"></span> Enabling "Sign Up" will allow MatchED Members to sign up for this resource through MatchED's Scheduling feature.  You will be notified every time someone signs up.<br />
                                  Please make sure to set up your schedule when you publish your resource.
                                </p>
                              </div>
                            </div>

                          </li>
                        }

                        <li>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="css1"
                            name="affiliate_enroll_button"
                            value={affiliateButton?.affiliate_enroll_button}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAffiliateButton({ ...affiliateButton, [e.target.name]: 1 })
                              } else {
                                setAffiliateButton({ ...affiliateButton, [e.target.name]: 0 })
                              }
                            }}
                            checked={affiliateButton?.affiliate_enroll_button === 1}
                            ref={inputAffiliate}
                          />
                          <label htmlFor="css1">Enroll  </label>
                          <div className="facustomeI">i
                            <div className="tootlippillerinfo">
                              <p><span className="span-dark"></span>Enabling "Enroll" will automatically send you (or your designee) an email alerting you of a MatchED member that would like to enroll in this resource.<br />
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="csss"
                            name="affiliate_add_info_button"
                            value={affiliateButton.affiliate_add_info_button}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAffiliateButton({ ...affiliateButton, [e.target.name]: 1 })
                              } else {
                                setAffiliateButton({ ...affiliateButton, [e.target.name]: 0 })
                              }
                            }}
                            checked={affiliateButton.affiliate_add_info_button === 1}
                            ref={inputAffiliate}
                          />
                          <label htmlFor="csss">Get More Info</label>
                          <div className="facustomeI">i
                            <div className="tootlippillerinfo">
                              <p><span className="span-dark"></span> Enabling "Get More Info" will allow MatchED members to start a Chat with you to ask you for more information about this resource.<br />
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <span className="error-span">
              {(errors && (affiliateButton.affiliate_add_info_button === 0 && affiliateButton?.affiliate_enroll_button === 0 && affiliateButton?.affiliate_signup_button === 0 && affiliateButton?.affiliate_purchase_button === 0)) ? errors.choose_anyone : ""}
            </span> */}


                  {(affiliateButton?.affiliate_purchase_button === 1) && (
                    <div className="save-container-2 wouldlike" >
                      <div className="texteditordescription ">
                        <h3>Where will they purchase this resource?
                          {/* <span className="start-color">*</span>
                          <span className="select-one">
                            {" "}
                            (Select atleast one )
                          </span> */}
                          </h3>
                        <div className="radiosdiv flexreadios">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="htmlc"
                            name="Website URL"
                            value="no"
                            onChange={(e) => setPurchaseRes(e.target.value)}
                            checked={purchaseRes === "no"}
                          />
                          <label htmlFor="htmlc">Website URL</label>
                          <div className="facustomeI">
                            i
                            <div className="tootlippillerinfo">
                              <p>Using this feature requires an Affiliate Partnership agreement with MatchED. Please contact MatchED here to request this agreement. Thank you!
                              </p>
                            </div>
                          </div>

{
  // First condition for 'individual' status with US or CA country
  ((groupStatusforPayload === "individual" && (countryName === "US" || countryName === "CA")) || 
  // Second condition for 'group' status with US or CA country
  (groupStatusforPayload === "group" && (groupCountryName === "US" || groupCountryName === "CA")))
  // If one of the above conditions is true, we check if zip code is !'111111'
  && !(zipCodeChangeData1 === "111111" || zipCodeChangeData2 === "111111")
  ? (
    <div>
      <input
        className="form-check-input"
        type="radio"
        id="cssa"
        name="Website URL"
        value="yes"
        onChange={(e) => {
          setPurchaseRes(e.target.value);
          state.resourceurl = "";
        }}
        checked={purchaseRes === "yes"}
      />
      <label htmlFor="cssa">Purchase from me through MatchED</label>
      <div className="facustomeI">
        i
        <div className="tootlippillerinfo">
          <p>The price you list will be the total price your customer pays excluding any taxes. We will deduct a 10% transaction fee plus a 3% processing fee from this total amount. You will be paid the remaining amount.</p>
        </div>
      </div>
    </div>
  ) : ""
}
                        </div>
                        <span className="error-span">
                          {(errors && purchaseRes == "") ? errors.Purchase_res : ""}
                        </span>
                        {purchaseRes === "no" ? (
                          <div
                            className="first-div1 w-100"
                          >
                            <h3>
                              Resource URL{" "}
                              <span className="start-color">*</span>
                              <span className="select-one">
                                {" "}
                                (if hosted on separate website)
                              </span>
                            </h3>
                            <div className="w-100">
                              <input
                                type="text"
                                name="resourceurl"
                                value={state.resourceurl}
                                onChange={handleInput}
                                placeholder="Example: https://www.example.com"
                                ref={resourceUrl}
                              />
                            </div>

                            <span className="error-span">
                              {errors ? errors.resourceurl : ""}
                            </span>
                            <span className="error-span">
                              {(errors && !state.resourceurl) ? errors.res_url : ""}
                            </span>
                            <p className="mt-1"> Note: Only https URLs are accepted </p>
                          </div>
                        ) : <>
                          <div className="r-title-div">
                            <h3>
                              Purchase Price ($)
                              <span className="start-color">*</span>
                            </h3>
                            <div className="margin-bt">
                              <input
                                type="text"
                                placeholder="Purchase price"
                                name="purchase_price"
                                value={state.purchase_price ? "$" + state.purchase_price : state.purchase_price}
                                onChange={handleInput}
                                ref={purchasePrciceFocus}
                              />
                            </div>
                            <span className="error-spans">
                              {errors ? errors.purchase_price : ""}
                              {/* {errors && (state.purchase_price === "") ? errors.purchase_price : ""}
{errors && (isNaN(state.purchase_price)) ? "Please enter number." : ""}
{errors && (state.purchase_price < 0) ? "Negative number is not allowed." : ""}
 {errors && (state.purchase_price == 0) ? "Purchase price can't be 0." : ""}  */}

                            </span>
                          </div>
                          <div className="clearnone"></div>
                        </>
                        }
                        <p>{purchaseRes === "yes" ? "" : ""}</p>

                      </div>
                    </div>
                  )}



                  {(affiliateButton?.affiliate_enroll_button === 1) && (
                    <div className="mul-sel-div engagemobilelist">

                      <div className="texteditordescription ">
                        <h3> Add the email address to be contacted for Members who want to Enroll in your resource.<span className="start-color">*</span></h3>
                        <input className="selectcontent" onChange={(e) => setEmailId(e.target.value)}
                          ref={emailIdFocus} value={emailId} />
                      </div>
                      <span className="error-span">
                        {errors && (emailId == "" || emailId == null) ? errors.emailId : emailErr !== "" ? (
                          emailErr
                        ) : ""}
                      </span>
                    </div>
                  )}
                  {/* {(affiliateButton.affiliate_add_info_button === 1) && (
              <div className="mul-sel-div engagemobilelist">
                <div className="texteditordescription">
                  <h3>Introduction message on initiation of chat</h3>
                  <textarea type="text" className="Text_box" onChange={(e) => setSendMsg(e.target.value)} value={sendMsg} ></textarea>
                </div>
              </div>
            )} */}
                  {(affiliateButton.affiliate_add_info_button === 1) && (
                    <div className="engagemobilelist">
                      {
                        groupResource && <>
                          <h3>Point of contact for chat </h3>
                          <div>
                            <Select
                              value={showAdmin ? showAdmin : null}
                              options={groupAdminList}
                              selectMultiple={false}
                              onChange={handleAdminOption}
                            />
                          </div>
                          <span className="error-span py-2">
                          </span></>
                      }
                      {/* <div className="texteditordescription">
                    <h3>Introduction message on initiation of chat</h3>
                    <textarea type="text" className="Text_box" onChange={(e) => setSendMsg(e.target.value)} value={sendMsg} ></textarea>
                  </div> */}
                    </div>
                  )}




                  {addMore && <>

                    <div className="clearnone"></div>
                    <div className="public-funding pt-2">
                      <label className="save-type-of-r">
                        Eligible for Public Funding? {" "}
                        <span className="select-one"> (select one)</span>
                      </label>
                      <select
                        className="form-select f-select"
                        aria-label="Default select example"
                        name="public_funding"
                        value={state.public_funding}
                        onChange={handleInput}
                      >
                        <option value="" className="select-val">
                          Select
                        </option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                      {/* <span className="error-span">
                      {errors ? errors.public_funding : ""}
                    </span> */}
                    </div>
                    {state.public_funding === "1" && (
                      <div className="artifact">
                        <h3>
                          Public funding approved states{" "}<span className="start-color">*</span>
                          <span className="select-one">(select all known states)</span>{" "}
                        </h3>
                        <Select
                          value={
                            approvedStatesIds && approvedStatesIds.length > 0
                              ? approvedStatesIds
                              : null
                          }
                          onChange={handleApprovedStates}
                          options={statesData}
                          isMulti
                          selectMultiple={true}
                        />
                        <span className="error-span">
                          {errors ? errors.approvedStatesIds : ""}
                        </span>
                      </div>
                    )}


                    {resourceMode === "serve" ?
                      <div className="texteditordescription mb-4">
                        <h3>About the Instructor</h3>
                        <JoditEditor
                          config={editorConfig}
                          value={aboutInstructor}
                          onBlur={(newContent) => setAboutInstructor(newContent)}
                        />
                      </div>
                      : ""}
                    {resourceMode === "serve" ?
                      <div className="texteditordescription mb-4">
                        <h3>Requirements/Tools</h3>
                        <JoditEditor
                          config={editorConfig}
                          value={requirementsTool}
                          onBlur={(newContent) => setRequirementsTool(newContent)}
                        />
                      </div>
                      : ""}




                    {resourceMode === "serve" ? "" : <>
                      <div className="leavfeedbackreviews">
                        <h4>Rating</h4>
                        <div className="innerBoxList innerBoxListRating">
                          <ul className="unstyled centered">
                            <li>
                              <input
                                className="styled-checkbox"
                                id="styled-checkbox-0"
                                type="checkbox"
                                checked={isParentChecked ? true : false}
                                value={isParentChecked}
                                onChange={(e) => {
                                  handleParentCheckbox(e);
                                }}
                              />
                              <label htmlFor="styled-checkbox-0">
                                &nbsp;{userDtl}
                                <span className="rankingStar mt-2 px-2 py-1">
                                  <Rating
                                    // disabled={`${isParentChecked ? "" : "disabled"}`}
                                    name="Rating Label0"
                                    value={parentRating}
                                    onChange={(event, newValue) => {
                                      setParentRating(newValue);
                                    }}
                                  />
                                </span>
                              </label>
                              <span className="error-span">
                                {errors ? errors.parentRating : ""}
                              </span>
                            </li>
                            {childData &&
                              childData.length > 0 &&
                              childData?.map((val, i) => {
                                return (
                                  <li key={i + 1}>
                                    <input
                                      className="styled-checkbox"
                                      id={`styled-checkbox-${i + 1}`}
                                      type="checkbox"
                                      value={val.id}
                                      checked={
                                        childRatingChekbox &&
                                          childRatingChekbox.length > 0 &&
                                          (childRatingChekbox.includes(val.id) ||
                                            childRatingChekbox.includes(
                                              val.id.toString()
                                            ))
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => handleChildRatingsCheckboxes(e)}
                                    />
                                    <label
                                      htmlFor={`styled-checkbox-${i + 1}`}
                                      className=""
                                    >
                                      &nbsp;{val.firstName}
                                      <span className="rankingStar mt-3 px-2 ">
                                        <Rating
                                          // disabled={`${childRatingChekbox &&
                                          //   childRatingChekbox.length > 0 &&
                                          //   (childRatingChekbox.includes(
                                          //     val.id.toString()
                                          //   ) == true ||
                                          //     childRatingChekbox.includes(val.id) ==
                                          //     true) &&
                                          //   parentRating
                                          //   ? ""
                                          //   : "disabled"
                                          //   }`}
                                          value={
                                            childsRating && childsRating.length > 0
                                              ? childsRating[i].rating
                                              : 0
                                          }
                                          name={`Rating Label${i + 1}`}
                                          onChange={(event, newValue) => {
                                            handleChildRatings(
                                              event,
                                              newValue,
                                              i,
                                              val.id
                                            );
                                          }}
                                        />
                                      </span>
                                    </label>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>


                    </>}
                    {/* <div className="leavfeedbackreviews">
                    <h4> Rankings</h4>
                    <div className="checkccc">
                      <ul className="unstyled centered">
                        <li>
                          <input
                            className="styled-checkbox"
                            id="styled-checkbox-0"
                            type="checkbox"
                            checked={isParentChecked ? true : false}
                            value={isParentChecked}
                            onChange={(e) => {
                              handleParentCheckbox(e);
                            }}
                          />
                          <label for="styled-checkbox-0">
                            &nbsp;Parent&nbsp;
                            <span className="rankingStar mt-2">
                              <Rating
                                disabled={`${isParentChecked ? "" : "disabled"}`}
                                name="Rating Label0"
                                value={parentRating}
                                onChange={(event, newValue) => {
                                  setParentRating(newValue);
                                }}
                              />
                            </span>
                          </label>
                          <span className="error-span">
                            {errors ? errors.parentRating : ""}
                          </span>
                        </li>
                        {childData &&
                          childData.length > 0 &&
                          childData.map((val, i) => {
                            return (
                              <li key={i + 1}>
                                <input
                                  className="styled-checkbox"
                                  id={`styled-checkbox-${i + 1}`}
                                  type="checkbox"
                                  value={val.id}
                                  checked={
                                    childRatingChekbox &&
                                      childRatingChekbox.length > 0 &&
                                      (childRatingChekbox.includes(val.id) ||
                                        childRatingChekbox.includes(
                                          val.id.toString()
                                        )) &&
                                      parentRating
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => handleChildRatingsCheckboxes(e)}
                                />
                                <label htmlFor={`styled-checkbox-${i + 1}`} className="">
                                &nbsp;{val.firstName}&nbsp;
                                  <span className="rankingStar mt-3 px-2 ">
                                    <Rating
                                      disabled={`${childRatingChekbox &&
                                        childRatingChekbox.length > 0 &&
                                        (childRatingChekbox.includes(
                                          val.id.toString()
                                        ) == true ||
                                          childRatingChekbox.includes(val.id) ==
                                          true) &&
                                        parentRating
                                        ? ""
                                        : "disabled"
                                        }`}
                                      value={
                                        childsRating && childsRating.length > 0
                                          ? childsRating[i].rating
                                          : 0
                                      }
                                      name={`Rating Label${i + 1}`}
                                      onChange={(event, newValue) => {
                                        handleChildRatings(
                                          event,
                                          newValue,
                                          i,
                                          val.id
                                        );
                                      }}
                                    />
                                  </span>
                                </label>
                              </li>
                            );
                          })}

                      </ul>
                    </div>
                  </div> */}

                    {resourceMode === "serve" ? "" : <>
                      <div className="about-div">
                        <h3>Comments</h3>
                        <div>
                          <textarea
                            name="comments"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </>}

                    <div className="r-title-div">
                      <h3>Upload Content</h3>
                      <div style={{ lineHeight: "0" }}>
                        <span className="u-content">
                          {" "}
                          <i className="fa fa-cloud-upload uploadicon"></i>
                          <span> Upload Content </span>
                        </span>
                        <input
                          type="file"
                          placeholder=""
                          // accept="image/*, video/*, audio/*, .pdf"
                          // multiple
                          className="input-file-upload"
                          onChange={handleFileUpload}
                        />
                      </div>
                      <p className="mt-1">Note: Only  png, jpeg, jpg, pdf, mpeg, mp4, mp3, avi, mov files are acceptable.</p>
                    </div>


                    <div className="uploadmaindiv">
                      {uploadContentName && uploadContentName.length > 0 && (
                        <div className="duplod">
                          <h4 className="dp">Documents Uploaded</h4>
                        </div>
                      )}
                      {uploadContentName &&
                        uploadContentName.length > 0 &&
                        uploadContentName.map((val, i) => {
                          return (
                            <div className="duplod1 p-2" key={i + 1}>
                              <h4 className="dp float-start">
                                <span className="one">{i + 1}</span>
                                {
                                  <button
                                    className="btn btn-md m-2"
                                    onClick={() => {
                                      showContentPreview(val.imgurl, val.imgType);
                                      window.scrollTo({
                                        bottom: 0,
                                        behavior: "smooth",
                                      });
                                      if (
                                        document.body.classList.contains("savepage")
                                      ) {
                                        document.body.classList.add("removescroll");
                                      } else {
                                        document.body.classList.remove("removescroll");
                                      }
                                    }}
                                  >
                                    {(val.imgType === "png" ||
                                      val.imgType === "jpg" ||
                                      val.imgType === "jpeg") && (
                                        <p>
                                          <img src={val.imgurl} className="image_pre" alt="uploded " style={{ width: "36px" }} />
                                        </p>
                                      )}
                                    {val.imgType === "pdf" && (
                                      <PictureAsPdfIcon style={{ fontSize: "30px", marginLeft: "4px" }} />
                                    )}
                                    {(val.imgType === "mp4" ||
                                      val.imgType === "mpeg") && (
                                        <video width="100%" height="20">
                                          <source
                                            src={val.imgurl}
                                            type={`video/${val.imgType}`}
                                          ></source>
                                        </video>
                                      )}{" "}
                                    {(val.imgType === "mov" || val?.imgType === "quicktime") && (

                                      <video src={val.imgurl} width="100%"
                                        height="20" />

                                    )}
                                    {val.imgType === "mp3" && (
                                      <AudioFileIcon style={{ fontSize: "30px", marginLeft: "4px" }} />
                                    )}
                                  </button>
                                }
                                <span style={{ marginLeft: "-20px", cursor: "pointer" }} onClick={() => {
                                  showContentPreview(val.imgurl, val.imgType);
                                  window.scrollTo({
                                    bottom: 0,
                                    behavior: "smooth",
                                  });
                                  if (
                                    document.body.classList.contains("savepage")
                                  ) {
                                    document.body.classList.add("removescroll");
                                  } else {
                                    document.body.classList.remove("removescroll");
                                  }
                                }}> {val.name.split('/').pop()} {val.size && " (Resize to: " + val?.size + ")"}</span>
                              </h4>
                              <div className="float-end">
                                <button
                                  className="btn btn-md px-0 mt-2 border-0"
                                  onClick={() => { val.type === "temp" ? removeImage(val.id) : removeUploadContent(val.id) }}
                                >
                                  <span className="ed">Remove</span>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <div className="r-title-div">
                      <h3>Add Link</h3>
                    </div>

                    {inputlist?.map((val, index) => {
                      return (
                        <div className="row">
                          <div className="col-lg-11 r-title-div mb-0"> <div>
                            <input
                              type="text"
                              name="Link"
                              placeholder="Example: https://www.example.com"
                              onChange={(event) =>
                                handleInputChange(index, event)}
                              value={val.Link}
                            />
                            <span className="error-span">{val?.error && "URL is not valid"}</span>
                          </div>

                            <p className="mb-2">Disclaimer: These links are not verified by MatchED.</p>


                          </div>
                          <div className="col-lg-1 ">
                            <div className="add">
                              {inputlist.length !== -1 && (
                                <>
                                  <button
                                    className="add-remove"
                                    onClick={(e) => handleremove(e, index)}
                                  >
                                    X
                                  </button>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="float-start">
                            {inputlist.length - 1 == index && (
                              <button className="add-add" onClick={handleadd}>
                                Add another link +
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}




                  </>}

                  <button className="add-more-information mt-3" onClick={() => { setAddMore(!addMore) }}>
                    {addMore ? " Hide information - " : "Add more information +"} </button>


                  <div className="publice-btn-save-p">
                    <button onClick={callFunctions}>Publish</button>
                  </div>
                  {isVisible && (
                    <div className="sharepopupOuter">
                      <div className="save-pop-up-container-inner">
                        <div className="pop-up-container">
                          <span
                            className="close-btn"
                            onClick={() => {
                              setVisible(false);
                              if (document.body.classList.contains("removescroll")) {
                                document.body.classList.remove("removescroll");
                              }
                            }}
                          >
                            <i className="fa fa-close closecs"></i>

                          </span>
                          <h3>Preview </h3>
                          <div className="input-popup2">
                            {contentPreview.imgurl &&
                              (contentPreview.imgtype === "png" ||
                                contentPreview.imgtype === "jpg" ||
                                contentPreview.imgtype === "jpeg" ||
                                contentPreview.imgtype === "jpeg") && (
                                <img
                                  src={contentPreview.imgurl}
                                  width={200}
                                  heigth={200}
                                />
                              )}
                            {contentPreview.imgurl &&
                              (contentPreview.imgtype === "mp4" ||
                                contentPreview.imgtype === "mpeg" ||
                                contentPreview.imgtype === "mpeg") && (
                                <video
                                  width="500"
                                  height="340"
                                  controls
                                  controlsList="nodownload"
                                >
                                  <source
                                    src={contentPreview.imgurl}
                                    type={`video/${contentPreview.imgtype}`}
                                  ></source>
                                </video>
                              )}
                            {contentPreview.imgurl &&
                              contentPreview.imgtype === "mp3" && (
                                <audio
                                  controls
                                  controlsList="nodownload"
                                >
                                  <source
                                    src={contentPreview.imgurl}
                                    type={`audio/${contentPreview.imgtype}`}
                                  ></source>
                                </audio>
                              )}
                            {contentPreview.imgurl &&
                              contentPreview.imgtype === "pdf" && (
                                <PdfViewer pdfurl={contentPreview.imgurl} />
                              )}
                          </div>

                          <div className="popup-btn-div">
                            {/* <button
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Ok
                </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {

                  }
                  {isVisible1 && (
                    <div className="sharepopupOuter">
                      <div className="save-pop-up-container-inner">
                        <div className="pop-up-container">
                          <span
                            className="close-btn"
                            onClick={() => {
                              setVisible1(false);
                              if (document.body.classList.contains("removescroll")) {
                                document.body.classList.remove("removescroll");
                              }
                            }}
                          >
                            <i className="fa fa-close closecs"></i>

                          </span>
                          <h3>Preview </h3>
                          <div className="input-popup2">
                            {contentPreview1.imgurl &&
                              (contentPreview1.imgtype === "png" ||
                                contentPreview1.imgtype === "jpg" ||
                                contentPreview1.imgtype === "jpeg" ||
                                contentPreview1.imgtype === "jpeg") && (
                                <img
                                  src={contentPreview1.imgurl}
                                  width={200}
                                  heigth={200}
                                />
                              )}
                            {contentPreview1.imgurl &&
                              (contentPreview1.imgtype === "mp4" ||
                                contentPreview.imgtype === "mpeg" ||
                                contentPreview1.imgtype === "mpeg") && (
                                <video
                                  width="700"
                                  height="340"
                                  controls
                                  controlsList="nodownload"
                                >
                                  <source
                                    src={contentPreview1.imgurl}
                                    type={`video/${contentPreview1.imgtype}`}
                                  ></source>
                                </video>
                              )}
                            {contentPreview1.imgurl &&
                              contentPreview1.imgtype === "mp3" && (
                                <audio
                                  controls
                                  controlsList="nodownload"
                                >
                                  <source
                                    src={contentPreview1.imgurl}
                                    type={`audio/${contentPreview1.imgtype}`}
                                  ></source>
                                </audio>
                              )}
                            {contentPreview1.imgurl &&
                              contentPreview1.imgtype === "pdf" && (
                                <PdfViewer pdfurl={contentPreview1.imgurl} />
                              )}
                          </div>

                          <div className="popup-btn-div">
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Content>

      }
      {
        isPopupOpen && <Publishpopup
          open={isPopupOpen}
          handleClose={() => setIsPopupOpen(false)}
          handleYes={chnageState === "announce" ? handleYes : handleResourceYes}
          handleNo={chnageState === "announce" ? handleNo : handleResourceNo}
          msg={toggleMessage}
          value={handleSatate} />
      }

    </>)
}
export default EditResource;